<template>
    <div class="home">
        <div class="box">
            <h1>درباره صرافی ویستا</h1>
            <p>
                صرافی ویستا با هدف رفع نیاز کاربران ایرانی برای مبادله دارایی های دیجیتال در تابستان 1400 شکل گرفت. تیم
                صرافی ویستا متشکل است از جمعی از فارغ التحصیلان دانشگاهی که در چندسال اخیر، در خرید و فروش بیت کوین و سایر
                ارزهای دیجیتال فعال بوده اند. ما با مشاهده موانع و مشکلات متعدد از جمله دسترسی سخت به بازارهای خارجی،
                وجود کلاهبرداران داخلی، خرید و فروش با سودی ناعادلانه و از طرفی در دسترس نبودن پلتفرمی امن و حرفه ای
                برای خرید و فروش ارزهای دیجیتال در کشور ، تصمیم به ایجاد پلتفرمی کاملا ایمن، حرفه ای وآسان برای خرید و
                فروش بیت کوین و سایر ارزهای دیجیتال گرفتیم. هدف ما فراهم نمودن بستری امن، سریع و آسان جهت خرید و
                فروش مستقیم ارزهای دیجیتال بین خریدار و فروشنده است و با ارائه مطالب آموزشی و تحلیلی، سعی در آگاهی بیشتر
                کاربران و فرهنگ سازی بیشتر جامعه جهت فعالیت در این حوزه مالی را دارد.
            </p>
        </div>
        <div class="site-header">
            <div class="detail-background"></div>
            <div class="box">
                <div class="header-detail">
                    <h2>به صرافی ویستا اعتماد کرده‌اند…</h2>
                </div>
                <div class="row no-gutters site-detail">
                    <div class="col-md">
                        <div class="detail-column">
                            <a>{{$toLocal(userscount)}}</a>
                            <p>تعداد کاربران</p>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="detail-column">
                            <a>+{{$toLocal(tradprice.toFixed(0))}}</a>
                            <p>ارزش معاملات (میلیارد تومان)</p>
                        </div>
                    </div>
                    <div class="col-md">
                        <div class="detail-column">
                            <a>{{$toLocal(totaltrad)}}</a>
                            <p>تعداد کل معاملات</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        name: "AboutUs",
        data() {
            return {
                userscount: '0',
                tradprice: '0',
                totaltrad: '0'
            }
        },
        methods: {
            async getInfo() {
                const res = await this.$axios('trades/market-info-home')
                this.userscount = res.baseDTO.userCount
                this.tradprice = res.baseDTO.tradesValue
                this.totaltrad = res.baseDTO.tradesAmount
            },
        },
        mounted() {
            this.getInfo()
        }
    }
</script>

<style scoped>

    .home {
        margin-top: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        color: var(--font-color);
    }

    h1 {
        color: var(--font-color);
    }

    .box {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1170px;
        padding: 0 10px;
        color: white;

    }

    .box h2 {
        margin-bottom: 30px;
    }

    .box h1 {
        font-size: 30px;
        margin-bottom: 10px;
        margin-top: 100px;
    }

    .box a {
        color: white;
        font-size: 18px;
        font-weight: 500;
    }

    .normal-btn {
        max-width: 200px;
    }

    .site-header {
        /*background-color: #202020;*/
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 275px;
        /*height: 695px;*/
    }

    .header-detail {
        z-index: 1;
        /*margin-top: 100px;*/
    }

    .detail-background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(rgba(22, 33, 51, 0.6), rgba(22, 33, 51, 0.6)), url("../../assets/Tradex/About Us/Bitmap.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-blend-mode: normal;
        filter: blur(2px);
    }

    .site-detail {
    }

    .site-detail div {
        display: flex;
        justify-content: center;
    }

    .detail-column {
        color: white;
        /*width: 270px;*/
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .detail-column a {
        font-weight: 100;
        font-size: 34px;
        border-bottom: solid 3px var(--main-orange);
        margin-top: 0 !important;
        margin-bottom: 0;
        padding-bottom: 10px;
        width: 105px;
    }

    .detail-column p {
        font-size: 22px;
        margin-bottom: 0 !important;
        margin-top: 20px !important;
        color: white !important;
    }


    .normal-btn {
        margin: 0 0 50px 0;
    }


    .box p {
        font-size: 20px;
        color: var(--font-color);
        text-align: justify;
    }

    .box p:first-of-type {
        margin-top: 50px;
    }

    .box p:last-of-type {
        margin-bottom: 50px;
    }

</style>
<template>
    <div class="box2">
            <table>
                <tr class="table-header">
                    <th>نوع {{label[type]}}</th>
                    <th>مقدار</th>
                    <th class="mobilehidden">شناسه تراکنش</th>
                    <th>زمان</th>
                    <th>وضعیت</th>
                </tr>
                <tr v-for="(tableContent,index) in tableContents" :key="index" v-show="index<16" class="table-row">
                    <td>{{$coins[tableContent.relatedCoin].persianName}}</td>
                    <!--                <td>{{tableContent.type}}</td>-->
                    <td>{{tableContent.amount}}</td>
                    <td class="mobilehidden">{{tableContent.id}}</td>
                    <td class="mobilehidden">{{$G2J(tableContent.createdAtDateTime,'faDateTime')}}</td>
                    <td class="mobileshow">{{$G2J(tableContent.createdAtDateTime,'faDate')}}</td>
                    <td v-if="tableContent.transactionStatus === 'CONFIRMED'" :style="{color:'var(--main-green)'}">موفق
                    </td>
                    <td v-else-if="tableContent.transactionStatus === 'UNCONFIRMED'" :style="{color:'var(--main-red)'}">
                        ناموفق
                    </td>
                    <td v-else-if="tableContent.transactionStatus === 'RAISED_BY_USER'" :style="{color:'var(--main-orange)'}">
                        درحال انجام
                    </td>
                    <td v-else-if="tableContent.isDone" :style="{color:'var(--main-green)'}">
                        موفق
                    </td>
                    <td v-else :style="{color:'var(--main-red)'}">
                        ناموفق
                    </td>
                </tr>
            </table>
        <div class="empty" v-show="!tableContents.length">
            <img src="../../assets/icon/empty.png" alt="">
            <p>تا کنون {{label[type]}} انجام نداده اید</p>
        </div>
        <pagination :key="lastPage" v-if="lastPage>1" style="margin-top: 30px" :n="lastPage" :max="5" :page="id"/>
    </div>
</template>

<script>
    import Pagination from "../Tools/Pagination";
    export default {
        name: "DepositTable",
        components: {Pagination},
        props: ['type'],
        data() {
            return {
                tableContents: [
                    // {
                    //     relatedCoin: '',
                    //     amount: 0,
                    //     id: 0,
                    //     createdAtDateTime: '',
                    //     transactionStatus: '',
                    // },
                ],
                loading: false,
                setting: {
                    suppressScrollX: true,
                    swipeEasing: false,
                    wheelPropagation: false,
                },
                label: {
                    with: 'برداشت',
                    dep: 'واریز'
                },
                lastPage: 1
            }
        },
        watch: {
            '$route.params.id'() {
                this.getDepositHistory()
            },
            '$route.params.tab'() {
                this.getDepositHistory()
            }
        },
        methods: {
            async getDepositHistory() {
                this.state.loading = true
                const address = this.type === 'with' ? 'wallets/withdrawal-requests' : 'wallets/deposit'
                const res = await this.$axios.get(address, {
                    params: {
                        size: 10,
                        page: this.id
                    }
                })
                // this.tableContents = [...this.tableContents, ...res.data.content]
                this.lastPage = res.totalPages
                this.tableContents = res.content
            }
        },
        mounted() {
            this.getDepositHistory()
        }

    }
</script>

<style scoped>

    .mobilehidden{
        display: table-cell;
    }
    .mobileshow{
        display:none
    }
    @media (max-width: 580px){
        .mobilehidden{
            display: none;
        }
        .mobileshow{
            display:table-cell;
        }
    }


    .box2 {
        font-size: 16px;
        border: none;
        background-color: white;
        width: 100%;
        margin-bottom: 30px;
        color: var(--font-color);
    }

    table {
        background-color: white;
        width: 100%;
        /*min-width: 673px;*/
        /*height: 710px;*/
        border-collapse: collapse;
    }

    table div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 50%;
        top: 30%;
        transform: translate(50%, -50%);
    }

    table div img {
        margin: 55px;
    }

    table th {
        height: 30px;
        font-weight: 300;
    }

    .table-header {
        top: 0;
        background-color:var(--dark-main);
        text-align: center;
        z-index: 1;
        color: white;
    }

    .table-row:nth-of-type(odd) {
        height: 40px;
        background-color: var(--hover);
    }

    .table-row:nth-of-type(even) {
        height: 45px;
        background-color: white;
    }

    .table-row:nth-of-type(odd) td:first-of-type {
        height: 40px;
    }

    .table-row:nth-of-type(even) td:first-of-type {
        height: 45px;
    }

    .table-row:nth-of-type(odd) td:last-of-type {
        height: 40px;
    }

    .table-row:nth-of-type(even) td:last-of-type {
        height: 45px;
    }


    .table-row td:last-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .table-row td:first-of-type a {
        margin-right: 5px;
    }

    .table-row td {

    }

    .table-row {
        position: relative;
        text-align: center;
        cursor: default;
        font-size: 14px;
    }
    .empty {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 50px;
    }


</style>
<template>
    <div class="Auth-box">
        <h3>تعیین رمز عبور</h3>
        <p v-if="state.userInfo.towStepAuthenticationType === 'EMAIL'">کد ۶ رقمی که به ایمیل
            {{$cookies.get('username')}} ارسال شده است را وارد نمایید.</p>
        <p v-else-if="state.userInfo.towStepAuthenticationType === 'SMS'"> کد ۶ رقمی که به شماره
            {{state.userInfo.mobileNumber}} ارسال شده است را وارد
            نمایید.</p>
        <p v-else-if="state.userInfo.towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR'">کد ۶ رقمی سرویس
            Google Authenticator را وارد نمایید.</p>
        <p v-else>کد ۶ رقمی ارسال شده را وارد نمایید.</p>
        <form class="Auth" @submit.prevent="submit">
            <CodeInput :loading="false" class="auth-row"
                       @change="onChange"
                       :fields="num" ref="code-input" :fieldWidth="55" :fieldHeight="55"/>
            <div class="footer">
                <a v-if="countDown===0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
                <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
                <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
            </div>
            <ali-input :value.sync="password" type="password" name="password" :errortext="passError"
                       faname="رمز عبور"/>
            <button type="submit" hidden/>
        </form>
        <button class="normal-btn" @click="submit" :disabled="!code || !password">ذخیره
        </button>
    </div>
</template>

<script>
    import AliInput from "@/components/Tools/AliInput";
    import CodeInput from "vue-verification-code-input";

    export default {
        name: "ResetPass",
        components: {AliInput,CodeInput},
        data: () => ({
            email: '',
            password: '',
            password2: '',
            type1: 'password',
            type2: 'password',
            countDown: 120,
            num: 6,
            code: '',
            passError:''
        }),
        methods: {
            async countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    const a = await this.$error('زمان شما به اتمام رسید', '','warning','ارسال مجدد')
                    if(a.isConfirmed){
                        this.send()
                    }
                }
            },
            async submit() {
                await this.check2Pass()
                this.state.loading = true
                await this.$axios.post('/users/reset-password', {
                    email: this.$cookies.get('username') || this.state.userInfo.email || '',
                    code: this.code,
                    password: this.password
                })
                await this.$router.push('/sign-in')
            },
            async send() {
                this.code = ''

                await this.$axios.post('/users/forget-password?email=' + this.$cookies.get('username') || this.state.userInfo.email)
                this.$error('کد جدید ارسال شد.', '', 'success')

                this.$cookies.set('username', this.state.userInfo.email)

                if (this.countDown === 0) {
                    if (this.state.userInfo.towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR')
                        this.countDown = 180
                    else
                        this.countDown = 120
                    this.countDownTimer()
                }

            },
            async check2Pass() {
                if (!this.$validPass(this.password) || this.password.length < 8) {
                    this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                    this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                    return Promise.reject('wrong pass : ' + this.password)
                }
            },
            onChange(e) {
                this.code = e
            },
        },
        created() {
            this.countDownTimer()

            if (this.state.userInfo.towStepAuthenticationType === 'GOOGLE_AUTHENTICATOR')
                this.countDown = 180
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
    }
</script>
<style scoped>

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 450px;
        height: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .normal-btn{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }
</style>

<template>
  <div class="Auth-box">
    <h3>شناسایی دو عاملی</h3>
    <form action="" @submit.prevent="submit" @paste="paste">
      <p v-if="pathMatch === 'email'">کد ۶ رقمی که به ایمیل {{ $cookies.get('username') }} ارسال شده است را وارد نمایید.</p>
      <p v-else-if="pathMatch === 'sms'">
        کد ۶ رقمی که به شماره
        <b dir="ltr" style="direction: ltr">{{state.userInfo.mobilenumber}}</b>
        ارسال شده است را وارد نمایید.</p>
      <p v-else-if="pathMatch === 'google-auth'">کد تایید دومرحله ای که برنامه Google Authenticator تولید کرده است را وارد نمایید.</p>
      <CodeInput :loading="false" class="auth-row"
                 @change="onChange" @complete="submit"
                 :fields="num" ref="code-input" :fieldWidth="55" :fieldHeight="55"/>
      <div class="footer" v-if="pathMatch !== 'google-auth'">
        <a v-if="countDown===0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
        <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
        <a style="text-decoration: none" class="countdown">{{ countDown }} ثانیه </a>
      </div>
      <button class="normal-btn" @click.prevent="submit" :disabled="code.toString().length < num || countDown === 0">
        تایید
      </button>
    </form>
    {{ error }}
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {reset,paste} from "lib/reuseableFunction";

export default {
  name: "TwoStepEmail",
  props:['pathMatch'],
  data() {
    return {
      countDown: 120,
      error: '',
      num: 6,
      code: '',
      timeOut: '',
      otp: {
        code: '',
        email: this.state.userInfo.email || this.$cookies.get('username')
      }
    }
  },
  components: {CodeInput},
  methods: {
    paste,reset,
    async countDownTimer() {
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--
          this.countDownTimer()
        }, 1000)
      } else {
        const a = await this.$error('زمان شما به اتمام رسید', '', 'warning', 'ارسال مجدد')
        if (a.isConfirmed) {
          this.send()
        }
      }
    },
    async send() {
      this.reset()
      await this.$axios.post('/users/resend-otp-code?email=' + this.otp.email)
      const text = {
        email: 'ایمیل',
        sms: 'شماره'
      }
      this.$error(`کد جدید به ${text[this.pathMatch]} شما ارسال شد.`, '', 'success')
      this.$cookies.set('username', this.state.userInfo.email)

      if (this.countDown === 0) {
        this.countDown = 120
        this.countDownTimer()
      }

    },
    async submit() {
      this.state.loading = true

      const res = await this.$axios.post('/users/check-otp-code', this.otp)
      if (res.token) {
        this.$setToken(res.token, this.state.userInfo.remember)
        await this.$router.push('/trade/TOMAN/BITCOIN')
      }
    },
    onChange(e) {
      this.code = e
    },
  },
  mounted() {
    this.$refs['code-input'].$refs['input_0'][0].focus();
  },
  created() {
    if(this.pathMatch !== 'google-auth'){
      this.countDownTimer()
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut)
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 30px;
}

input {
  padding: 5px 0;
}

.Auth-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: 100%;
}

.countdown {
  font-size: 14px;
  text-decoration: underline;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.auth-row {
  display: flex;
  direction: ltr;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

.Auth-input {
  font-size: 20px;
  border-radius: 5px;
  border: solid 1px #00000010;
  width: 55px;
  height: 55px;
  text-align: center;

  &:focus {
    border-color: var(--main-orange);
  }
}

.normal-btn {
  margin-top: 60px;
  margin-bottom: 20px;
}
</style>
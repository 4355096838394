var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"row trade-main"},[_c('div',{staticClass:"col-14 col-lg-3 pad order-3 order-lg-0 h-1"},[_c('shop',{staticClass:"box h-2"}),(true)?_c('real-price',{staticClass:"box h-4 p-relative"}):_vm._e()],1),_c('div',{staticClass:"col-14 col-lg-3 pad order-4 order-lg-1 h-1"},[_c('market-depth',{staticClass:"box h-2"}),_c('last-trade',{staticClass:"box h-4"})],1),_c('div',{staticClass:"col-14 col-lg-8 h-3 order-first order-lg-3"},[_c('trade-header',{staticClass:"h-7 col-14",attrs:{"id":"trade-header"}}),_c('vue-trading-view',{key:_vm.$coins[_vm.tradeFrom].code + _vm.state.theme,staticClass:"h-6",attrs:{"options":{
                              autosize:true,
                              symbol: 'BINANCE:' + _vm.$coins[_vm.tradeFrom].code + 'USDT',
                              timezone: 'Asia/Tehran',
                              theme: _vm.state.theme,
                              // library_path: require('../assets'),
                              style: '1',
                              locale: 'fa_IR',
                              toolbar_bg: '#f1f3f6',
                              enable_publishing: false,
                              withdateranges: true,
                              range: 'ytd',
                              // user_id:'Nima2142',
                              hide_side_toolbar: false,
                              allow_symbol_change: true,
                              toolbar_bg: '#fff',
                              loading_screen:{
                                backgroundColor : '#000',
                                foregroundColor : '#000'
                              },
                              disabled_features: [
                                   'border_around_the_chart' ],
                              enabled_features: [
                                    'remove_library_container_border',
                                    'remove_container_border',
                                    'dont_show_boolean_study_arguments',
                                    'use_localstorage_for_settings',
                                    'remove_library_container_border',
                                    'save_chart_properties_to_local_storage',
                                    'side_toolbar_in_fullscreen_mode',
                                    'hide_last_na_study_output',
                                    'constraint_dialogs_movement',
                                    'header_fullscreen_button'
                              ],
                              studies_overrides: {
                                  'volume.volume.color.0': '#f84960',
                                  'volume.volume.color.1': '#01bf76',
                                  'volume.volume.transparency': 75,
                              },
                              overrides:{
                                  'paneProperties.background': '#000',
                  'paneProperties.vertGridProperties.color': 'rgba(83,87,96,0)',
                  'paneProperties.horzGridProperties.color': 'rgba(54,60,78,0)',
                  'mainSeriesProperties.areaStyle.color1': '#f8b200',
                  'mainSeriesProperties.areaStyle.color2': '#f8b200',
                  'mainSeriesProperties.areaStyle.linecolor': '#f8b200',
                  'mainSeriesProperties.lineStyle.color': '#f8b200',
                  'mainSeriesProperties.candleStyle.upColor': '#01bf76',
                  'mainSeriesProperties.candleStyle.downColor': '#f84960',
                  'mainSeriesProperties.candleStyle.borderColor': '#01bf76',
                  'mainSeriesProperties.candleStyle.borderUpColor': '#01bf76',
                  'mainSeriesProperties.candleStyle.borderDownColor': '#f84960',
                  'mainSeriesProperties.candleStyle.wickUpColor': '#01bf76',
                  'mainSeriesProperties.candleStyle.wickDownColor': '#f84960',
                  'mainSeriesProperties.hollowCandleStyle.upColor': '#01bf76',
                  'mainSeriesProperties.hollowCandleStyle.downColor': '#f84960',
                  'mainSeriesProperties.hollowCandleStyle.borderColor': '#01bf76',
                  'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#01bf76',
                  'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#f84960',
                  'mainSeriesProperties.hollowCandleStyle.wickUpColor': '#01bf76',
                  'mainSeriesProperties.hollowCandleStyle.wickDownColor': '#f84960',
                  'mainSeriesProperties.haStyle.upColor': '#01bf76',
                  'mainSeriesProperties.haStyle.downColor': '#f84960',
                  'mainSeriesProperties.haStyle.borderColor': '#01bf76',
                  'mainSeriesProperties.haStyle.borderUpColor': '#01bf76',
                  'mainSeriesProperties.haStyle.borderDownColor': '#f84960',
                  'mainSeriesProperties.haStyle.wickUpColor': '#01bf76',
                  'mainSeriesProperties.haStyle.wickDownColor': '#f84960',
                  'mainSeriesProperties.barStyle.upColor': '#01bf76',
                  'mainSeriesProperties.barStyle.downColor': '#f84960',
                  'scalesProperties.backgroundColor':'#000'
                              },
                              custom_css_url: require('@/style/ali.css'),
                              }}}),_c('orders',{staticClass:"box h-5",on:{"completed":_vm.completed}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
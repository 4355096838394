<template>
  <div class="main-box">
    <h2>کیف پول</h2>
    <div class="tab">
      <div :class="type === 'P2P' ? 'tab-content-active' : 'tab-content'"
           @click.prevent="type = 'P2P'">
        <a>معامله حرفه ای</a>
      </div>
      <div :class="type === 'EXCHANGE' ? 'tab-content-active' : 'tab-content'"
           @click.prevent="type = 'EXCHANGE'">
        <a>معامله ساده</a>
      </div>
      <div class="selector" :style="{right : type === 'P2P'? '' : type === 'EXCHANGE'? '50%': ''}"></div>
    </div>
    <table style="position: relative">
      <over-loading v-if="loading"/>
      <tr class="table-header">
        <th>نوع ارز</th>
        <th>موجودی</th>
        <th>ارزش</th>
        <th>عملیات</th>
      </tr>
      <tr v-for="(wallet,index) in wallets" :key="index" class="table-row">
        <td>
          <img height="30px" :src="'/coins/' + wallet[0] + '.png'" alt="WalletIcon">
          <a>{{ $coins[wallet[0]].persianName || wallet[0] || 'ارز پشتیبانی نشده' }}</a>
        </td>
        <td style="direction: ltr">{{ amount(wallet) || 0 }}</td>
        <td>{{ price(wallet) || 0 }}</td>
        <td>
          <button @click="$emit('deposit', wallet)">واریز</button>
          <button @click="$emit('withdraw', wallet )">برداشت</button>
          <button @click="$router.push('/trade/TOMAN/'+(wallet[0]))" :disabled="wallet[0]==='TOMAN'">معامله</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import OverLoading from "components/over-loading";
export default {
  name: "MyWallet",
  components: {OverLoading},
  props:['type'],
  data() {
    return {
      wallets: [],
      loading:false,
    }
  },
  watch: {
    type() {
      this.getWallets()
    }
  },
  computed: {
    amount() {
      return e => this.$toLocal(e.credit) || '0'
    },
    price() {
      return e => this.$toLocal( e[1].wholePriceOfCredit, this.$prcesion[e[0]]) || '0'
    },
    blocked() {
      return e => this.$toLocal(e[1].blockedCredit) || '0'
    },
    credit() {
      return e => this.$toLocal(e.credit) || '0'
    },
  },
  methods: {
    async getWallets() {
      // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
      this.loading = true
      const [res,] = await this.$http.get('/wallets/customer-addresses?walletType=' + this.type || 'P2P')
      if (res) {
        let entireRes = Object.entries(res)
        const sortCoins = this.$walletSortCoins
        const compare1 = (a, b) => {
          let first = sortCoins.indexOf(a[0])
          let second = sortCoins.indexOf(b[0])
          first = first > -1 ? first : Infinity
          second = second > -1 ? second : Infinity
          return first - second
        }
        const compare2 = (a, b) => {
          let first = b[1].wholePriceOfCredit || 0
          let second = a[1].wholePriceOfCredit || 0
          first = first > -1 ? first : Infinity
          second = second > -1 ? second : Infinity
          return first - second
        }
        entireRes.sort(compare1)
        entireRes.sort(compare2)
        this.wallets = entireRes
      }
      this.loading = false
    },
    withdraw(e) {
      this.$emit('withdraw', e)
    },
    trade(e) {
      this.$emit('trade', e)
    }
  },
  mounted() {
    this.state.loading = true
    this.getWallets()
  }
}
</script>

<style scoped>

.tab {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 60px;
  /*border-bottom: #00000010 1px solid;*/
  font-size: 18px;
  align-items: center;
  background-color: transparent;
  text-align: right;
  width: 100%;
  margin-bottom: 30px;

}

.tab-content {
  width: 50%;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
}

.tab-content-active {
  font-weight: 800;
  width: 50%;
  text-align: center;
}

.selector {
  position: absolute;
  background-color: var(--main-orange);
  width: 50%;
  height: 6px;
  border-radius: 5px;
  bottom: -3px;
  right: 0;
  transition: 0.5s right;
}

.main-box {
  font-size: 16px;
  background-color: white;
  width: 100%;
  margin-bottom: 30px;
  color: var(--font-color);
}

h1 {
  font-size: 30px;
  margin-bottom: 40px;
  margin-top: 10px;
}

table {
  background-color: white;
  width: 100%;
  /*min-width: 550px;*/
  /*height: 100%;*/
  border-collapse: collapse;
}

table th {
  height: 30px;
  font-weight: 300;
  /*opacity: 0.8;*/
}

.table-header {
  top: 0;
  background-color: var(--dark-main);
  text-align: center;
  z-index: 1;
  color: white;
}

.table-row:nth-of-type(odd) {
  height: 40px;
  background-color: var(--hover);
}

.table-row:nth-of-type(even) {
  height: 45px;
  background-color: white;
}

.table-row {
  font-size: 14px;
}


.table-header th:first-of-type,
.table-row td:first-of-type {
  text-align: right;
  direction: rtl;
  margin-top: 10px;
  padding-right: 1.5%;
  min-width: 130px;
}

.table-row td:first-of-type img,
.table-row td:first-of-type a {
  vertical-align: middle;
}

.table-header th:last-of-type {
  padding-left: 100px;
}

@media (max-width: 580px) {
  .table-header th:last-of-type {
    padding-left: 20px;
  }

  button {
    padding-right: 0 !important;
  }
}


.table-header th:last-of-type,
.table-row td:last-of-type {
  text-align: end;
}

.table-row td:first-of-type a {
  margin-right: 10px;
}

.table-row td:last-of-type {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}

.table-row {
  text-align: center;
  cursor: default;
}


button {
  padding: 0 20px;
  margin: 10px 0;
  color: var(--font-color);
}

button:hover {
  text-decoration: underline;
  color: var(--main-orange);
  cursor: pointer;
}

button:disabled {
  color: white;
  cursor: default;
  opacity: 0;
}


</style>
<template>
    <div class="main-box">
        <h2 style="text-align: right">تنظیمات امنیتی</h2>
        <div class="box2">
            <set-two-step-auth/>
            <change-password/>
        </div>
    </div>
</template>

<script>
    import ChangePassword from "@/components/UserAccount/ChangePassword";
    import SetTwoStepAuth from "@/components/UserAccount/SetTwoStepAuth";

    export default {
        name: "Setting",
        components: {
            SetTwoStepAuth,
            ChangePassword
        },
        data() {
            return {
                profile: true,
                showGmodal: false,
                showSmodal: false,
                showEmodal: false,
                showcomplete: false,
                text: ''
            }
        },
        methods: {
            change(e) {
                console.log(e)
                switch (e) {
                    case 'googleauth':
                        this.showGmodal = true
                        break;
                    case 'twostepesms':
                        this.showSmodal = true
                        break;
                    case 'twostepemail':
                        this.showEmodal = true
                        break;
                    default:
                        this.showGmodal = false
                        this.showSmodal = false
                        this.showEmodal = false
                }
            },
            close() {
                this.showGmodal = false
                this.showSmodal = false
                this.showEmodal = false
            },
            completed(e) {
                this.text = e
                this.showcomplete = true
            }
        },

    }
</script>

<style scoped>

    .main-box {
        display: inline-block;
        background-color: transparent;
        text-align: right;
        margin-top: 30px;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1170px;
    }

    h2 {
        font-family: IRANSans-Bold, sans-serif;
        font-size: 22px;
        margin-bottom: 30px;
        margin-right: 10px;
    }


    .box2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        text-align: right;
        width: 100%;
    }
</style>
import Vue from 'vue'

// axios
import axios from 'axios'
import router from "@/lib/router";

const that = Vue.prototype

if (that.state.token) {
    // console.log('I have token : ', that.state.token)
    axios.defaults.headers.common['Authorization'] = that.state.token
}
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"

axios.defaults.baseURL = 'https://vistadev.xyz/exchange/api'
if (process.env.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'https://vistadev.xyz/exchange/api'
}

axios.interceptors.response.use(
    (res) => {
        that.state.loading = false

        return res.data;
    },
    (err) => {
        that.state.loading = false

        const res = err.response || err.toJSON()
        // const data = err?.response?.data;
        const message = err?.response?.data?.message;
        const errors = err?.response?.data?.errors;
        const error = err?.response?.data?.error;
        const status = err?.response?.data?.status;

        if(message?.includes('429')){
            return Promise.reject(err);
        }

        if (res?.config?.responseType === 'blob') {
            const vm = that
            let reader = new FileReader();
            reader.onload = function (e) {
                let test = JSON.parse(e.target.result);
                vm.$error('', vm.$errorHandeling[test.message])
            };
            reader.readAsText(err.response.data);
            return Promise.reject(err);
        }

        if (err.response) if (message?.includes('JWT') || message?.includes('Invalid Token')) {
            that.$logout()
            return Promise.reject(err);
        } else if (message?.includes('ShebaCode')) {
            that.$error('شماره شبا یا کارت تکراری است.', '')
            return Promise.reject(err);
        } else if (['GOOGLE_FAILURE', 'Can\'t Access Resource'].includes(message)) {
            return Promise.reject(err);
        } else if (message === "USER_IDENTITY_DATA") {
            // console.log('in USER_IDENTITY_DATA');
            const a = {
                MOBILE_IS_INCORRECT: 'شماره همراه تکراری میباشد.',
                NATIONAL_CODE_IS_INCORRECT: 'کد ملی تکراری میباشد.',
                TELEPHONE_IS_INCORRECT: 'شماره تلفن تکراری میباشد.',
            }
            let uniqueErrors = '';
            for (let i = 0; i < errors.length; i++) {
                uniqueErrors = uniqueErrors + (a[errors[i]] || errors[i]) + '\n'
            }
            that.$error('اطلاعات تکراری میباشد', uniqueErrors);
            return Promise.reject(err);
        } else if (status === 'BAD_REQUEST') {
            let a = errors[0]
            // a = JSON.parse(a.match(/\[(.*?)\]/)[1])
            const codeError = {
                'invalid otp or mobile': 'کد وارد شده اشتباه است',
                'otp used before': 'کد منقضی شده است',
            }
            const finoError = {
                'nid is not valid': 'کد ملی صحیح نیست',
                'mobile is not valid': 'شماره همراه صحیح نیست',
                'nid and mobile is not match': 'شماره همراه شما با کد ملی مطابقت ندارد',
                'هیچ رکوردی یافت نشد. لطفا مقادیر ورودی را کنترل نمایید': 'تاریخ تولد با سایر اطلاعات همخوانی ندارد.',
            }
            if (finoError[a] || codeError[a]) {
                that.$error('خطای احراز هویت', codeError[a] || finoError[a]);
                if (finoError[a]) {
                    router.push({name: 'Profile'})
                }
                return Promise.reject(err);
            }
        }

        // if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
        //     return Promise.reject(err);
        // }

        const text = res ?
            that.$errorHandeling[message]
            || that.$errorHandeling[status]
            || errors
            || error
            : message

        if (res && that.$errorHandeling[message] || that.$errorHandeling[status]) {
            that.$error('',text)
        }

        return Promise.reject(err);
    },);

let func = async (config) => {
    try {
        const res = await axios(config)
        return [res, null]
    } catch (e) {
        return [null, e]
    }
}
const props = {
    get: async (url, config) => {
        try {
            const res = await axios.get(url, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    post: async (url, data, config) => {
        try {
            const res = await axios.post(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    put: async (url, data, config) => {
        try {
            const res = await axios.put(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    delete: async (url, config={}) => {
        try {
            const res = await axios.delete(url, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
}
for (let prop in props) {
    func[prop] = props[prop];
}

that.$http = func;
that.$axios = axios

export const http = func
export default axios

<template>
    <div class="main">
        <h1>دعوت دوستان</h1>
        <div class="box">
            <p>هر فردی که با استفاده از کد دعوت و یا لینک دعوت شما ثبت نام نماید 20% درصد از کارمزد ریالی که صرافی ویستا
                از هر معامله آن فرد دریافت می کند به شما تعلق می گیرد. هم چنین فرد دعوت شده می تواند دو معامله با کارمزد
                صفر انجام دهد و دو پله هم کارمزد معاملاتی شخص کاهش پیدا می کند.</p>
            <div class="input">
                <label for="link">لینک دعوت</label>
                <div @click.prevent="Copy('https://VistaEx.ir/sign-up?ref='+data.referralCode,'لینک')" class="input-box">
                    <button>
                        <img src="../../assets/icon/copy-black.svg" alt="">
                        کپی
                    </button>
                    <input type="text" name="link" id="link" :value="'https://VistaEx.ir/sign-up?ref='+data.referralCode" readonly>
                </div>
            </div>
            <div class="input">
                <label for="code">کد دعوت</label>
                <div @click.prevent="Copy(data.referralCode,'کد')" class="input-box">
                    <button>
                        <img src="../../assets/icon/copy-black.svg" alt="">
                        کپی
                    </button>
                    <input type="text" name="code" id="code" :value="data.referralCode" readonly>
                </div>
            </div>
        </div>
        <div class="result">
            <div class="box1">
                <img src="../../assets/icon/friend.png" alt="">
                <p>{{$toLocal(data.countInvitees) || 0}} دوست</p>
            </div>
            <div class="box1">
                <img src="../../assets/icon/money.png" alt="">
                <p> میزان سود فعلی: {{$toLocal(data.profit) || 0}} تومان </p>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "Invite",
        data() {
            return {
                data:{
                    countInvitees:0,
                    profit:'',
                    referralCode: '',
                }
            }
        },
        methods: {
            async Copy(e,t) {
                await navigator.clipboard.writeText(e)
                alert(t+' کپی شد')
            }
        },
        async mounted() {
            const res = await this.$axios.get('/users/invites')
            this.data = res.baseDTO
        }
    }
</script>

<style scoped>
    .main {
        text-align: right;
        color: var(--font-color);
        width: 100%;
        max-width: 1380px;
        padding: 0 10px;
    }

    h1 {
        font-size: 30px;
        margin-bottom: 40px;
    }

    .box {
        width: calc(100% - 40px);
        margin: 0 20px 40px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: 2px solid var(--light-border);
    }

    p {
        margin-bottom: 35px;
    }

    .input {
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 50px;
    }
    .input:first-of-type{
        width: 40%;
    }
    .input:last-of-type{
        width: 30%;
    }
    .input-box{
        height: 30px;
        border: 1px solid var(--light-border);
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 75%;
    }
    .input-box button{
        width: 75px;
        display: flex;
        align-items: center;
        color: var(--font-color);
        cursor: pointer;
    }
    .input-box img{
        filter: invert(14%) sepia(9%) saturate(6552%) hue-rotate(189deg) brightness(96%) contrast(93%);
        opacity: 0.9;
    }
    .input-box button:hover{
        color: var(--main-orange);
    }
    .input-box button:hover img{
        filter: invert(87%) sepia(15%) saturate(6929%) hue-rotate(358deg) brightness(97%) contrast(93%);
    }
    .input-box input{
        height: 30px;
        width: calc(100% - 75px);

    }
    .input-box img{
        height: 20px;
        margin-left: 5px;
    }
    .input-box input{
        border: none;
        text-align: left;
        padding-left: 5px;
    }
    @media (max-width: 767px) {
        .input:first-of-type{
            width: 100%;
            margin-left: 0;
        }
        .input:last-of-type{
            width: 100%;
            margin-left: 0;
        }
        .box{
            width: calc(100% - 20px);
            margin: 0 10px 40px;
        }
    }
    .result{
        width: 100%;
        display: flex;
        justify-content: space-around;
    }
    .box1{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }
    .box1 img{
        height: 85px;
    }

</style>
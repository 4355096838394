<template>
  <div class="dropbox">
    <div class="dropbox1">
      <div v-for="(content,index) in contents"
           :key="content.lastPrice + ' - ' + index"
           class="dropbox-contents"
           @click="select(content)">
        <div class="contents">
          <img width="24" :src="'/coins/' + $M2C(content.marketType) + '.png'">
          {{ $coins[$M2C(content.marketType)].persianName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dropDown",
  props: ['contents', 'name'],
  methods: {
    select(e) {
      this.$emit('select', e)
    }
  },
}
</script>

<style lang="scss" scoped>

.dropbox {
  padding-top: 17px;
  font-size: 14px;
  position: absolute;
  z-index: 5;
  display: block;
  right: 50%;
  transform: translate(50%, 0);

  .dropbox1 {
    color: var(--font-color);
    background-color: white;
    text-align: right;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: 0.5s;
    z-index: 5;

    .dropbox-contents {
      padding: 10px 10px 10px 15px;
      transition: 0.25s;
      cursor: pointer;
      position: relative;
      /*border-bottom: solid 2px rgba(0, 0, 0, 0.1);*/
      &:first-of-type {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
      }

      &:last-of-type {
        /*border-bottom: none;*/
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:hover {
        background-color: var(--main-hover);
        color: var(--main-orange);
      }
    }
  }

}

.contents {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

/*.dropbox-contents::after {*/
/*    content: '';*/
/*    position: absolute;*/
/*    margin-top: 8px;*/
/*    right: 0;*/
/*    background-color: #000000;*/
/*    height: 2px;*/
/*    width: 100%;*/
/*    opacity: 0.1;*/
/*}*/

.arrow {
  fill: white;
  margin-right: 5px;
  transition: 0.25s;
}


.selected {
  color: #ffbf00;
  border-bottom: #ffbf00 solid 2px;
  cursor: default;
}

</style>
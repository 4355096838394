<template>
    <div class="box-sub">
        <a>اطلاعات شخصی</a>
        <form class="row no-gutters">
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <label class="col-4 pl-1" for="fname">نام</label>
                <input
                        @input="data.firstName = data.firstName.replace(/[\w!#^+=?*&@.$%{}\[\]'\\\-,():;`]|  /g,'')"
                        class="col-12"
                        type="text"
                        v-model="data.firstName"
                        name="fname"
                        id="fname"
                        placeholder="نام"
                        :readonly="readonly"
                >
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <label class="col-4 pl-1" for="lname">نام خانوادگی</label>
                <input
                        @input="data.lastName = data.lastName.replace(/[\w!#^+=?*&@.$%{}\[\]'\\\-,():;`]|  /g,'')"
                        class="col-12"
                        type="text"
                        v-model="data.lastName"
                        name="lname"
                        id="lname"
                        placeholder="نام خانوادگی"
                        :readonly="readonly"
                >
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <label class="col-4 pl-1" for="nationalCode">کد ملی</label>
                <input
                        @input="data.nationalCode = data.nationalCode.replace(/\D/g,'')"
                        :style="{textAlign: [data.nationalCode? 'left':'right']}"
                        class="col-12"
                        type="text"
                        v-model="data.nationalCode"
                        name="nationalCode"
                        id="nationalCode"
                        placeholder="کد ملی"
                        :readonly="readonly"
                        :class="{danger : data.nationalCode && data.nationalCode.length<10}"
                        minlength="10"
                        maxlength="10"
                >
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <label class="col-4 pl-1" for="birthday">تاریخ تولد</label>
                <date-picker
                        v-model="data.birthDay"
                        :placeholder="'تاریخ تولد'"
                        color="var(--main-orange)"
                        :format="$dateFormat['en']"
                        :display-format="$dateFormat['faDate']"
                        :max="$jmoment().format($dateFormat['en'])"
                        :clearable="!state.userInfo.authorized"
                        :disabled="readonly"
                        id="birthday"
                />
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <label class="col-4 pl-1" for="mobile">شماره موبایل</label>
                <input
                        :style="{textAlign: [data.mobileNumber? 'left':'right']}"
                        class="col-12"
                        type="text"
                        v-model="data.mobileNumber"
                        name="mobile"
                        id="mobile"
                        minlength="11"
                        maxlength="11"
                        placeholder="شماره موبایل"
                        @input="data.mobileNumber = $A2N(data.mobileNumber)"
                >
            </div>
            <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                <label class="col-4 pl-1" for="phone">شماره ثابت</label>
                <input
                        :style="{textAlign: [data.telephone? 'left':'right']}"
                        class="col-12"
                        type="text"
                        v-model="data.telephone"
                        name="phone"
                        id="phone"
                        minlength="11"
                        maxlength="11"
                        placeholder="شماره ثابت"
                        @input="data.telephone = $A2N(data.telephone)"
                >
            </div>
            <div class="row no-gutters col-12 p-1 p-sm-3">
                <label for="address">آدرس</label>
                <textarea @change="checkAddress" v-model="data.address" name="address" id="address"
                          placeholder="حداقل 20 کاراکتر الزامیست"/>
                <p class="error" v-if="character">حداقل 20 کاراکتر</p>
<!--                <p class="error" v-else-if="postal">کد پستی اجباریست</p>-->
            </div>
        </form>
    </div>
</template>

<script>
    import '@/style/birthdayPicker.scss'

    export default {
        name: "PersonalInformation",
        props: ['data'],
        data() {
            return {
                character: false,
                postal: false
            }
        },
        computed: {
            readonly() {
                return ['AUTHORIZED', "INFORMATION_CHANGED",'INFORMATION_CHANGED_PENDING'].includes(this.data.authenticationType)
            }
        },
        methods: {
            checkAddress() {
                // console.log('askjdhsajk')
                this.character = false
                this.postal = false
                // let re = /\d{10}/;
                if (this.data.address.replace(/ /ig, '').length < 21) {
                    this.character = true
                }
                /*else if (!re.test(this.data.address)) {
                    this.postal = true
                }*/
            }
        }
    }
</script>

<style lang="scss" scoped>

    .box-sub {
        font-family: IRANSans-Bold,sans-serif;
        display: block;
        background-color: white;
        text-align: right;
        border-bottom: #00000015 solid 1px;
        padding: 10px 15px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
        color: var(--font-color);
    }

    form {
        font-family: VistaDev,sans-serif;
        text-align: right;
        margin: 20px 0;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    form div:last-of-type label{
        width: 10.5%
    }

    .box-sub label {
        display: flex;
        align-items: center;
        min-width: 84px;


    }

    .box-sub input {
        /*border: solid rgba(54, 52, 53, 0.15) 0.5px;*/
        height: 29px;
        border-radius: 5px;
        padding: 2px 5px;
        width: 100%;
        max-width: 66.666667%;
        text-align: left;
        &:read-only{
            background-color: var(--main-gray);
        }
    }

    #fname,#lname{
        text-align: right;
    }


    #birthday{
        width: 100%;
        max-width: 66.666667%;
    }

    @media (max-width: 911px) {
        .box-sub input {
            max-width: 100%;
        }
        #birthday{
            max-width: 100%;
        }
    }

    /*.box-sub input:hover {*/
    /*    border: solid rgba(54, 52, 53, 0.3) 0.5px;*/
    /*}*/

    /*.box-sub input:focus {*/
    /*    border: solid rgba(54, 52, 53, 0.5) 0.5px;*/
    /*}*/

    textarea {
        position: relative;
        /*border: solid rgba(54, 52, 53, 0.15) 0.5px;*/
        display: inline-block;
        width: calc(100% - 10.5%) !important;
        height: 97px;
        border-radius: 5px;
        padding: 2px 5px;
        font-size: 14px;
    }

    @media (max-width: 911px) {
        textarea {
            width: 100% !important;
        }
    }

    .error {
        color: red;
        font-size: 12px;
        position: absolute;
        bottom: 0;
        right: calc(10.5% + 1rem);
        transform: translateY(calc(100% + 5px));
    }

    /*.box-sub textarea:hover {*/
    /*    border: solid rgba(54, 52, 53, 0.3) 0.5px;*/
    /*}*/

    /*.box-sub textarea:focus {*/
    /*    border: solid rgba(54, 52, 53, 0.5) 0.5px;*/
    /*}*/

</style>
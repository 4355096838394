<template>
  <div class="box1">
    <over-loading v-if="loading" style="height:65%"/>
    <div style="position: relative;width: 100%">
      <perfect-scrollbar :options="settings">
        <table>
          <tr class="table-header">
            <th>قیمت</th>
            <th>مقدار</th>
            <th>مجموع <a style="font-size: 10px">({{ $coins[tradeTo].persianName }})</a></th>
          </tr>
          <tr style="height: 40px" v-if="tableContentsSell.length === 0">
            <td colspan="3">
              <p style="color:red;text-align:center;width:100%">
                در حال حاضر اطلاعاتی وجود ندارد
              </p>
            </td>
          </tr>
          <tr @click.prevent="setShop($toLocal(tableContent.unitPrice,precision),$toLocal(tableContent.volume - tableContent.executedAmount,$decimal[tradeFrom]),'sell')"
              v-for="(tableContent,index) in tableContentsSell.filter((e,i)=> i>tableContentsSell.length-8)"
              :key="-(index+1)" style="color:var(--main-red);cursor: pointer"
              class="table-row red-percent"
              :style="{
                        textDecoration: tableContent.myOrder? 'underline' : '',
                        '--executedPercent': (100 - tableContent.executedPercent) +'%',
                        '--color': 'var(--main-red-hover)'
                    }"
              :title="tableContent.myOrder ? 'این سفارش متعلق به شماست' : ''">
            <td>{{ $toLocal(tableContent.unitPrice, precision) }}</td>
            <td>{{ $toLocal(tableContent.volume - tableContent.executedAmount, $decimal[tradeFrom]) }}</td>
            <td>{{ $toLocal(tableContent.wholePrice, $decimal[tradeTo]) }}</td>
            <!--<span :style="{width: tableContent.executedPercent + '%'}" class="percent1"/>-->
          </tr>
          <tr style="height: 25px" class="table-row">
            <td colspan="3">
              <div :class="{active : state.shopInfo.lastPrice>0}" class="realprice">
                <svg style="margin-left: 10px"
                     id="Layer"
                     enable-background="new 0 0 64 64" viewBox="0 0 64 64"
                     width="16px" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="m37.379 12.552c-.799-.761-2.066-.731-2.827.069-.762.8-.73 2.066.069 2.828l15.342 14.551h-39.963c-1.104 0-2 .896-2 2s.896 2 2 2h39.899l-15.278 14.552c-.8.762-.831 2.028-.069 2.828.393.412.92.62 1.448.62.496 0 .992-.183 1.379-.552l17.449-16.62c.756-.755 1.172-1.759 1.172-2.828s-.416-2.073-1.207-2.862z"/>
                </svg>
                {{ $toLocal(state.shopInfo.lastPrice, precision) }}
              </div>
            </td>
          </tr>
          <tr style="height: 40px" v-if="tableContentsBuy.length === 0">
            <td colspan="3">
              <p style="color:red;text-align:center;width:100%">در حال حاضر اطلاعاتی وجود
                ندارد</p>
            </td>
          </tr>
          <tr @click.prevent="setShop($toLocal(tableContent.unitPrice,precision),$toLocal(tableContent.volume - tableContent.executedAmount,$decimal[tradeFrom]),'buy')"
              v-for="(tableContent,index) in tableContentsBuy.filter((e,i)=> i<7)" :key="index"
              class="table-row red-percent" style="color:var(--main-green);cursor: pointer"
              :style="{
                        textDecoration: tableContent.myOrder? 'underline' : '',
                        '--executedPercent': (100 - tableContent.executedPercent) +'%',
                        '--color': 'var(--main-green-hover)'
                    }"
              :title="tableContent.myOrder ? 'این سفارش متعلق به شماست' : ''">
            <td>{{ $toLocal(tableContent.unitPrice, precision) }}</td>
            <td>{{
                $toLocal(tableContent.volume - tableContent.executedAmount, $decimal[tradeFrom])
              }}
            </td>
            <td>{{ $toLocal(tableContent.wholePrice, $decimal[tradeTo]) }}</td>
            <!--<span :style="{width: tableContent.executedPercent + '%'}" class="percent2"/>-->
          </tr>
        </table>
      </perfect-scrollbar>
    </div>
  </div>

</template>


<script>
import OverLoading from "@/components/over-loading";
import {tradeTo, tradeFrom, precision} from "@/lib/reuseableFunction";

export default {
  name: "MarketDepth",
  components: {OverLoading},
  data() {
    return {
      tableContentsSell: [],
      tableContentsBuy: [],
      loading: false,
      settings: {
        suppressScrollX: true
      }
    }
  },
  computed: {
    tradeTo, tradeFrom, precision
  },
  methods: {
    setShop(p, a, t) {
      if (t === 'sell') {
        this.state.buyAmount = a
        this.state.buyPrice = p
        ++this.state.buyShop
      } else {
        this.state.sellAmount = a
        this.state.sellPrice = p
        ++this.state.sellShop
      }
    },
    async getMarketDepth() {
      const res = await this.$axios.get('/orders/order-book', {
        params: {
          marketType: this.tradeFrom + '_' + this.tradeTo
        }
      })
      if (res) {
        this.state.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
        this.state.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0

        res.sellOrderBook.reverse()
        this.tableContentsSell = res.sellOrderBook
        this.tableContentsBuy = res.buyOrderBook
      }

      this.loading = false
    },
    async loop() {
      this.state.loading = true
      this.loading = true

      while (this.alive) {
        await this.getMarketDepth()
        this.loading = false
        await this.$sleep(5000)
      }
    }
  },
  mounted() {
    this.alive = true
    if (this.state.loop) {
      this.loop()
    } else {
      this.getMarketDepth()
    }
  },
  beforeDestroy() {
    this.alive = false
  },

}
</script>

<style lang="scss" scoped>
.ps {
  height: 100%;
  width: 100%;
}

.box1 {
  font-size: 12px;
  height: 100%;
}

a {
  color: white;
}

table {
  width: 100%;
  height: 100%;
  align-items: center;
  border-radius: 5px;
  border-collapse: collapse;
}

.table-header {
  position: relative;
  align-items: center;
  height: 30px;
  text-align: center;
  opacity: 0.5;
}

.table-row {
  &.red-percent {
    --executedPercent: 100%;
    --color: var(--main-red-hover);
    background-image: linear-gradient(90deg, transparent var(--executedPercent), var(--color) 0%);
    background-position: left;
    background-size: 100% 16px;
    background-repeat: no-repeat;
  }
}

.table-header th:first-of-type,
.table-row td:first-of-type {
  text-align: right;
  padding-right: 10px;
}

.table-header th:last-of-type,
.table-row td:last-of-type {
  text-align: left;
  padding-left: 10px;
}

.table-row td {
  /*color: black;*/
  opacity: 0.7;
  align-items: center;
  height: 20px;
  text-align: center;
  //cursor: ;
}

.realprice {
  /*position: absolute;*/
  /*top: 0;*/
  width: 100%;
  font-size: 14px;
  display: flex;
  height: 25px;
  justify-content: center;
  align-items: center;
  color: var(--main-green)
}

.realprice svg {
  fill: var(--main-red);
  transform: rotate(90deg)
}

.active {
  color: var(--main-green)
}

.active svg {
  fill: var(--main-green);
  transform: rotate(-90deg)
}


</style>
<template>
    <div class="main-input">
        <div class="input" :class="{invalid : errortext.length,valid : value,readonly:readonly}">
            <label :for="name">{{faname}}</label>
            <div style="position: relative">
                <input @input="sendData" :value="value" :required="required" :type="type1" :name="name" :readonly="readonly">
                <img @click.prevent="toggle('text')" v-if="type==='password' && type1==='password'"
                     src="../../assets/icon/eye.svg" alt="">
                <img @click.prevent="toggle('password')" v-if="type==='password' && type1==='text'"
                     src="../../assets/icon/hide.svg" alt="">
            </div>
            <div class="bottom-border"></div>
        </div>
        <div class="invalid-alert">
            {{errortext}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "AliInput",
        props: ['type', 'name', 'errortext', 'faname','value','readonly','required'],
        data() {
            return {
                type1: this.type
            }
        },
        methods: {
            toggle(e) {
                this.type1 = e
            },
            sendData(e){
                this.$emit('update:value',e.target.value);
            }
        }

    }
</script>

<style scoped>
    .main-input {
        width: 100%;
        margin: 10px 0;
    }

    .input input {
        direction: ltr;
        text-align: left;
        width: 100%;
        height: 44px;
        padding: 10px 35px 10px 10px;
        font-size: 18px;
        color: var(--font-color);
        /*border: 1px solid rgba(0, 0, 0, 0.1);*/
        border-radius: 5px;
        background: transparent;
    }

    label {
        padding: 10px 0;
        font-size: 18px;
        color: var(--font-color);
        pointer-events: none;
        transition: 0.23s;
        margin-bottom: 10px;
    }


    .invalid {
        color: var(--main-red);
    }

    .readonly{
        opacity: 0.5;
    }

    .invalid input,
    .invalid label {
        color: var(--main-red);
        border-color: var(--main-red);
    }

    .invalid .bottom-border {
        background-color: var(--main-red);
    }

    .invalid-alert {
        margin-top: 5px;
        font-size: 10px;
        transition: 0.3s;
        color: var(--main-red);
    }

    .input img {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translate(0, -50%);
    }
</style>
<template>
    <div class="box2">
            <table>
                <tr class="table-header">
                    <th class="mobilehidden">نوع دستگاه</th>
                    <th class="mobilehidden">پلتفرم</th>
                    <th>مرورگر</th>
                    <th>آی‌پی</th>
                    <th>زمان</th>
                    <th>وضعیت</th>
                </tr>
                <tr v-for="(tableContent,index) in tableContents" :key="index" v-show="index<16" class="table-row">
                    <td class="mobilehidden">{{tableContent.deviceType}}</td>
                    <td class="mobilehidden">{{tableContent.osType}}</td>
                    <td class="mobilehidden">{{tableContent.browser}}</td>
<!--                    <td class="mobileshow">{{tableContent.browser.slice(0,6)}}</td>-->
                    <td>{{tableContent.ipAddress}}</td>
                    <td class="mobilehidden">{{$G2J(tableContent.loginRequestedDateTime,'faDateTime')}}</td>
                    <td class="mobileshow">{{$G2J(tableContent.loginRequestedDateTime,'faDate')}}</td>
                    <td v-show="tableContent.isSuccessful" :style="{color:'var(--main-green)'}">موفق</td>
                    <td v-show="!tableContent.isSuccessful" :style="{color:'var(--main-red)'}">ناموفق</td>
                </tr>
            </table>
        <pagination :key="lastPage" v-if="lastPage>1" style="margin-top: 30px" :n="lastPage" :max="3" :page="id" :link="'History'"></pagination>
    </div>
</template>

<script>
    import Pagination from "../Tools/Pagination";

    export default {
        name: "BuyTable",
        components: {Pagination},
        props:['id'],
        data() {
            return {
                buy: false,
                sell: false,
                login: true,
                lastPage: 1,
                tableContents: []
            }
        },
        watch: {
            '$route.params.id'() {
                this.check()
            }
        },
        methods: {
            async check() {
                this.state.loading = true
                console.log('fetch logins')
                const res = await this.$axios.get('/users/login-history', {
                    params: {
                        size: 5,
                        page: this.id
                    }
                })
                this.lastPage = res.totalPages
                // this.tableContents = [...this.tableContents, ...response.data.content]
                this.tableContents = res.content
                console.log(res);
            },
        },
        mounted() {
            console.log('logiiin');
            this.check()
        }
    }
</script>

<style scoped>

    .mobilehidden {
        display: table-cell;
    }

    .mobileshow {
        display: none !important;
    }

    @media (max-width: 800px) {
        .mobilehidden {
            display: none !important;
        }

        .mobileshow {
            display: table-cell !important;
        }
    }
    @media (max-width: 360px) {
        .table-row td{
            font-size: 12px;
        }

    }

    .box2 {
        font-size: 16px;
        border: none;
        background-color: white;
        width: 100%;
        margin-bottom: 30px;
    }

    table {
        background-color: white;
        width: 100%;
        /*min-width: 590px;*/
        /*height: 710px;*/
        border-collapse: collapse;
    }
    table div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 50%;
        top: 30%;
        transform: translate(50%,-50%);
    }
    table div img{
        margin: 55px;
    }

    table th {
        height: 30px;
        font-weight: 300;
        opacity: 0.8;
    }

    .table-header {
        top: 0;
        background-color: var(--dark-main);
        text-align: center;
        z-index: 1;
        color: white;
    }
    .table-header th:first-of-type{
        text-align: right;
    }

    .table-row:nth-of-type(odd){
        height: 40px;
        background-color: var(--hover);
    }
    .table-row:nth-of-type(even){
        height: 45px;
        background-color: white;
    }

    .table-row:nth-of-type(odd) td:first-of-type{
        height: 40px;
    }
    .table-row:nth-of-type(even) td:first-of-type{
        height: 45px;
    }
    .table-row:nth-of-type(odd) td:last-of-type{
        height: 40px;
    }
    .table-row:nth-of-type(even) td:last-of-type{
        height: 45px;
    }


    .table-row td:first-of-type {
        text-align: right !important;
        direction: rtl;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 10px;

    }
    .table-header th:first-of-type{
        padding-right: 10px;
    }


    .table-row td:last-of-type {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .table-row td:first-of-type a {
        margin-right: 5px;
    }

    .table-row td {
        direction: ltr;
        opacity: 0.8;

    }

    .table-row {
        position: relative;
        text-align: center;
        cursor: default;
    }



</style>
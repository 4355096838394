<template>
    <transition name="modal-fade">
        <div id="modal" class="modal-backdrop" @click.self="close">
            <div class="modal" role="dialog"
                 aria-labelledby="WithdrawModal"
                 aria-describedby="WithdrawModal">
                <img src="../../assets/icon/close.svg"
                     class="btn-close"
                     @click="close"
                     aria-label="Close modal" alt="">
                <header class="modal-header">
                    شناسایی دو عاملی گوگل
                </header>
                <section class="modal-body" style="direction: rtl">
                    <p>
                        لطفا بارکد زیر را توسط برنامه Google Authenticator گوشی خود اسکن نمایید.
                    </p>
                    <img
                            width="300px"
                            :src="'data:image/png;base64,'+qrCode"
                            alt=""
                    >
                    <a style="cursor: pointer" @click="Copy(qrLink)">Secret Key = {{qrLink}}</a>
                    <p>پس از اسکن بارکد، مورد مربوط به صرافی ویستا در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند،
                        کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید.</p>
                    <CodeInput :loading="false" class="auth-row"
                               @change="onChange" @complete="submit"
                               :fields="num" ref="code-input" :fieldWidth="55" :fieldHeight="55"/>
                </section>
                <footer class="modal-btn">
                    <button
                            type="button"
                            class="normal-btn"
                            @click="submit">
                        تایید
                    </button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    import CodeInput from "vue-verification-code-input";
    export default {
        name: "setGoogleAuthModal",
        props: ['qrCode','qrLink'],
        components:{CodeInput},
        data() {
            return {
                amount: '',
                twostepcode: '',
                walletaddress: '0x40beE7b4e50d756C57B4e59693905D36B9cCD1B6',
                smscode: [],
                error: '',
                num: 6,
                code: ''
            }
        },
        methods: {
            async Copy(e) {
                await navigator.clipboard.writeText(e)
                alert(' کپی شد')
            },
            close() {
                this.$emit('close');
            },
            /*setPlaceholder() {
                for (let i = 0; i < this.num; i++) {
                    this.$refs['code-input'].$refs['input_' + i][0].placeholder = '----'
                }
            },*/
            onChange(e) {
                this.code = e
            },
            async submit() {
                if (this.code.toString().length === 6) {
                    if (this.$route.name !== 'Wallet') {
                        this.state.loading = true
                        await this.$axios.post(`/users/change-otp?twoStepAuthorization=GOOGLE_AUTHENTICATOR&code=${this.code}`)
                        this.$emit('close')
                        this.$emit('submit', 'شناسایی دو مرحله ای شما به Google Authenticator تغییر یافت.')
                    } else {
                        this.$emit('submit', this.code)
                    }
                }
            }
        },
        mounted() {
            this.$refs['code-input'].$refs['input_0'][0].focus()
            // this.setPlaceholder()
        }
    }
</script>

<style scoped>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 55;
        color: var(--font-color);
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
        flex-direction: column;
    }

    .modal {
        position: relative;
        background: var(--dark-main);
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
        border-radius: 5px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 600px;
        padding: 10px 10px;
    }

    .modal-header {
        color: white;
        font-size: 20px;
        display: flex;
        justify-content: center;
    }

    .btn-close {
        position: absolute;
        left: 15px;
        top: 15px;
        height: 22px;
        width: 22px;
        transition: 0.5s ease-in;
        cursor: pointer;
        z-index: 5;
    }

    .btn-close:hover {
        transform: rotate(180deg);
    }

    .modal-body {
        margin-top: 20px;
        position: relative;
        background-color: white;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        padding: 20px 5% 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .modal-body div:last-of-type {
        margin: 10px 0 10px;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }


    .modal-btn {
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .normal-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: white;
        max-width: 200px;
        margin: 0;
        border-radius: 5px;
        height: 40px;
        padding: 0;
    }

    .normal-btn img {
        margin-left: 10px;
    }

    @media (max-width: 400px) {
        .address {
            font-size: 12px;
        }

    }
    .google-btn{
        max-width: 200px;
    }

    .auth-row {
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        max-width: 350px;
        margin-top: 10px;
    }

    .Auth-input {
        font-size: 20px;
        border-radius: 5px;
        border: solid 1px #00000010;
        width: 55px;
        height: 55px;
        text-align: center;
    }

    .Auth-input:focus {
        border-color: var(--main-orange);
    }
</style>
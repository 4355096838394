<template>
    <div class="Auth-box">
        <form class="Auth" @submit.prevent="send">
            <ali-input @update:value="emailError=''" :value.sync="data.email" type="email"
                       name="email" :errortext="emailError" faname="ایمیل"/>
            <ali-input :value.sync="data.password" type="password" name="password"
                       :errortext="passError" faname="رمز عبور"/>
            <button type="submit" hidden/>
        </form>
        <a style="margin-top: -10px" class="link" @click="$router.push('/recovery-pass')"> فراموشی رمز عبور</a>
        <button @click="send" class="normal-btn" :disabled="!data.email || !data.password || emailError.length>0">ورود</button>
        <a style="margin: 5px 0">یا</a>
        <button class="google-btn" @click="goToGoogle">ورود با گوگل</button>
<!--        <div style="text-align: center;margin-top: 20px;font-size: 14px">-->
<!--            کاربر جدید هستید؟ <a class="link" @click="()=>this.$router.push('/register')">ثبت نام کنید</a>-->
<!--        </div>-->
    </div>
</template>

<script>
    import AliInput from "@/components/Tools/AliInput";
    const goto = {
        EMAIL: '/two-step-email',
        SMS: '/two-step-sms',
        GOOGLE_AUTHENTICATOR: '/two-step-google-auth',
    }
    export default {
        name: "Login",
        components: {AliInput},
        data: () =>
            ({
                show: false,
                email: '',
                emailError: '',
                passError: '',
                password: '',
                data: {
                    email: '',
                    password: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                },
            }),
        methods: {
            goToGoogle(){
                window.location = this.$google_sso
            },
            async send() {
                await this.checkEmail()
                this.state.loading = true
                const res = await this.$axios.post('/users/login', this.data)
                if (res.message === 'login successful, otp is required') {

                    this.$cookies.set('username', this.data.email)
                    this.state.userInfo.email = this.data.email
                    this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';

                    await this.$router.push(goto[res.baseDTO.towStepAuthenticationType])

                }else if (res.token){
                    this.$setToken(res.token,this.state.userInfo.remember)
                    await this.$router.push('/profile')
                }

            },
            async checkEmail() {
                if (!this.$validEmail(this.data.email) || !this.data.email) {
                    this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                    return Promise.reject('repeat failed : ' + this.data.email)
                }
            },
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
            },
            
        },
        mounted(){
            this.getData()
        }

    }
</script>
<style scoped>
    h3 {
        margin-bottom: 50px;
    }

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 470px;
        height: 100%;
        margin:0;
        padding: 10px 0;
        border: none;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .link {
        text-decoration: underline;
        font-size: 12px;
        align-self: end;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .link:hover {
        color: var(--font-hover);
    }

    .normal-btn {
        margin-top: 20px
    }
</style>
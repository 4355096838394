<template>
  <transition name="modal-fade">
    <div id="modal" class="modal-backdrop">
      <div class="modal"
           role="dialog"
           aria-labelledby="WithdrawModal"
           aria-describedby="WithdrawModal">
        <img
            src="../../assets/icon/close.svg"
            class="btn-close"
            @click="close"
            aria-label="Close modal" alt="">
        <header class="modal-header">
          درخواست {{ typeLabel[type] }}
        </header>
        <section class="modal-body" style="direction: rtl">
          <a class="row justify-content-center" style="font-size: 16px">
            آیا از ثبت درخواست
            <b style="margin: 0 3px">{{ typeLabel[type] }}</b>
            به شرح زیر مطمئن هستید؟
          </a>
          <div>
            <a>حد قیمت <a style="font-size: 10px">({{ $coins[tradeTo].persianName }})</a></a>
            <a style="font-weight: bold;font-size: 14px">{{ $toLocal(unitPrice) || 0 }}</a>
          </div>
          <div>
            <a>قیمت واحد <a style="font-size: 10px">({{ $coins[tradeTo].persianName }})</a></a>
            <a style="font-weight: bold;font-size: 14px">{{ $toLocal(unitPrice) || 0 }}</a>
          </div>
          <div>
            <a>مقدار {{ $coins[tradeFrom].persianName }} </a>
            <a style="font-weight: bold;font-size: 14px">{{ $toLocal(amount) || 0 }}</a>
          </div>
          <div v-if="stopLimit">
            <a>حد توقف {{ $coins[tradeFrom].persianName }}</a>
            <a style="font-weight: bold;font-size: 14px">{{ stopPrice }} {{ $coinUnit[tradeTo] }}</a>
          </div>
          <div>
            <a>قیمت کل <a style="font-size: 10px">({{ $coins[tradeTo].persianName }})</a></a>
            <a style="font-weight: bold;font-size: 14px">{{ $toLocal(totalPrice) || 0 }}</a>
          </div>
          <div>
            <a>کارمزد معامله <a style="font-size: 10px">({{
                $coins[type === 'BUY' ? tradeFrom : tradeTo].persianName
              }})</a></a>
            <a style="font-weight: bold;font-size: 14px">{{ fee }}</a>
          </div>
          <div>
            <a>مقدار دریافتی <a
                style="font-size: 10px">({{ $coins[type === 'BUY' ? tradeFrom : tradeTo].persianName }})</a></a>
            <a style="font-weight: bold;font-size: 14px">{{ received }}</a>
          </div>
          <div v-if="date">
            <a>تاریخ انقضا</a>
            <a style="font-weight: bold;font-size: 14px;direction: ltr">{{ $G2J(date, 'faDateTime') }}</a>
          </div>
        </section>
        <footer class="modal-btn">
          <button type="button" class="normal-btn" @click="accept">بله</button>
          <button type="button" class="normal-btn" @click="close">خیر</button>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
const typeLabel = {
  BUY: 'خرید',
  SELL: 'فروش',
}
import {tradeFrom, tradeTo} from "@/lib/reuseableFunction";

export default {
  name: "ShopModal",
  props: ['totalPrice', 'unitPrice', 'amount', 'type', 'date', 'limit', 'stopLimit', 'oco', 'stopPrice', 'limitPrice'],
  data() {
    return {
      twostepcode: '',
      walletaddress: '',
      typeLabel,
    }
  },
  computed: {
    tradeTo, tradeFrom,
    fee() {
      return this.state.userInfo.fee * 0.01 * this.$S2N(this.type === 'BUY' ? this.amount : this.totalPrice)
    },
    received() {
      return this.$S2N(this.type === 'BUY' ? this.amount : this.totalPrice) - this.fee
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async accept() {
      this.state.loading = true;
      let orderDetail, marketType;
      if (this.$route.name === 'Simple') {
        this.$emit('ok')
        this.close()
        return
      } else {
        marketType = this.tradeFrom + '_' + this.tradeTo
      }

      if (this.limit) {
        if (this.stopLimit) {
          orderDetail = {
            marketType: marketType,
            amount: this.$S2N(this.amount),
            orderType: `STOP_LIMIT_${this.type}`,
            expiresAt: this.date || null,
            unitPrice: this.$S2N(this.unitPrice),
            wholePrice: this.$S2N(this.totalPrice),
            stop: this.$S2N(this.stopPrice)
          }
        } else {
          orderDetail = {
            marketType: marketType,
            amount: this.$S2N(this.amount),
            orderType: `LIMITED_${this.type}`,
            expiresAt: this.date || null,
            unitPrice: this.$S2N(this.unitPrice),
            wholePrice: this.$S2N(this.totalPrice),
          }
        }
      } else if (this.oco) {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `OCO_${this.type}`,
          expiresAt: this.date || null,
          unitPrice: this.$S2N(this.unitPrice),
          wholePrice: this.$S2N(this.totalPrice),
          stop: this.$S2N(this.stopPrice),
          limitPrice: this.$S2N(this.stopPrice)
        }
      } else {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `MARKET_${this.type}`,
        }
      }
      try {
        await this.$axios.post(`/orders/${this.type.toLowerCase()}`, orderDetail)
        this.$emit('close')
        this.$emit('submit')
        this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
      } catch (error) {
        this.$emit('close')
        if (error?.response?.data?.message === 'NOT_ACCEPTABLE_UNIT_PRICE') {
          const percent = error?.response?.data?.errors[2]?.replace('difference:', '')
          const text = `قیمت واحد با مقدار جهانی ${percent} درصد اختلاف دارد`
          const text2 = 'آیا از درخواست خود مطمئن هستید؟'
          const res1 = await this.$error(text, text2, 'warning', 'بله', 'خیر')
          if (res1.isConfirmed) {
            this.state.loading = true
            const [res2,] = await this.$http.post(`/orders/${this.type.toLowerCase()}?force=true`, orderDetail)
            if (res2) {
              ++this.state.orderChanged
              this.$error('', 'سفارش با موفقیت ثبت شد', 'success')
            }
          }
        }
      }
    },
  },
}
</script>

<style scoped>

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(19, 22, 27, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 55;
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  color: var(--font-color);
  flex-direction: column;
}

.dark .modal-backdrop{
    background-color: rgba(255,255,255,0.2);
}

.modal {
  position: relative;
  background: var(--dark-main);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 5px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  padding: 10px 10px;
}

.modal-header {
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.btn-close {
  position: absolute;
  left: 15px;
  top: 15px;
  height: 22px;
  width: 22px;
  transition: 0.5s;
  cursor: pointer;
  z-index: 5;
}

.btn-close:hover {
  transform: rotate(180deg);
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  position: relative;
  background-color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 20px 30px 20px;
}

.modal-body div {
  width: 100%;
  max-width: 327px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body div:first-of-type {
  margin-top: 15px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .25s ease;
}


.modal-btn {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.normal-btn {
  font-size: 16px;
  color: white;
  max-width: 150px;
  margin: 0 15px;
  border-radius: 5px;
  height: 40px;
  padding: 0;
}


</style>

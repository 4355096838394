<template>
    <div class="main-box">
        <h1>راهنمای استفاده</h1>
        <div class="message-block"
             v-for="(message,index) in messages"
             :key="index">
            <div @click.prevent="toggle(index)" class="message-header">
                <div class="header-header">
                    <img src="../../assets/icon/question.svg" alt="">
                    <h3>{{message.title}}</h3>
                </div>
                <svg height="14px" :style="{transform: [showmessage[index] ?  'rotate(90deg)':'' ],
                fill: [showmessage[index] ?  'var(--main-orange)':'' ]}"
                     version="1.1" xmlns="http://www.w3.org/2000/svg"
                     x="0px" y="0px" viewBox="0 0 443.52 443.52" xml:space="preserve">
                <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                L143.492,221.863z"/>
                </svg>
            </div>
            <div class="message-description" :style="{maxHeight: showmessage[index]?  messageheight[index]+'px' : ''}">
                <froalaView v-model="message.html"/>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "HowToUse",
        data() {
            return {
                showmessage: [],
                messageheight:[],
                messages: [],
            }
        },
        methods: {
            toggle(e) {
                this.messageheight[e] = document.getElementsByClassName('message-description')[e].firstChild.clientHeight + 20
                for (let i = 0; i < this.messages.length; i++) {
                    if (e === i)
                        this.showmessage[e] = !this.showmessage[e];
                    else
                        this.showmessage[i] = false
                }
                this.$forceUpdate()
            },
            async getHowToUse() {
                this.state.loading = true
                this.messages = await this.$axios.get('/user-manual')
                for (let i = 0; i < this.messages.length; i++) {
                    this.showmessage[i] = false
                }
            },
        },
        mounted() {
            this.getHowToUse()
        }

    }
</script>

<style lang="scss" scoped>

    .main-box {
        display: inline-block;
        background-color: transparent;
        text-align: right;
        padding: 0 10px 50px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1170px;
    }

    h1 {
        font-family: IRANSans-Bold, sans-serif;
        font-size: 30px;
        color: var(--font-color);
        text-align: center;
        margin-top: 80px;
    }

    .message-block {
        display: block;
        flex-direction: column;
        align-items: center;
        background-color: white;
        text-align: right;
        border-bottom: #00000015 solid 1px;
        box-sizing: border-box;
        width: 100%;
        font-size: 18px;
        transition: 0.3s;
    }

    .message-header {
        position: relative;
        display: flex;
        height: 61px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        svg {
            transform: rotate(-90deg);
            margin-left: 10px;
            transition: 0.3s;
        }

        &:hover {
            svg {
                fill: var(--main-orange);
            }
        }
    }

    .message-header h3 {
        font-family: VistaDev, sans-serif;
        font-weight: 300;
        font-size: 18px;
        color: var(--font-color);
    }

    .message-description {
        font-size: 16px;
        overflow: hidden;
        transition: 0.4s ease-out;
        -webkit-transition: 0.4s ease-out;
        -moz-transition: 0.4s ease-out;
        max-height: 0;

        p {
            margin-top: 0;
            color: var(--font-color);
        }
    }

    .header-header {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            margin: 0 20px
        }
    }

</style>
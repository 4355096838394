<template>
    <div class="touch-menu" style="padding-bottom: 10px" :style="{backgroundColor:[currentroute==='Trade'? 'var(--dark-background' : '']}">
        <div class="touch-menu-header">
            <svg height="20px" @click="$emit('close')" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                 x="0px" y="0px" fill="#ffffff80"
                 viewBox="0 0 443.52 443.52" style="enable-background:new 0 0 443.52 443.52;" class="back"
                 xml:space="preserve">
                        <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                        c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                        L143.492,221.863z"/>
                    </svg>
            
            <div @click="$emit('account')" class="account-active">
                <p style="margin:0;overflow: hidden;width: calc(100% - 29px);text-overflow: ellipsis" :dir="state.userInfo.authenticationType === 'UNAUTHORIZED'?'ltr':'rtl'">
                    {{state.userInfo.authenticationType === 'UNAUTHORIZED' ? state.userInfo.email :
                    state.userInfo.firstName}}
                </p>
                <svg style="margin: 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="var(--font-hover)"
                     width="29px" height="29">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/>
                </svg>
            </div>
        </div>
        <!--        <div v-show="currentroute==='Trade'" class="contents light-on" @click.prevent="changetheme('')">-->
        <!--            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" class="back"-->
        <!--                 viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" width="25px" height="25px" xml:space="preserve">-->
        <!--            <path d="M26,8.1V16l2,1.2c2.5,1.4,4,4.1,4,6.9c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-2.8,1.5-5.5,4-6.9l2-1.2V8.1H26 M30,4.1H18v9.6-->
        <!--            c-3.6,2.1-6,5.9-6,10.4c0,6.6,5.4,12,12,12s12-5.4,12-12c0-4.4-2.4-8.3-6-10.4C30,13.7,30,4.1,30,4.1z"/>-->
        <!--            </svg>نمای تاریک-->
        <!--        </div>-->
        <!--        <div v-show="currentroute==='Trade'" class="contents light-off" @click.prevent="changetheme('dark')">-->
        <!--            <svg class="back"-->
        <!--                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="25px" height="25px">-->
        <!--                <path d="M3.55 19.09l1.41 1.41 1.79-1.8-1.41-1.41zM11 20h2v3h-2zM1 11h3v2H1zm12-6.95v3.96l1 .58c1.24.72 2 2.04 2 3.46 0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.42.77-2.74 2-3.46l1-.58V4.05h2m2-2H9v4.81C7.21 7.9 6 9.83 6 12.05c0 3.31 2.69 6 6 6s6-2.69 6-6c0-2.22-1.21-4.15-3-5.19V2.05zM20 11h3v2h-3zm-2.76 7.71l1.79 1.8 1.41-1.41-1.8-1.79z"/>-->
        <!--            </svg>نمای روشن-->
        <!--        </div>-->
        <div :style="{height: 'calc(100vh/' + (contents.length+1) +')'}"
             :class="[currentroute === contents[0].route ? 'selected' : '']" class="contents" @click="$router.push(contents[0].link)">
            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px">
                <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/>
                <circle v-show="false" fill="#f76500" cx="16" cy="6" r="4px"/>
            </svg>
            {{contents[0].name}}
        </div>
        <div>

        </div>
        <div :style="{height: 'calc(100vh/' + (contents.length+1) +')'}"
             :class="[currentroute === content.route ? 'selected' : '']" v-show="index>0" v-for="(content,index) in contents"
             :key="index" class="contents" @click="$router.push(content.link)">
            <img class="icon" :src="content.img" :alt="'image'+index">
            {{content.name}}
        </div>
       
    </div>
</template>

<script>
    export default {
        name: "touchMenuAccount",
        props: ['currentroute'],
        data() {
            return {
                contents: [
                    {
                        name: 'اعلانات',
                        link: '/messages',
                        img: require('../../../assets/icon/bell.svg'),
                        route: 'Messages'
                    },
                    {
                        name: 'حساب کاربری',
                        link: '/profile',
                        img: require('../../../assets/icon/name.svg'),
                        route: 'Profile'
                    },
                    {
                        name: 'امنیت',
                        link: '/setting',
                        img: require('../../../assets/icon/shield.svg'),
                        route: 'Setting'
                    },
                    {
                        name: 'کیف پول',
                        link: '/wallet',
                        img: require('../../../assets/icon/wallet.svg'),
                        route: 'Wallet'
                    },
                    {
                        name: 'تاریخچه',
                        link: '/history',
                        img: require('../../../assets/icon/history.svg'),
                        route: 'History'
                    },
                    {
                        name: 'دعوت دوستان',
                        link: '/invite',
                        img: require('../../../assets/icon/invite.svg'),
                        route: 'Invite'
                    },
                    {
                        name: 'خروج',
                        link: '/sign-in',
                        img: require('../../../assets/icon/power.svg'),
                    },
                ]
            }
        },
        methods: {
            changetheme(e) {
                localStorage.theme = e;
                this.state.theme = localStorage.theme
            }
        }
    }
</script>

<style scoped>
    .touch-menu .account {
        display: flex;
    }

    .touch-menu-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 54px;
        align-items: center;
        border-bottom: solid 1px rgba(255, 255, 255, 0.3);
        padding: 0 10px;
    }

    /*.dark .touch-menu-header{*/
    /*    border-color: rgba(255,255,255,0.3);*/
    /*}*/

    .touch-menu {
        font-size: 18px !important;
        position: absolute;
        /*position: -webkit-sticky;*/
        left: 0;
        top: 0;
        background-color: var(--light-background);
        display: flex;
        width: 60%;
        max-width: 250px;
        flex-direction: column;
        overflow: hidden;
        transition: 0.25s;
        z-index: 5;
        height: 100vh;
        padding: 0 10px;
        margin-left: -300px;
    }

    /*.touch-menu a {*/
    /*    display: flex;*/
    /*    align-items: center;*/
    /*    !*height: calc(100vh/12.5);*!*/
    /*    max-height: 70px;*/
    /*    !*margin: 20px 0;*!*/
    /*    text-align: right;*/
    /*    opacity: 0.8;*/
    /*    padding-right: 10px;*/
    /*    color: white;*/
    /*}*/

    /*.dark .touch-menu {*/
    /*    background-color: var(--dark-main);*/
    /*    color: white;*/
    /*}*/
    /*.dark .touch-menu a {*/
    /*    color: white;*/
    /*}*/
    /*.dark .back {*/
    /*    fill: white;*/
    /*}*/

    /*.light-on {*/
    /*    display: none !important;*/
    /*}*/
    /*.light-off{*/
    /*    display: flex;*/
    /*}*/

    /*.dark .light-off {*/
    /*    display: none;*/
    /*}*/

    /*.dark .light-on {*/
    /*    display: flex !important;*/
    /*}*/
    /*.dark .icon{*/
    /*    filter: invert(100%);*/
    /*}*/


    .account-active {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: var(--font-hover);
        width: calc(100% - 29px);
    }

    .contents {
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        max-height: 70px;
        /*margin: 20px 0;*/
        padding-right: 10px;
        color: white;
    }

    .contents img {
        margin-left: 10px;
        filter: invert(100%)
    }

    .contents svg{
        margin-left: 10px;
        fill: white;
    }

    .selected {
        color: var(--font-hover) !important;
        /*height: 30px;*/
        border-bottom: var(--font-hover) solid 2px;
        cursor: default;
    }

    .selected img {
        filter: invert(44%) sepia(88%) saturate(388%) hue-rotate(161deg) brightness(87%) contrast(91%);
    }
    .selected svg{
        fill: var(--font-hover)
    }

    .selected a {
        color: var(--font-hover);
    }
</style>
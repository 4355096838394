<template>
  <div class="main">
    <!--        <trade-nav-bar :maintable="maintable" :tradefrom="tradefrom[tradefromid]"-->
    <!--                       :tradeto="tradeto[tradetoid]"></trade-nav-bar>-->
    <div class="row trade-main">
      <div class="col-14 col-lg-3 pad order-3 order-lg-0 h-1">
        <shop class="box h-2"/>
        <real-price v-if="true" class="box h-4 p-relative"/>
      </div>
      <div class="col-14 col-lg-3 pad order-4 order-lg-1 h-1">
        <market-depth class="box h-2"/>
        <last-trade class="box h-4"/>
      </div>
      <div class="col-14 col-lg-8 h-3 order-first order-lg-3">
        <trade-header id="trade-header" class="h-7 col-14"/>
        <vue-trading-view :key="$coins[tradeFrom].code + state.theme" class="h-6" :options="{
                                autosize:true,
                                symbol: 'BINANCE:' + $coins[tradeFrom].code + 'USDT',
                                timezone: 'Asia/Tehran',
                                theme: state.theme,
                                // library_path: require('../assets'),
                                style: '1',
                                locale: 'fa_IR',
                                toolbar_bg: '#f1f3f6',
                                enable_publishing: false,
                                withdateranges: true,
                                range: 'ytd',
                                // user_id:'Nima2142',
                                hide_side_toolbar: false,
                                allow_symbol_change: true,
                                toolbar_bg: '#fff',
                                loading_screen:{
                                  backgroundColor : '#000',
                                  foregroundColor : '#000'
                                },
                                disabled_features: [
                                     'border_around_the_chart',
                                ],
                                enabled_features: [
                                      'remove_library_container_border',
                                      'remove_container_border',
                                      'dont_show_boolean_study_arguments',
                                      'use_localstorage_for_settings',
                                      'remove_library_container_border',
                                      'save_chart_properties_to_local_storage',
                                      'side_toolbar_in_fullscreen_mode',
                                      'hide_last_na_study_output',
                                      'constraint_dialogs_movement',
                                      'header_fullscreen_button'
                                ],
                                studies_overrides: {
                                    'volume.volume.color.0': '#f84960',
                                    'volume.volume.color.1': '#01bf76',
                                    'volume.volume.transparency': 75,
                                },
                                overrides:{
                                    'paneProperties.background': '#000',
                    'paneProperties.vertGridProperties.color': 'rgba(83,87,96,0)',
                    'paneProperties.horzGridProperties.color': 'rgba(54,60,78,0)',
                    'mainSeriesProperties.areaStyle.color1': '#f8b200',
                    'mainSeriesProperties.areaStyle.color2': '#f8b200',
                    'mainSeriesProperties.areaStyle.linecolor': '#f8b200',
                    'mainSeriesProperties.lineStyle.color': '#f8b200',
                    'mainSeriesProperties.candleStyle.upColor': '#01bf76',
                    'mainSeriesProperties.candleStyle.downColor': '#f84960',
                    'mainSeriesProperties.candleStyle.borderColor': '#01bf76',
                    'mainSeriesProperties.candleStyle.borderUpColor': '#01bf76',
                    'mainSeriesProperties.candleStyle.borderDownColor': '#f84960',
                    'mainSeriesProperties.candleStyle.wickUpColor': '#01bf76',
                    'mainSeriesProperties.candleStyle.wickDownColor': '#f84960',
                    'mainSeriesProperties.hollowCandleStyle.upColor': '#01bf76',
                    'mainSeriesProperties.hollowCandleStyle.downColor': '#f84960',
                    'mainSeriesProperties.hollowCandleStyle.borderColor': '#01bf76',
                    'mainSeriesProperties.hollowCandleStyle.borderUpColor': '#01bf76',
                    'mainSeriesProperties.hollowCandleStyle.borderDownColor': '#f84960',
                    'mainSeriesProperties.hollowCandleStyle.wickUpColor': '#01bf76',
                    'mainSeriesProperties.hollowCandleStyle.wickDownColor': '#f84960',
                    'mainSeriesProperties.haStyle.upColor': '#01bf76',
                    'mainSeriesProperties.haStyle.downColor': '#f84960',
                    'mainSeriesProperties.haStyle.borderColor': '#01bf76',
                    'mainSeriesProperties.haStyle.borderUpColor': '#01bf76',
                    'mainSeriesProperties.haStyle.borderDownColor': '#f84960',
                    'mainSeriesProperties.haStyle.wickUpColor': '#01bf76',
                    'mainSeriesProperties.haStyle.wickDownColor': '#f84960',
                    'mainSeriesProperties.barStyle.upColor': '#01bf76',
                    'mainSeriesProperties.barStyle.downColor': '#f84960',
                    'scalesProperties.backgroundColor':'#000'
                                },
                                custom_css_url: require('@/style/ali.css'),
                                }"/>
        <orders class="box h-5" @completed="completed"/>
      </div>
    </div>

  </div>
</template>

<script>

import LastTrade from "./LastTrade";
import MarketDepth from "./MarketDepth";
import Shop from "./Shop/Shop";
import VueTradingView from 'vue-trading-view';
import Orders from "@/components/Trade/Orders";
import TradeHeader from "@/components/Trade/TradeHeader";
import RealPrice from "components/Trade/RealPrice/RealPrice";

export default {
  name: "Trade",
  props: ['tradeTo', 'tradeFrom'],
  components: {
    RealPrice,
    TradeHeader,
    Orders,
    Shop,
    MarketDepth,
    LastTrade,
    VueTradingView
  },
  data() {
    return {
      completedmodal: '',
      theme: 'dark'
    }
  },
  methods: {
    completed() {
      this.completedmodal = true
      setTimeout(() => {
        this.completedmodal = false
      }, 1500);
    }
  },
}
</script>

<style lang="scss" rel="stylesheet" src="../../assets/bootstrap-grid-rtl-2/bootstrap-grid-rtl.scss" scoped/>
<style scoped>
.main {
  width: 100%;
  /*max-width: 1440px;*/
  padding: 0 5px;
  display: flex;
  justify-content: center;
  /*max-height: 720px;*/
}

.trade-main {
  width: 100%;
  //max-width: 1440px;
}

.pad {
  padding-bottom: 5px;
}

.box {
  background-color: white;
  color: var(--font-color);
  margin-top: 5px;
}

.dark .box {
  background-color: var(--dark-background);
  color: white;
}

.h-1, .h-3 {
  height: calc(100vh - 54px - 5px - 5px);
}

.h-2 {
  height: calc(61% - 2px);
}

.h-4, .h-5 {
  height: calc(39% - 5px);
}

.h-6 {
  height: calc(61% - 60px - 10px);
  margin-top: 5px;
}

.h-7 {
  min-height: 60px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .h-1, .h-3 {
    height: calc(700px);
  }

  .h-1:first-of-type {
    height: 400px;
  }

  /*.h-2 {*/
  /*    height: calc(61%)*/
  /*}*/
  .h-6 {
    height: calc(61% - 80px - 10px);
    margin-top: 5px;
  }
}

@media (max-width: 584px) {
  .h-1, .h-3 {
    height: 800px;
  }

  .h-6 {
    height: calc(61% - 115px - 10px);
    margin-top: 5px;
  }
}

/*@media (max-width: 992px ) {*/
/*    .h-1, .h-2, .h-3 {*/
/*        height: 445px;*/
/*    }*/

/*    .h-4 {*/
/*        height: 350px;*/
/*    }*/

/*    .h-5 {*/
/*        height: 270px;*/
/*    }*/
/*}*/

/*@media (max-width: 574px) {*/
/*    .h-4 {*/
/*        height: 600px;*/
/*    }*/

/*}*/

/*.dark {*/
/*    background-color: var(--dark-background) !important;*/
/*    color: white !important;*/
/*}*/

/*.chart-page {*/
/*    background-color: transparent !important;*/
/*}*/

/*body #widget-container {*/
/*    border: none !important;*/
/*}*/

</style>
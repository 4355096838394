<template>
    <div class="main-box">
        <h1>کارمزدهای صرافی ویستا</h1>
        <div class="content">
            <h2>کارمزد معاملات</h2>
            <p>کارمزد معاملات در سامانه ما بصورت درصدی از مبلغ کل معامله است و محاسبه‌ی آن بر اساس ملاحظات زیر صورت
                می‌گیرد. لازم به توضیح است که کسر کارمزد از معاملات باعث جلوگیری از ثبت معاملات صوری و مکرر خواهد شد و
                شرایط مطلوب‌تری را در بازار برای تمامی کاربران ایجاد می‌کند.</p>
            <ul>
                <li>
                    <a>
                        کارمزد خریدار ارزدیجیتال به صورت ارزدیجیتال کسر و کارمزد فروشنده ارزدیجیتال از مبلغ دریافتی به
                        تومان
                        کسر می شود.
                    </a>
                </li>
                <li>
                    <a>
                        کارمزد از هر دو سمت معامله کسر می شود.
                    </a>
                </li>
                <li>
                    <a>
                        در هنگام ثبت معاملات، میزان کارمزد به شما نمایش داده می شود.
                    </a>
                </li>
            </ul>

            <table>
                <tr>
                    <th>حجم معامله در سه ماه اخیر</th>
                    <th>میزان کارمزد</th>
                </tr>
                <tr v-for="(level,index) in $accountLevel" :key="index">
                    <td v-if="index === 0">کمتر از {{$accountLevel[index].tradeAmount.toString().slice(0,-6)}} میلیون</td>
                    <td v-else-if="index === 3">بیشتر از {{$accountLevel[index-1].tradeAmount.toString().slice(0,-6)}} میلیون</td>
                    <td v-else>
                        از
                        {{$accountLevel[index-1].tradeAmount.toString().slice(0,-6)}}
                        تا
                        {{$accountLevel[index].tradeAmount.toString().slice(0,-6)}}
                        میلیون
                    </td>
                    <td>% {{level.wagePercent}}</td>
                </tr>
            </table>

            <p>لازم به ذکر است کارمزد معاملات جفت ارز (به طور مثال بیت کوین - تتر) به صورت ثابت ۰.۲% در نظر گرفته می
                شود.</p>
        </div>
        <div class="content">
            <h2>کارمزد واریز و برداشت ارز دیجیتال</h2>
            <ul>
                <li>
                    <a>
                        کارمزد واریز تمامی دارایی‌های دیجیتال (از جمله بیت‌کوین، اتریوم، لایت‌کوین، ریپل، بیت‌کوین کش،
                        بایننس کوین، تتر و ...) صفر است.
                    </a>
                </li>
                <li>
                    <a>
                        کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای
                        تراکنش
                        شبکه کسر می‌شود و ما در آن نقشی نداریم.
                    </a>
                </li>
            </ul>

            <table>
                <tr>
                    <th>نوع ارز</th>
                    <th>میزان کارمزد</th>
                </tr>
                <tr v-for="coin in coins" :key="coin.coin">
                    <td>{{$coins[coin.coin].persianName}}</td>
                    <td>{{coin.transferFee}} {{$coins[coin.coin].code}}</td>
                </tr>
            </table>
        </div>
        <div class="content">
            <h2>کارمزد برداشت ریالی</h2>

            <p>برداشت‌های ریالی برای تمامی حساب‌ها، در قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان
                انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول
                انجامد.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Wage",
        data(){
            return{
                coins:[],
            }
        },
        methods:{
            async getData(){
                const res = await this.$axios.get('/coins')
                this.coins = res.content
            }
        },
        mounted() {
            this.$getFees()
            this.getData()
        }
    }
</script>

<style scoped>

    .main-box {
        display: flex;
        flex-direction: column;
        background-color: white;
        direction: rtl;
        text-align: right;
        box-sizing: border-box;
        width: 100%;
        max-width: 1170px;
        padding: 15px;
        font-size: 17px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 100%;
        margin-bottom: 50px;
    }

    h1 {
        font-size: 30px;
        margin: 80px -10px 50px 0;
        text-align: center;
        color: var(--font-color);
    }

    h2 {
        font-family: IRANSans-Medium,sans-serif;
        font-weight: 500;
        font-size: 21px;
        margin-top: 0;
        margin-bottom: 10px;
        color: var(--font-color);
    }

    p {
        margin: 10px 0;
        text-align: justify;
        color: var(--font-color);
    }

    ul {
        margin: 10px 0 0 0;
        list-style-position: inside;
    }

    ul li {
        margin-bottom: 10px;
        color: var(--main-orange);
        text-align: justify;
    }

    ul li:last-of-type {
        margin-bottom: 0;
    }


    table {
        font-size: 14px;
        border-radius: 5px;
        border: solid 1px #00000010;
        background-color: #ffffff;
        text-align: center;
        align-self: center;
        margin: 30px 0;
        width: 100%;
        max-width: 350px;
        border-collapse: collapse;
        color: var(--font-color);
    }

    th, td {
        height: 52px;
    }

    th {
        border-bottom: var(--main-orange) 2px solid;
    }

    th:first-of-type {
        min-width: 100px;
    }

    td {
        border-bottom: #00000010 2px solid;
    }

</style>
<template>
  <div class="main-box">
    <div class="tab">
      <div :class="type === 'LIMIT' ? 'tab-content-active' : 'tab-content'"
           @click.prevent="type = 'LIMIT'">
        <a>حد</a>
      </div>
      <div :class="type === 'MARKET' ? 'tab-content-active' : 'tab-content'"
           @click.prevent="type = 'MARKET'">
        <a>بازار</a>
      </div>
      <div :class="type === 'STOP_LIMIT' ? 'tab-content-active' : 'tab-content'"
           @click.prevent="type = 'STOP_LIMIT'">
        <a>حد توقف</a>
      </div>
      <div :class="type === 'OCO_STOP_LIMIT' ? 'tab-content-active' : 'tab-content'"
           @click.prevent="type = 'OCO_STOP_LIMIT'">
        <a>OCO</a>
      </div>
      <!--            <div class="selector" :style="{right :[limit? '' : '50%']}"></div>-->
    </div>
    <div class="tab2">
      <div style="border-top-right-radius: 5px;border-bottom-right-radius: 5px"
           :class="[!buy ? 'tab-content2' : 'tab-content-active2']"
           @click.prevent="buy=true">
        <a>خرید</a>
      </div>
      <div style="border-top-left-radius: 5px;border-bottom-left-radius: 5px" class="sell"
           :class="[buy ? 'tab-content2' : 'tab-content-active2']"
           @click.prevent="buy=false">
        <a>فروش</a>
      </div>
      <!--<div class="selector" :style="{right :[limit? '' : '50%']}"></div>-->
    </div>
    <div class="row box1 no-gutters" style="height:100% ;max-height: 400px;">
      <div class="col-12 p-0" v-if="buy">
        <buy-crypto
            @submit="getWalletCredit"
            :cash="buyCredit"
            :role="role"
            :wage="state.userInfo.fee"
            :limit="type.includes('LIMIT')"
            :stop-limit="type.includes('STOP')"
            :oco="type.includes('OCO')"
            :id="1"
            :key="'buy' + limit"
        />
      </div>
      <div class="col-12 p-0" v-if="!buy">
        <sell-crypto
            @submit="getWalletCredit"
            :cash="sellCredit"
            :role="role"
            :wage="state.userInfo.fee"
            :limit="type.includes('LIMIT')"
            :stop-limit="type.includes('STOP')"
            :oco="type.includes('OCO')"
            :id="2"
            :key="'sell' + limit"
        />
      </div>

    </div>
  </div>
</template>

<script>
import BuyCrypto from "./BuyCrypto";
import SellCrypto from "./SellCrypto";
import {tradeTo, tradeFrom,Loop} from "lib/reuseableFunction";

export default {
  name: "Shop",
  components: {SellCrypto, BuyCrypto,},
  props: ['coin'],
  data() {
    return {
      limit: true,
      sellCredit: 0,
      buyCredit: 0,
      buy: true,
      type: 'LIMIT',
      loop:undefined,
    }
  },
  computed: {
    tradeTo, tradeFrom,
  },
  methods: {
    async getWalletCredit() {
      this.loop = new Loop(this.getBalance, 1000, "getBalance");
      if (this.state.loop && this.state.userInfo.authorized) {
        this.loop.start();
      } else {
        this.getBalance();
      }
      const coins = await this.$axios.get("/coins/details");
      this.role = coins?.content?.find((a) => a.coin === this.tradeFrom);
    },
    async getBalance() {
      if (this.state.userInfo.authorized) {
        const res = await this.$axios.get("/wallets/customer-addresses", {
          params: {
            walletType: 'P2P',
            coins: `${this.tradeFrom},${this.tradeTo}`,
          },
        });
        this.sellCredit = res[this.tradeFrom].credit || 0;
        this.buyCredit = res[this.tradeTo].credit || 0;
      }
    },
    closemodal() {
      this.showmodal = false
    },
  },
  created() {
    this.getWalletCredit()
  },
  beforeDestroy() {
    this.loop?.stop();
  },

}
</script>

<style scoped>
.main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: right;
  padding: 0 0 5px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 1400px;
  font-size: 12px;
  background-color: transparent;
}

.dark a {
  color: white;
}


.tab {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 30px;
  /*border-bottom: #00000010 1px solid;*/
  font-size: 12px;
  align-items: center;
  background-color: white;
  text-align: right;
  width: 100%;
}

.dark .tab {
  background-color: var(--dark-main);
}

.tab-content {
  width: 50%;
  height: 30px;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-content-active {
  font-weight: 800;
  width: 50%;
  height: 30px;
  opacity: 1;
  text-align: center;
  border-bottom: 2px solid var(--main-orange);
  /*background-color: var(--dark-background);*/
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.dark .tab a {
  color: white;
}

.tab a {
  color: var(--font-color);
}

.tab2 {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 30px;
  /*border-bottom: #00000010 1px solid;*/
  font-size: 12px;
  align-items: center;
  background-color: white;
  text-align: right;
  width: 100%;
  margin: 10px 0;
}

.dark .tab2 {
  background-color: var(--dark-background);
}

.tab-content2 {
  width: 50%;
  height: 30px;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main-gray);
}

.tab-content-active2 {
  font-weight: 800;
  width: 50%;
  height: 30px;
  opacity: 1;
  text-align: center;
  background-color: var(--main-green);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.tab-content-active2.sell {
  background-color: var(--main-red);
}

.tab-content-active2 a {
  color: white !important;
}

.dark .tab2 a {
  color: var(--font-color);
}

.tab2 a {
  color: var(--font-color);
}

.box1 {
  /*background-color: white;*/
  width: 100%;
}

/*.selector {*/
/*    position: absolute;*/
/*    background-color: var(--main-orange);*/
/*    width: 50%;*/
/*    height: 2px;*/
/*    border-radius: 5px;*/
/*    bottom: -1px;*/
/*    right: 0;*/
/*    transition: 0.5s;*/
/*}*/

</style>
import Vue from 'vue'
import App from './App.vue'
import '@babel/polyfill'
// import 'roboto-fontface/css/roboto/roboto-fontface.css'
// import '@mdi/font/css/materialdesignicons.css'
import 'style/style.scss'
import '@/style/Font/Font-Yekan.css'
import 'style/bootstrap-grid-rtl.css'
import PerfectScrollbar from '@nima2142/vue2-perfect-scrollbar'
import '@nima2142/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import Trend from "vuetrend"
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueCookies from 'vue-cookies';
import VuePersianDatetimePicker from 'vue-persian-datetime-picker'
import VueFroala from 'vue-froala-wysiwyg'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import { VBTooltip } from 'bootstrap-vue'
import '@/style/b-tooltip.css'
//

Vue.use(VueSweetalert2);
Vue.use(Trend)
Vue.component('date-picker', VuePersianDatetimePicker)
Vue.use(PerfectScrollbar,{
    options:{
        // swipeEasing: false,
        wheelPropagation: false,
    }
});
Vue.use(VueCookies)
Vue.use(VueFroala)
Vue.directive('tooltip', VBTooltip)
Vue.$cookies.config('session')

Vue.config.productionTip = false

import router from '@/lib/router'
import '@/lib/state'
import '@/lib/axios'
import '@/lib/globalConstants'
import '@/lib/globalFunctions'

new Vue({
    render: h => h(App),
    router
}).$mount('#app')

<template>
  <div class="main">
    <form @input="calculate">
      <div class="footer">
        <a>موجودی</a>
        <a class="link" @click="putcash(1)">
          {{ $toLocal(cash, precision) }}
          {{ $coins[tradeFrom].code }}
        </a>
      </div>
      <div v-if="oco" class="row no-gutters">
        <label class="col-5 col-sm-12 col-md-5" :for="`oco${id}`">حد قیمت ({{ $coins[tradeTo].persianName }})</label>
        <input
            @input="limitPrice = $toLocal(limitPrice,precision)"
            class="col-7 col-sm-12 col-md-7"
            type="text"
            v-model="limitPrice"
            :id="`oco${id}`"
        />
      </div>
      <div class="row no-gutters">
        <label class="col-5" for="unitprice">
          قیمت
          <small>({{ $coins[tradeTo].persianName }})</small>
        </label>
        <input @input="unitPrice = $toLocal(unitPrice,precision)"
               :class="{dang : danger}" class="col-7" type="text"
               v-model="unitPrice" id="unitprice" :readonly="!limit"
               :placeholder="!limit? ($coins[tradeFrom].persianName + ' - ' + $coins[tradeTo].persianName) : ''">
      </div>
      <div class="row no-gutters">
        <label class="col-5" for="amount">
          مقدار {{ $coins[tradeFrom].persianName }}
        </label>
        <input @input="amount = $toLocal(amount,decimal)"
               :class="{dang : danger1}"
               class="col-7"
               type="text"
               v-model="amount"
               id="amount">
      </div>
      <div class="row no-gutters">
        <div class="col-7 offset-5" id="slider">
          <span class="slider-back"/>
          <template v-for="n in 5">
            <!--<a :key="n" @click.prevent="value=((n-1)*0.25);putcash(decimal,value);" class="percent">{{(n-1)*25}}٪</a>-->
            <span :key="-n" @click.prevent="putcash(percent=((n-1)*0.25))"
                  class="dot" :class="'dot'+n"/>
          </template>
          <input @input="putcash()" style="direction: ltr" type="range" class="slider"
                 :id="'one'+id" :name="'one'+id" min="0" max="1" step="0.01"
                 v-model="percent">
          <label class="rangenumber"
                 :style="{left: 'min(100%,'+percent*100 + '%)',transform : 'translatex(max(-100%,-'+percent*100+'%))'}"
                 :for="'one'+id">
            <a>{{ (percent * 100).toFixed(0) }}٪</a>
          </label>
        </div>
      </div>
      <div v-if="stopLimit" class="row no-gutters">
        <label class="col-5 col-sm-12 col-md-5" :for="`stop${id}`">حد توقف ({{ $coins[tradeTo].persianName }})</label>
        <input
            @input="stopPrice = $toLocal(stopPrice,precision)"
            class="col-7 col-sm-12 col-md-7"
            type="text"
            v-model="stopPrice"
            :id="`stop${id}`"
        />
      </div>
      <div class="row no-gutters" style="position: relative">
        <label class="col-5" for="totalprice">
          قیمت کل
          <small>({{ $coins[tradeTo].persianName }})</small>
        </label>
        <input class="col-7"
               type="text"
               v-model="totalPrice"
               id="totalprice"
               :class="{dang:danger2}"
               readonly>
        <over-loading v-if="calculating" style="margin:0"/>
      </div>
      <div v-if="limit" class="row no-gutters">
        <label class="col-5" for="exp">تاریخ انقضا</label>
        <date-picker id="exp" type="datetime" v-model="eDate"
                     :placeholder="'تاریخ انقضا'"
                     color="var(--main-orange)"
                     :format="$dateFormat['en']"
                     :display-format="$dateFormat['faDateTime']"
                     :min="now"
                     clearable
                     class="col-7 col-sm-12 col-md-7 m-0"/>
      </div>
      <div v-if="limit" class="footer">
        <a style="text-align: right">بالا ترین پیشنهاد خرید</a>
        <a style="text-align: left" class="link"
           @click="setMin(state.shopInfo.maxBuy)">{{ $toLocal(state.shopInfo.maxBuy) }}
          {{ $coins[tradeTo].persianName }}</a>
      </div>
      <div class="footer">
        <a>میزان کارمزد فعلی</a>
        <a>٪ {{ wage }}</a>
      </div>
      <button
          v-if="state.userInfo.authorized || state.userInfo.supervisor"
          @click.prevent="showmodal"
          class="btn" :title="readonly"
          :disabled="readonly"
      >
        خرید {{ $coins[tradeFrom].persianName }}
      </button>
      <button
          @click.prevent="$router.push({name:'Profile'})"
          class="btn"
          v-else-if="state.userInfo.pending"
      >
        حساب تایید نشده
      </button>
      <button
          @click.prevent="$router.push('/profile')"
          class="btn"
          v-else
      >
        تکمیل حساب
      </button>
    </form>
    <shop-modal v-if="show"
                @close="show = false"
                @submit="$emit('submit')"
                :unitPrice="unitPrice" :totalPrice="totalPrice"
                :amount="amount" type="SELL" :date="eDate" :stop-price="stopPrice"
                :limit-price="limitPrice"
                :limit="limit" :stop-limit="stopLimit" :oco="oco"
    />
  </div>
</template>

<script>
import '@/style/dateTimePicker.scss'
import ShopModal from "@/components/Trade/ShopModal";
import OverLoading from "@/components/over-loading";
import {tradeTo, tradeFrom, precision, Loop} from "@/lib/reuseableFunction";

export default {
  name: "SellCrypto",
  props: ['cash', 'wage', 'limit', 'stopLimit', 'oco', 'id', 'role'],
  components: {ShopModal, OverLoading},
  data() {
    return {
      eDate: '',
      percent: '',
      unitPrice: '',
      amount: '',
      totalPrice: '',
      danger: false,
      danger1: false,
      danger2: false,
      show: false,
      checked: '',
      calculating: false,
      temp: 0,
      loop: undefined,
    }
  },
  watch: {
    'state.sellShop'() {
      this.setShop()
    },
  },
  computed: {
    tradeTo, tradeFrom, precision,
    now() {
      return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
    },
    readonly() {
      let error = ''
      if (this.limit && !this.$S2N(this.unitPrice)) {
        error = 'قیمت واحد اجباریست'
      } else if (this.stopLimit && !this.$S2N(this.stopPrice)) {
        error = 'قیمت توقف اجباریست'
      } else if (this.oco && !this.$S2N(this.limitPrice)) {
        error = 'حد قیمت اجباریست'
      } else if (!this.$S2N(this.amount)) {
        error = 'مقدار ارز اجباریست'
      } else if (!this.$S2N(this.totalPrice)) {
        error = 'قیمت نهایی کمتر از حد مجاز است'
      } else if (this.$S2N(this.amount) < this.min) {
        error = 'مقدار ارز کمتر از حد مجاز است'
      } else if (this.$S2N(this.amount) > this.max) {
        error = 'مقدار ارز بیشتر از حد مجاز است'
      } else if (this.percent > 1 && !this.state.userInfo.supervisor) {
        error = 'موجودی کافی نیست'
      } else if (this.load) {
        error = 'لطفا صبر کنید...'
      } else if (!this.limit && this.state.userInfo.supervisor) {
        error = 'بازارگردان قادر به انجام سفارش بازار نیست'
      }
      return error
    },
    load() {
      return this.calculating
          || this.calculating2
    },
    min() {
      return this.role?.minAmount
    },
    max() {
      return this.role?.maxAmount
    },
    range() {
      return 'Min : ' + this.$toLocal(this.min) + ' ' + this.$coins[this.tradeFrom].code
          + '\n' + 'Max : ' + this.$toLocal(this.max) + ' ' + this.$coins[this.tradeFrom].code
    },
  },
  methods: {
    setShop() {
      this.amount = this.$toLocal(this.state.sellAmount, this.decimal)
      this.unitPrice = this.$toLocal(this.state.sellPrice, this.precision)
      this.calculate()
    },
    async putcash(p) {
      this.percent = p || this.percent
      this.amount = this.$toLocal(this.percent * this.cash, this.precision)
      const b = this.percent
      const temp = ++this.temp
      await this.$sleep(500)

      if (this.cash && b === this.percent && temp === this.temp)
        this.calculate()
    },
    setMin(e) {
      if (this.limit) {
        this.unitPrice = this.$toLocal(e)
        this.danger = false
        this.calculate()
      }
    },
    localCalculate() {
      const up = this.$S2N(this.unitPrice, this.precision)
      const a = this.$S2N(this.amount, this.decimal)
      this.totalPrice = this.$toLocal(up * a, this.tradeTo === 'TOMAN' ? 0 : 2)
      this.percent = this.$S2N(this.amount) / this.cash
    },
    async serverCalculate() {
      this.loop?.stop()
      this.calculating = true
      const a = this.amount
      await this.$sleep(500)
      if (a === this.amount) {
        this.loop ||= new Loop(this.getPriceFromServer, this.state.time, 'getPriceBuy')
        this.loop.start()
      }
    },
    async getPriceFromServer() {
      let amount = this.$S2N(this.amount);
      const [res,] = await this.$http.get('/orders/market-buy-sell-whole', {
        params: {
          amount: amount,
          marketType: this.tradeFrom + '_' + this.tradeTo,
          orderType: 'MARKET_BUY'
        }
      })
      if (res) {
        let price = res.baseDTO.wholePrice;
        this.unitPrice = this.$toLocal(price / amount, this.precision);
        this.totalPrice = this.$toLocal(price, this.$decimal[this.tradeTo]);
      }
    },
    calculate() {
      if (this.limit && this.unitPrice && this.amount) {
        this.localCalculate()
      } else if (!this.limit && this.$S2N(this.amount)) {
        this.serverCalculate()
      }
    },
    showmodal() {
      if (this.readonly) return
      if (!(!this.limit || this.$S2N(this.unitPrice))) {
        this.danger = true
        this.danger1 = false
        this.danger2 = false
      } else if (!this.$S2N(this.amount)) {
        this.danger1 = true
        this.danger2 = false
        this.danger = false
      } else if (this.percent > 1 && !this.state.userInfo.supervisor) {
        this.danger2 = true
        this.danger1 = false
        this.danger = false
      } else {
        this.show = true
        this.danger = false
        this.danger1 = false
        this.danger2 = false
      }
    }
  },
  beforeDestroy() {
    this.loop?.stop()
  }
}
</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
  height: 100%;
}

form {
  width: 100%;
  /*min-height: 250px;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

form div:not(.btn) {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 0;
}

label {
  text-align: right;
}

label a {
  font-size: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/*input[type=number] {*/
/*    -moz-appearance: textfield;*/
/*}*/

input[type=text] {
  border: solid rgba(0, 0, 0, 0.2) 0.5px;
  color: var(--font-color);
  height: 25px;
  padding: 0 10px !important;
  text-align: left;
  border-radius: 5px;
}

input[type=text]:hover {
  border: solid rgba(0, 0, 0, 0.4) 0.5px !important;
}

input[type=text]:focus {
  border: solid rgba(0, 0, 0, 0.7) 0.5px !important;
}

input[type=text]:read-only {
  background-color: rgba(0, 0, 0, 0.1);
  border: none !important;
}

.dark input[type=text] {
  border: solid rgba(255, 255, 255, 0.4) 0.5px;
  color: white;
}

.dark input[type=text]:hover {
  border: solid rgba(255, 255, 255, 0.6) 0.5px !important;
}

.dark input[type=text]:focus {
  border: solid rgba(255, 255, 255, 0.8) 0.5px !important;
}

.dark input[type=text]:read-only {
  background-color: rgba(255, 255, 255, 0.1);
}

#slider {
  margin-top: 6px;
  margin-bottom: 6px;
}

.slider-back {
  height: 2px;
  width: 99%;
  background-color: #d8d8d8;
  border-radius: 5px;
  display: inline-block;
  position: absolute;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #d8d8d8;
  border-radius: 50%;
  border: solid 1px white;
  display: inline-block;
  position: absolute;
  cursor: pointer;
  background-image: none;
  margin: 0;
}

.dot:hover {
  background-color: #a7a7a7;
}

.dot1 {
  left: 0;
}

.dot2 {
  left: 25%;
  transform: translateX(-24%);
}

.dot3 {
  left: 50%;
  transform: translateX(-50%);
}

.dot4 {
  left: 75%;
  transform: translateX(-74%);
}

.dot5 {
  left: 100%;
  transform: translateX(-100%);
}

.percent {
  font-size: 10px;
  position: absolute;
  top: 9px;
  cursor: pointer;
}

.percent:hover + .dot {
  background-color: #a7a7a7;
}

.percent:first-of-type {
  left: 0;
}

.percent:nth-of-type(2) {
  left: 25%;
  transform: translateX(-30%);
}

.percent:nth-of-type(3) {
  left: 50%;
  transform: translateX(-50%);
}

.percent:nth-of-type(4) {
  left: 75%;
  transform: translateX(-65%);
}

.percent:nth-of-type(5) {
  left: 100%;
  transform: translateX(-90%);
}


.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 0;
  background: none !important;
  outline: none;
  border: none !important;
  -webkit-transition: .2s;
  transition: 0.2s;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 1;
  cursor: pointer;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  /*background: var(--main-orange);*/
  background: transparent;
  /*border: solid 1px var(--main-orange);*/
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  /*background: var(--main-orange);*/
  background: transparent;
  /*border: solid 1px var(--main-orange);*/
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
}

/*.slider::-moz-range-thumb:active,*/
/*.slider::-moz-range-thumb:hover {*/
/*    transform: scale(2);*/
/*    !*background-color: #fff;*!*/
/*}*/

.rangenumber {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  transform: translateY(-50%);
  background: var(--main-orange);
  border: solid 1px var(--main-orange);
  font-size: 10px;
  /*display: block;*/
  position: absolute;
  /*transition: 0.2s;*/
  /*z-index: 1;*/
  transition: height 0.2s;

  & a {
    display: none;
  }
}

#slider:hover .rangenumber {
  width: 22px;
  height: 22px;

  & a {
    display: block;
  }
}

.slider::-webkit-slider-thumb:hover,
.slider::-webkit-slider-thumb:active {
  width: 22px;
  height: 22px;
}

.slider::-moz-range-thumb:hover,
.slider::-moz-range-thumb:hover {
  width: 22px;
  height: 22px;
}

/*.slider::-moz-range-thumb:active #percent{*/
/*    display: flex !important;*/
/*}*/

.link {
  text-decoration: underline;
}

.footer {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 10px;
}

.btn {
  font-size: 12px;
  background-color: var(--main-red);
  height: 30px;
  width: 100%;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-top: 5px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.25s;
    width: 100%;
    transform: scaleX(0);
    background-color: white;
    opacity: 0.2;
    height: 100%;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
    }
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      &:after {
        transform: scaleX(0);
      }
    }
  }
}

.slider::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
}

.dark a {
  color: white;
}

.dang {
  border: 1px var(--main-red) solid !important;
}

</style>
<template>
    <div class="box2">
        <table>
            <tr class="table-header">
                <th>بازار</th>
                <th class="mobilehidden">مقدار</th>
                <th class="mobilehidden">قیمت واحد</th>
                <th class="mobilehidden">کارمزد</th>
                <th>قیمت کل</th>
                <th>دریافتی شما</th>
                <th>زمان</th>
            </tr>
            <tr v-for="(tableContent,index) in tableContents" :key="index" v-show="index<16" class="table-row">
                <td>
                    {{$coins[$marketType2relatedCoin(tableContent.marketType)].persianName}} -
                    {{$coins[tableContent.marketType.split('_')[2] || tableContent.marketType.split('_')[1]].persianName}}
                </td>
                <td class="mobilehidden">
                    {{$coins[$M2C(tableContent.marketType)].code}}
                    {{$toLocal(tableContent.amount)}}
                </td>
                <td class="mobilehidden">
                    {{tableContent.unitPrice}}
                    {{$coins[$M2C(tableContent.marketType)].code}}
                </td>
                <td class="mobilehidden">
                    % {{tableContent.wagePercent}}
                </td>
                <td>
                    {{$toLocal(tableContent.amount * tableContent.unitPrice)}}
                    {{$coins[$M2S(tableContent.marketType)].code}}
                </td>
                <td>
                    {{$coins[$M2C(tableContent.marketType)].code}}
                    {{$toLocal(tableContent.receivedAmount)}}
                </td>
                <td class="mobilehidden">{{$G2J(tableContent.createdAtDateTime,'faDateTime')}}</td>
                <td class="mobileshow">{{$G2J(tableContent.createdAtDateTime,'faDate')}}</td>
            </tr>
        </table>
        <div class="empty" v-if="!tableContents.length">
            <img src="../../assets/icon/empty.png">
            <p>تاکنون معامله خرید انجام نداده‌اید.</p>
        </div>
        <pagination :key="lastPage" v-if="lastPage>1" style="margin-top: 30px" :n="lastPage" :max="3" :page="id"/>
    </div>
</template>

<script>
    import Pagination from "../Tools/Pagination";

    export default {
        name: "BuyTable",
        props: ['id'],
        components: {Pagination},
        data() {
            return {
                buy: false,
                sell: false,
                login: true,
                tableContents: [],
                lastPage: 1,
            }
        },
        watch: {
            '$route.params.id'() {
                this.check()
            }
        },
        methods: {
            async check() {
                this.state.loading = true
                const res = await this.$axios.get('/trades', {
                    params: {
                        size: 10,
                        page: this.id,
                        type: 'BUY'
                    }
                })
                this.lastPage = res.totalPages
                // this.tableContents = [...this.tableContents, ...response.data.content]
                this.tableContents = res.content
            },
        },
        mounted() {
            this.check()
        }

    }
</script>

<style scoped>

    .mobilehidden {
        display: table-cell;
    }

    .mobileshow {
        display: none !important;
    }

    @media (max-width: 800px) {
        .mobilehidden {
            display: none;
        }

        .mobileshow {
            display: table-cell !important;
        }
    }

    @media (max-width: 445px) {
        .table-row td {
            font-size: 12px;
        }

    }

    .box2 {
        font-size: 16px;
        border: none;
        background-color: white;
        width: 100%;
        margin-bottom: 30px;
        color: var(--font-color);
    }

    table {
        background-color: white;
        width: 100%;
        /*min-width: 768px;*/
        /*height: 710px;*/
        border-collapse: collapse;
    }

    table div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 50%;
        top: 30%;
        transform: translate(50%, -50%);
    }

    table div img {
        margin: 55px;
    }

    table th {
        height: 30px;
        font-weight: 300;
        opacity: 0.8;
    }

    .table-header {
        top: 0;
        background-color: var(--dark-main);
        text-align: center;
        z-index: 1;
        color: white;
    }

    .table-header th:first-of-type {
        text-align: right;
        padding-right: 1.5%;
    }

    .table-header th:last-of-type {
        text-align: left;
        padding-left: 1.5%;
    }

    .table-row {
        position: relative;
        text-align: center;
        cursor: default;
    }

    .table-row:nth-of-type(odd) {
        height: 40px;
        background-color: var(--hover);
    }

    .table-row:nth-of-type(even) {
        height: 45px;
        background-color: white;
    }

    .table-row:nth-of-type(odd) td:first-of-type {
        height: 40px;
    }

    .table-row:nth-of-type(even) td:first-of-type {
        height: 45px;
    }


    .table-row td:first-of-type {
        padding-right: 8%;
        text-align: right;
        direction: rtl;
        display: flex;
        flex-direction: row;
        align-items: center;
    }


    .table-row td:nth-of-type(7),
    .table-row td:nth-of-type(8) {
        padding-left: 1.5%;
        text-align: left;
        /*direction: ltr;*/
        /*display: flex;*/
        /*flex-direction: row;*/
        /*align-items: center;*/
        /*margin-left: 10%;*/

    }

    .table-row td:first-of-type a {
        margin-right: 5px;
    }

    .empty {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 50px;
    }


</style>
<template>
  <div class="calculator">
    <button class="normal-btn"
            :class="toman? 'rotate' : 'no-rotate'"
            @click="exchange">
      {{ toman ? 'تومان' : 'تتر' }}
    </button>
    <input @input="calamount" type="text" v-model="price" id="price" placeholder="قیمت ارز">
    <input @input="calprice" type="text" v-model="amount" id="amount" placeholder="مقدار ارز">
    <div class="dropdown">
      {{ $coins[calcname].persianName }}
      <drop-down class="drop" @select="setcoin" :contents="calcoin"/>
    </div>
  </div>
</template>

<script>
import DropDown from "components/Home/dropDown";

export default {
  name: "calculator",
  components: {DropDown},
  props: ['toman', 'calcoin'],
  data() {
    return {
      price: '',
      amount: '',
      calcname: '',
      calcunit: '',
    }
  },
  watch: {
    calcoin() {
      this.setCalCoin()
    }
  },
  methods: {
    setCalCoin() {
      this.setcoin(this.calcname ? this.calcoin.find((e) => this.$M2C(e.marketType) === this.calcname) : this.calcoin[0])
      this.calprice()
    },
    calamount() {
      this.price = this.$toLocal(this.price, this.toman ? 0 : this.$prcesion[this.calcname])
      this.amount = this.$S2N(this.price) / this.calcunit
      this.amount = this.$toLocal(this.amount, this.$decimal[this.calcname])
    },
    calprice() {
      this.amount = this.$toLocal(this.amount, this.$decimal[this.calcname])
      this.price = this.$S2N(this.amount) * this.calcunit
      this.price = this.$toLocal(this.price, this.toman ? 0 : this.$prcesion[this.calcname])
    },
    setcoin(e) {
      this.calcname = this.$M2C(e.marketType)
      this.calcunit = e.lastPrice || 0
      this.calprice()
    },
    exchange() {
      this.$emit('update:toman', !this.toman)
    }
  }
}
</script>

<style lang="scss" scoped>

.calculator {
  display: flex;
  width: calc(100% - 20px);
  max-width: 610px;
  height: 45px;
}

.calculator button {
  height: 100%;
  color: var(--font-color);
  width: 25%;
  font-size: 16px;
  border-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  margin: 0;
}

.calculator button::before {
  position: absolute;
  right: 0;
  content: '';
  background-image: url("../../assets/Tradex/swap_vert-24px.svg");
  background-position: 10% center;
  background-repeat: no-repeat;
  background-size: 25px;
  height: 25px;
  width: 100%;
  transition: 0.5s transform;
  transform-origin: center;
}

.no-rotate::before {
  transform: rotateX(0deg);
}

.rotate::before {
  transform: rotateX(180deg);
}

.calculator input {
  background-color: white;
  border: 2px solid var(--main-orange);
  text-align: center;
  width: 25%;
}

.calculator .dropdown {
  background-color: var(--main-orange);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: IRANSans-Bold, sans-serif;
}

.dropdown {
  position: relative;
}

.drop {
  display: none !important;
  top: 30px;
  width: 100%;
}

.dropdown:hover .drop {
  display: block !important;
}

</style>
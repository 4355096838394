<template>
    <div class="touch-menu" style="padding-bottom: 10px;overflow: scroll;">
        <div class="touch-menu-header">
            <svg width="20px" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 443.52 443.52" xml:space="preserve" fill="#ffffff70">
                        <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                        c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                        L143.492,221.863z"/>
                    </svg>
            <!--            <account-deactive @click="$emit(account)" v-show="!account" :route="currentroute"></account-deactive>-->
            <!-- <button @click="$router.push('/sign-in')" v-show="!account" class="btn">پنل کاربری</button> -->
            <div v-if="state.token" @click="$emit('account')" class="account-active">
                <p style="margin:0;overflow: hidden;width: calc(100% - 29px);text-overflow: ellipsis" :dir="state.userInfo.authenticationType === 'UNAUTHORIZED'?'ltr':'rtl'">
                    {{state.userInfo.authenticationType === 'UNAUTHORIZED' ? state.userInfo.email :
                    state.userInfo.firstName}}
                </p>
                <svg style="margin: 0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff70"
                     width="29px" height="29">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"/>
                </svg>
            </div>
        </div>
        <a :class="$route.name==='Home' ? 'selected' : ''"
           @click="$router.push('/home')" class="col-auto" id="home">خانه</a>
        <a :class="$route.name==='Trades' ? 'selected' : ''"
           @click="$router.push('/trade')" class="col-auto" id="trade">بازار ها</a>
        <a :class="['Simple','Trade'].includes($route.name) ? 'selected' : ''"
           @click.prevent="$emit('trading')">معامله
        </a>
        <a :class="$route.name==='Wage' ? 'selected' : ''"
           @click="$router.push('/wage')" class="col-auto" id="wage">کارمزد</a>
        <a href="/blog" class="col-auto" id="blog">بلاگ</a>
        <a :class="['HowToUse','Policy','FAQ'].includes($route.name) ? 'selected' : ''"
           @click.prevent="$emit('help')">راهنما
        </a>
        <a @click="$router.push('/sign-in')" v-if="!state.token" :class="$route.path=='/sign-in' ? 'selected' : ''"
           class="col-auto" style="text-decoration:none">ورود</a>
        <a @click="$router.push('/sign-up')" v-if="!state.token" :class="$route.path=='/sign-up' ? 'selected' : ''"
           class="col-auto" style="text-decoration:none">ثبت نام</a>
        <a>
            <p v-if="$route.name === 'Trade'" @click="theme('dark')">نمای تاریک</p>
            <p v-if="$route.name === 'Trade'" @click="theme('light')">نمای روشن</p>
        </a>
    </div>
</template>

<script>

    export default {
        name: "touch-menu",
        props: ['currentroute', 'account'],
        methods: {
            theme(e) {
                localStorage.theme = e;
                this.state.theme = localStorage.theme
            }
        }
    }
</script>

<style scoped>

    .touch-menu {
        font-size: 18px !important;
        position: absolute;
        left: 0;
        top: 0;
        background-color: var(--light-background);
        display: flex;
        width: 60%;
        max-width: 250px;
        flex-direction: column;
        transition: 0.25s;
        z-index: 5;
        height: 100vh;
        padding: 0 10px;
        margin-left: -300px;
    }

    .touch-menu a {
        margin: 20px 0;
        text-align: right;
        opacity: 0.8;
        padding-right: 10px;
        color: white;
    }

    .touch-menu-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 54px;
        align-items: center;
        padding: 0 10px;
    }

    .selected {
        color: var(--font-hover) !important;
        /*height: 30px;*/
        border-bottom: var(--font-hover) solid 2px;
        cursor: default;
    }

    .selected a {
        color: var(--font-hover);
    }

    .account-active {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #ffffff80;
        width: calc(100% - 20px);
    }

    .btn {
        width: 80px;
        height: 29px;
        border-radius: 5px;
        background-color: var(--font-hover);
        border: 2px solid var(--font-hover);
        color: white;
        font-size: 12px;
        cursor: pointer;
        transition: 0.2s;
    }

    .btn:hover {
        filter: brightness(120%);
    }

    a:last-of-type p {
        margin: 0;
    }

    a:last-of-type p:last-of-type {
        display: none;
    }

    .dark a:last-of-type p:first-of-type {
        display: none;
    }

    .dark a:last-of-type p:last-of-type {
        display: block;
    }
</style>
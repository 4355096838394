<template>
    <div class="box-sub">

        <set-google-auth-modal v-if="googleModal" @close="googleModal=false;getTwoStepAuth();" @submit="submitted($event)"
                               :qr-code="qrCode" :qr-link="qrLink"/>
        <set-sms-auth-modal v-if="smsModal" @close="smsModal=false;getTwoStepAuth();" @submit="submitted($event)" :mobile="mobile"/>
        <set-email-auth-modal v-if="emailModal" @close="emailModal=false;getTwoStepAuth();" @submit="submitted($event)"/>

        <set-auth-completed-modal v-if="completedModal" :text="completedText" @close="completedModal=false;getTwoStepAuth();"/>

        <a style="font-size: 14px;font-family: IRANSans-Bold">شناسایی دو عاملی</a>
        <p>برای امنیت بیشتر حساب کاربری, فعالسازی شناسایی دو عاملی توصیه میشود.</p>
        <form @change="showModal" class="row no-gutters">
            <a class="col-12 col-md-6">تایید هویت دو مرحله ای جهت ورود به حساب کاربری و درخواست برداشت</a>
            <div class="col-12 col-md-6 row no-gutters">
                <label class="col-12 col-sm-auto" for="twostepemail">
                    <input type="radio" id="twostepemail" v-model="twoStep" value="EMAIL">
                    <svg fill="var(--main-orange)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22px" height="22px"
                         viewBox="0 0 510 510" style="enable-background:new 0 0 510 510;" xml:space="preserve">
                        <g id="radio-button-on" v-show="state.userInfo.towStepAuthenticationType==='EMAIL'">
                            <path d="M255,127.5c-71.4,0-127.5,56.1-127.5,127.5c0,71.4,56.1,127.5,127.5,127.5c71.4,0,127.5-56.1,127.5-127.5
                            C382.5,183.6,326.4,127.5,255,127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z
                            M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                        <g id="radio-button-off" v-show="state.userInfo.towStepAuthenticationType!=='EMAIL'">
                            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459
                            c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                    </svg>
                    ایمیل
                </label>
                <label class="col-12 col-sm-auto" for="twostepesms">
                    <input type="radio" id="twostepesms" v-model="twoStep" value="SMS">
                    <svg fill="var(--main-orange)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22px" height="22px"
                         viewBox="0 0 510 510" style="enable-background:new 0 0 510 510;" xml:space="preserve">
                        <g id="radio-button-on" v-show="state.userInfo.towStepAuthenticationType==='SMS'">
                            <path d="M255,127.5c-71.4,0-127.5,56.1-127.5,127.5c0,71.4,56.1,127.5,127.5,127.5c71.4,0,127.5-56.1,127.5-127.5
                            C382.5,183.6,326.4,127.5,255,127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z
                            M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                        <g id="radio-button-off" v-show="state.userInfo.towStepAuthenticationType!=='SMS'">
                            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459
                            c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                    </svg>
                    پیامک
                </label>
                <label class="col-12 col-sm-auto" for="googleauth">
                    <input type="radio" id="googleauth" v-model="twoStep" value="GOOGLE_AUTHENTICATOR">
                    <svg fill="var(--main-orange)" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="22px" height="22px"
                         viewBox="0 0 510 510" style="enable-background:new 0 0 510 510;" xml:space="preserve">
                        <g id="radio-button-on" v-show="state.userInfo.towStepAuthenticationType==='GOOGLE_AUTHENTICATOR'">
                            <path d="M255,127.5c-71.4,0-127.5,56.1-127.5,127.5c0,71.4,56.1,127.5,127.5,127.5c71.4,0,127.5-56.1,127.5-127.5
                            C382.5,183.6,326.4,127.5,255,127.5z M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z
                            M255,459c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                        <g id="radio-button-off" v-show="state.userInfo.towStepAuthenticationType!=='GOOGLE_AUTHENTICATOR'">
                            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459
                            c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"/>
                        </g>
                    </svg>
                    شناسایی دوعاملی گوگل
                </label>
            </div>
        </form>
    </div>
</template>

<script>
    import SetGoogleAuthModal from "@/components/UserAccount/setGoogleAuthModal";
    import SetSmsAuthModal from "@/components/UserAccount/setSmsAuthModal";
    import SetEmailAuthModal from "@/components/UserAccount/setEmailAuthModal";
    import SetAuthCompletedModal from "@/components/UserAccount/setAuthCompletedModal";
    export default {
        name: "SetTwoStepAuth",
        components: {SetAuthCompletedModal, SetEmailAuthModal, SetSmsAuthModal, SetGoogleAuthModal},
        data() {
            return {
                twoStep: 'EMAIL',
                googleModal: false,
                emailModal: false,
                smsModal: false,
                qrCode: '',
                mobile: '',
                completedModal: false,
                completedText: '',
                qrLink:'',
            }
        },
        methods: {
            getTwoStepAuth() {
                this.twoStep = this.state.userInfo.towStepAuthenticationType
            },
            async showModal() {
                this.state.loading = true
                const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`).catch(()=>{ this.getTwoStepAuth()})
                if(res){
                    this.mobile = res.baseDTO.starTwoStepAuthentication
                    if (res.message === 'Verification Code Sent') {
                        if (this.twoStep === 'EMAIL') {
                            this.emailModal = true
                        } else if (this.twoStep === 'SMS') {
                            this.smsModal = true
                        } else if (this.twoStep === 'GOOGLE_AUTHENTICATOR') {
                            this.qrCode = res.baseDTO.file;
                            this.qrLink = res.baseDTO.qrCodeLink.match(/secret=(.*?)&/)[1]
                            this.googleModal = true
                        }
                    }
                }
            },
            async submitted(e){
                this.completedText=e;
                this.completedModal=true
                await this.$getuserInfo()
            }
        },
        mounted() {
            this.getTwoStepAuth()
        }
    }
</script>

<style scoped>

    .box-sub {
        font-family: IRANSans, sans-serif;
        position: relative;
        display: block;
        background-color: white;
        text-align: right;
        border-bottom: #00000015 solid 1px;
        padding: 10px 15px 25px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 16px;
        color: var(--font-color);
    }

    p {
        margin-top: 22px;
    }

    form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        text-align: justify;
    }

    form div {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    form label {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 10px 0;
    }

    input {
        display: none;
        cursor: pointer;
    }

    label svg{
        margin-left: 10px;
    }
</style>
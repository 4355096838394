<template>
    <div class="main-box">
        <h1>قوانین و مقررات صرافی ویستا</h1>
        <froalaView v-model="privacyPolicy"/>
    </div>
</template>

<script>

    export default {
        name: "Policy",
        data:()=>({
            privacyPolicy:'',
            loading:true,
        }),
        async mounted() {
            this.state.loading = true
            const res = await this.$axios.get('/exchange-info')
            this.privacyPolicy = res.baseDTO.privacyPolicy
        }
    }
</script>

<style scoped>

    .main-box {
        display: flex;
        flex-direction: column;
        background-color: white;
        direction: rtl;
        text-align: right;
        box-sizing: border-box;
        width: 100%;
        max-width: 1170px;
        padding: 10px;
        font-size: 17px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-content: center;
        width: 100%;
        margin-bottom: 50px;
        padding-right: 10px;
    }

    h1 {
        font-size: 30px;
        margin-bottom:15px;
        color: var(--font-color);
        text-align: center;
        margin-top: 80px;
    }

    h2 {
        font-family: IRANSans-Medium,sans-serif;
        font-weight: 500;
        font-size: 21px;
        margin-top: 15px;
        margin-bottom: 15px;
        color: var(--font-color);
    }

    p {
        margin: 15px 0;
        text-align: justify;
        color: var(--font-color);
    }

    ul {
        margin: 15px 0 0 0;
        list-style-position: inside;
    }

    ul li {
        margin-bottom: 15px;
        color: var(--main-orange);
        text-align: justify;
    }

    ul li:last-of-type {
        margin-bottom: 0;
        color: var(--main-orange);
    }
</style>
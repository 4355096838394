<template>
    <transition name="modal-fade">
        <div @click="close" id="modal" class="modal-backdrop" @click.self="close">
            <div class="modal"
                 role="dialog"
                 aria-labelledby="WithdrawModal"
                 aria-describedby="WithdrawModal">
                <section class="modal-body" style="direction: rtl">
                    <img width="125px" src="../../assets/icon/completed.svg" alt="">
                    <div class="row justify-content-center" style="font-size: 16px">
                        {{text}}
                    </div>
                </section>
                <footer class="modal-btn">
                    <button
                            type="button"
                            class="normal-btn"
                            @click="$emit('close')">
                        تایید
                    </button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "setAuthCompletedModal",
        props:['text'],
        data() {
            return {
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
        },
    }
</script>

<style scoped>

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(19, 22, 27, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 55;
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
        color: var(--font-color);
        flex-direction: column;
    }
    .dark{
        background-color: rgba(255 , 255 , 255 , 0.3) !important;
    }

    .modal {
        position: relative;
        background: var(--dark-main);
        backdrop-filter: blur(1px);
        -webkit-backdrop-filter: blur(1px);
        border-radius: 5px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        padding: 10px 10px;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        /*margin-top: 20px;*/
        position: relative;
        background-color: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding: 50px 30px 30px;
    }

    .modal-body div {
        margin: 20px 0;
    }

    .modal-fade-enter,
    .modal-fade-leave-active {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .25s ease;
    }
    .modal-btn {
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: white;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .normal-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: white;
        max-width: 200px;
        margin: 0;
        border-radius: 5px;
        height: 40px;
        padding: 0;
    }


</style>

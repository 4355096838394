<template>
    <div class="orders">
        <div class="tab">
            <div :class="[!active ? 'tab-content' : 'tab-content-active']"
                 @click.prevent="[active=true]">
                <a style="font-weight: 600">سفارشات فعال من ({{activeLength}})</a>
            </div>
            <div :class="[active ? 'tab-content' : 'tab-content-active']"
                 @click.prevent="[active=false]">
                <a style="font-weight: 600">تاریخچه سفارشات</a>
            </div>
        </div>
        <active-order v-if="active" :activeLength.sync="activeLength" class="box" />
        <last-order v-else class="box"/>
    </div>
</template>

<script>
    import ActiveOrder from "@/components/Trade/ActiveOrder";
    import LastOrder from "@/components/Trade/LastOrder";

    export default {
        name: "Orders",
        components: {LastOrder, ActiveOrder},
        data() {
            return {
                active: true,
                activeLength:0
            }
        },
        methods: {}
    }
</script>

<style scoped>
    .orders {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .tab {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 30px;
        /*border-bottom: #00000010 1px solid;*/
        font-size: 12px;
        align-items: center;
        background-color: white;
        text-align: right;
        width: 100%;
    }
    .dark .tab{
        background-color: var(--dark-main);
    }

    .tab-content {
        width: 140px;
        height: 30px;
        opacity: 0.5;
        text-align: center;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tab-content-active {
        font-weight: 800;
        width: 140px;
        height: 30px;
        opacity: 1;
        text-align: center;
        /*background-color: var(--dark-background);*/
        border-bottom: 2px solid var(--main-orange);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: default;
    }
    .tab a{
        color: var(--font-color);
    }
    .dark .tab a{
        color: white;
    }

</style>
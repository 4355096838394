<template>
  <div class="box2">
    <over-loading width="70" v-if="loading"/>
    <table>
      <tr class="table-header">
        <th>نام ارز</th>
        <th>آخرین قیمت</th>
        <th>تغییرات 24 ساعت اخیر</th>
        <th class="mobilehidden">حجم 24 ساعت اخیر</th>
        <th class="mobilehidden">نمودار هفتگی</th>
        <!--                <th></th>-->
      </tr>
      <tr v-for="(tableContent,index) in tableContents.slice(0,max)" :key="index" class="table-row"
          :href="'/trade/'+(active?'TOMAN':'TETHER')+'/'+$M2C(tableContent.marketType)"
          @click.prevent="$router.push('/trade/'+(active?'TOMAN':'TETHER')+'/'+$M2C(tableContent.marketType))">
        <td>
          <img width="25px"
               :src="'/coins/' + $M2C(tableContent.marketType) + '.png' "
               alt="crypto">
          <a>{{ $coins[$M2C(tableContent.marketType)].persianName }}</a>
        </td>
        <td style="direction: rtl"
            :style="{color:[(tableContent.last24HourChange>0) ? 'var(--main-green)' : tableContent.last24HourChange<0? 'var(--main-red)' : '']}">
          {{ $toLocal(tableContent.lastPrice,active? 0 : $prcesion[$M2C(tableContent.marketType)]) + ' ' + (active ? 'تومان' : 'تتر') }}
        </td>
        <td :style="{color:[(tableContent.last24HourChange>0) ? 'var(--main-green)' : tableContent.last24HourChange<0? 'var(--main-red)' : '']}">
          {{ Number(tableContent.last24HourChange).toFixed(2) }} %
        </td>
        <td class="mobilehidden">
          {{ $toLocal(tableContent.last24HourVolume,0) }}
        </td>
        <td class="mobilehidden">
          <trend
              :data="tableContent.lastWeekData || [0,0]"
              :gradient="['var(--main-orange)']"
              :height="50"
              :width="150"
              style="stroke-width: 2px;margin-bottom: -10px"
              auto-draw>
          </trend>
        </td>
        <!--                <td><button @click="goto(index)">معامله</button></td>-->
      </tr>
      <tr v-if="!tableContents.length">
        <td style="text-align: center;direction: rtl" colspan="5">اطلاعاتی جهت نمایش وجود ندارد.</td>
      </tr>
    </table>
  </div>
</template>

<script>
import OverLoading from "@/components/over-loading";
import {Loop} from "@/lib/reuseableFunction";

export default {
  name: "CryptoRialChart",
  components: {OverLoading},
  props: {
    max: {
      default: 11
    },
    active: {
      default: true
    }
  },
  data() {
    return {
      loop:undefined,
      tableContents: [],
      TomanCoin: 'market_type=BITCOIN_TOMAN' +
          '&market_type=BITCOIN_CASH_TOMAN' +
          '&market_type=ETHEREUM_TOMAN' +
          '&market_type=CLASSIC_ETHEREUM_TOMAN' +
          '&market_type=LITE_COIN_TOMAN' +
          '&market_type=BINANCE_COIN_BSC_TOMAN' +
          '&market_type=EOS_TOMAN' +
          '&market_type=STELLAR_TOMAN' +
          '&market_type=TRON_TOMAN' +
          '&market_type=DASH_TOMAN' +
          '&market_type=RIPPLE_TOMAN' +
          '&market_type=DOGE_COIN_TOMAN' +
          '&market_type=MATIC_TOMAN' +
          '&market_type=CELO_TOMAN' +
          '&market_type=TETHER_TOMAN',
      TetherCoin: 'market_type=BITCOIN_TETHER' +
          '&market_type=BITCOIN_CASH_TETHER' +
          '&market_type=ETHEREUM_TETHER' +
          '&market_type=CLASSIC_ETHEREUM_TETHER' +
          '&market_type=LITE_COIN_TETHER' +
          '&market_type=BINANCE_COIN_BSC_TETHER' +
          '&market_type=EOS_TETHER' +
          '&market_type=STELLAR_TETHER' +
          '&market_type=TRON_TETHER' +
          '&market_type=RIPPLE_TETHER' +
          '&market_type=DOGE_COIN_TETHER' +
          '&market_type=MATIC_TETHER' +
          '&market_type=CELO_TETHER' +
          '&market_type=DASH_TETHER',
      alive: true,
      loading: false
    }
  },
  watch: {
    'active'() {
      this.tab()
    }
  },
  methods: {
    async setData() {
      const [res,] = await this.$http.get('/trades/market-info?' + (this.active ? this.TomanCoin : this.TetherCoin), {
        params: {
          weeklyDataIncluded: true,
          // marketType: this.active ? this.TomanCoin : this.TetherCoin
        }
      })
      if (res) {
        const sortCoins = this.$walletSortCoins
        res.content.sort((a, b) => sortCoins.indexOf(this.$M2C(a.marketType)) - sortCoins.indexOf(this.$M2C(b.marketType)))
        this.tableContents = res.content;
        this.$emit('get-data', res.content)
      }
      this.loading = false
    },
    async beforeLoop() {
      this.loading = true
      this.loop = new Loop(this.setData, 5000, 'homeChart' + this.active ? 'TOMAN' : 'TETHER')
      if(this.state.loop){
        this.loop.start()
      }else{
        this.setData()
      }
    },
    async tab() {
      this.tableContents = []
      this.loading = true
      await this.setData()
    }
  },
  mounted() {
    this.beforeLoop()
  },
  beforeDestroy() {
    this.loop?.stop()
  }

}
</script>

<style scoped>


.mobileshow {
  display: none !important;
}

@media (max-width: 800px) {
  .mobilehidden {
    display: none;
  }

  .mobileshow {
    display: table-cell !important;
  }
}

.box2 {
  font-size: 16px;
  direction: ltr;
  border-radius: 5px;
  border: none;
  background-color: white;
  width: 100%;
  position: relative;
}

table {
  direction: rtl;
  background-color: white;
  width: 100%;
  /*min-width: 768px;*/
  border-collapse: collapse;
}

table th {
  height: 50px;
  font-weight: 300;
}

.table-header {
  top: 0;
  /*background-color: #00000005;*/
  text-align: center;
  z-index: 1;
  border-bottom: solid 2px #f2f4f7;
}

.table-header th:first-of-type {
  text-align: right;
  padding-right: 4%;
}

/*.table-header th:last-of-type {*/
/*    text-align: left;*/
/*    padding-left: 3%;*/
/*}*/

.table-row {
  position: relative;
  text-align: center;
  cursor: default;
  border-bottom: solid 2px #f2f4f7;
  max-height: 60px;
}

.table-row:last-of-type {
  border-bottom: none;
}

.table-row:hover {
  background-color: var(--main-hover);
  cursor: pointer;
}


.table-row td:first-of-type {
  padding-right: 10%;
  text-align: right;
  direction: rtl;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}


.table-row td:last-of-type {
  /*padding-right: 10%;*/
  text-align: center;
  /*display: flex;*/
  /*margin-left: 10%;*/

}

.table-row td:first-of-type a {
  margin-right: 5px;
}

table td {
  height: 60px;
  direction: ltr;
  display: table-cell;
}

/*button{*/
/*    width: 100%;*/
/*    max-width: 80px;*/
/*    min-width: 50px;*/
/*    height: 27px;*/
/*    border-radius: 5px;*/
/*    border: solid 1px var(--main-orange);*/
/*    color: var(--main-orange);*/
/*    font-size: 12px;*/
/*    font-weight: 600;*/
/*    cursor: pointer;*/
/*    transition: 0.2s;*/
/*    padding: 0;*/
/*}*/
/*button:hover{*/
/*    background-color: var(--main-orange);*/
/*    color: white;*/
/*}*/


</style>
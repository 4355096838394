<template>
    <div class="Auth-box">
        <div class="tab">
            <div :class="[$route.params.pathMatch==='in' ? 'tab-content-active' : 'tab-content']"
                 @click.prevent="$router.push('/sign-in')">
                <a>ورود</a>
            </div>
            <div :class="[$route.params.pathMatch==='up' ? 'tab-content-active' : 'tab-content']"
                 @click.prevent="$router.push('/sign-up')">
                <a>ثبت نام</a>
            </div>
            <div class="selector" :style="{right :[$route.params.pathMatch==='in'? '' : '50%']}"></div>
        </div>
        <div class="box1">
            <login v-show="$route.params.pathMatch ==='in'"/>
            <register v-show="$route.params.pathMatch ==='up'"/>
        </div>
    </div>
</template>

<script>
    import Login from "@/components/Auth/Login";
    import Register from "@/components/Auth/Register";

    export default {
        name: "LoginRegister",
        components: {Register, Login},
        props: ['pathMatch'],
        data() {
            return {
                reg: false,
                log: true
            }
        }
    }
</script>

<style scoped>
    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 470px;
        height: 100%;
    }

    .tab {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 60px;
        border-bottom: #00000010 1px solid;
        font-size: 18px;
        align-items: center;
        background-color: transparent;
        text-align: right;
        width: 100%;
        margin-top: 10px;

    }

    .tab-content {
        width: 50%;
        opacity: 0.5;
        text-align: center;
        cursor: pointer;
    }

    .tab-content-active {
        font-weight: 800;
        width: 50%;
        opacity: 0.8;
        text-align: center;
    }

    .box1 {
        flex-direction: column;
        margin: 10px 0;
        background-color: white;
        width: 100%;
    }

    .selector {
        position: absolute;
        background-color: var(--main-orange);
        width: 50%;
        height: 6px;
        border-radius: 5px;
        bottom: -3px;
        right: 0;
        transition: 0.5s;
    }

</style>
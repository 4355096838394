<template>
    <div :class="{dark : !(currentroute !== 'Trade' || state.theme === 'light')}">
        <div class="loading" v-if="state.loading">
            <img src="./assets/loading.svg" alt="">
        </div>
        <nav-bar v-if="!blog"/>
        <div :style="{backgroundColor: [trans.indexOf(currentroute)>-1? 'transparent' : [currentroute==='Trade'? [state.theme !== 'light'? '#000':'var(--main-gray)']:'white']],
        marginTop: [currentroute === 'Home'? 0 : [currentroute === 'Trade'? '50px':'']],padding:[currentroute === 'Home'? 0 : '']}"
             class="container1">
            <router-view :key="($route.params.tradeTo || $route.name) + ($route.params.tradeFrom || $route.name)"/>
        </div>
        <Footer v-if="havefooter.indexOf(currentroute)>-1"/>
        <mini-footer v-else-if="currentroute !== 'Trade'"/>
<!--        <div class="alert">
            سایت در مرحله تست می باشد
        </div>-->
    </div>
</template>

<script>
    import Footer from "@/components/Footer/Footer";
    import NavBar from "@/components/Navbar/NavBar";
    import MiniFooter from "@/components/Footer/miniFooter";

    export default {
        name: 'App',
        data() {
            return {
                currentroute: '',
                blog: '',
                sh: '',
                theme: '',
                blogs: ['BlogHome', 'PostIndex', 'PostShow'],
                trans: ['Auth', 'TwoStepGoogle', 'TwoStepGoogleAuth', 'TwoStepSms', 'RecoveryPass', 'ResetPass'],
                havefooter: ['Home', 'AboutUs', 'FAQ', 'HowToUse', 'Policy', 'Wage', 'Trades'],
                havemfooter: ['Messages', 'Profile', 'Setting', 'Invite', 'Wallet']
            }
        },
        components: {MiniFooter, NavBar, Footer},
        watch: {
            $route(to) {
                this.currentroute = to.name
                this.RouteName()
            }
        },
        mounted() {
        },
        created() {
            this.currentroute = this.$route.name;
            this.RouteName()
        },
        methods: {
            RouteName() {
                if (this.blogs.indexOf(this.currentroute) > -1) {
                    this.blog = true
                } else {
                    this.blog = false
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .container1 {
        background-color: var(--main-gray);
    }

    .loading {
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #00000080;
        backdrop-filter: blur(2px);
        z-index: 999;
    }

    .alert {
        position: fixed;
        left: 10px;
        bottom: 10px;
        z-index: 10;
        height: 50px;
        border-radius: 5px;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        color: #fff;
        padding-right: 45px;
        box-shadow: 0 0 14px red;
        cursor: pointer;

        &:before {
            position: absolute;
            height: 24px;
            width: 24px;
            content: '!';
            font-weight: bold;
            border-radius: 50%;
            border: 2px solid #fff;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    /*.dark{*/
    /*    background-color: var(--dark-main) !important;*/
    /*    color: white !important;*/
    /*}*/
</style>

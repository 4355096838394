<template>
  <div class="main-box">
    <h2>تاریخچه کیف پول ها</h2>
    <div class="tab">
      <div :class="[tab ==='deposit' ? 'tab-content-active' : 'tab-content']"
           @click.prevent="routing('deposit')">
        <a>واریز ها</a>
      </div>
      <div :class="[tab ==='withdraw' ? 'tab-content-active' : 'tab-content']"
           @click.prevent="routing('withdraw')">
        <a>برداشت ها</a>
      </div>
      <div class="selector" :style="{right :[tab==='deposit'? '' : [tab==='withdraw'? '50%': '']]}"></div>
    </div>
    <div class="box1">
      <deposit-table v-show="tab === 'withdraw'" type="with"/>
      <deposit-table v-show="tab === 'deposit'" type="dep"/>
    </div>
  </div>
</template>

<script>

import DepositTable from "./DepositTable";
// import WithdrawTable from "./WithdrawTable";

export default {
  name: "WalletHistory",
  components: {DepositTable},
  props: {
    tab: {
      default: 'deposit'
    },
    id: {
      default: 1
    }
  },
  data() {
    return {
      deposit: false,
      withdraw: true,
    }
  },
  methods: {
    routing(e) {
      this.$router.push({name: 'Wallet', params: {tab: e, id: 1}})
    }
  }
}
</script>

<style scoped>

.main-box {
  background-color: transparent;
  text-align: right;
  margin-top: 30px;
  padding: 0 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1170px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  font-size: 14px;
  color: var(--font-color);
}

h1 {
  font-size: 30px;
  margin-top: 10px;
  margin-bottom: 25px;
  margin-right: 10px;
}

.tab {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 60px;
  /*border-bottom: #00000010 1px solid;*/
  font-size: 18px;
  align-items: center;
  background-color: transparent;
  text-align: right;
  width: 100%;

}

.tab-content {
  width: 50%;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
}

.tab-content-active {
  font-weight: 800;
  width: 50%;
  text-align: center;
}

.selector {
  position: absolute;
  background-color: var(--main-orange);
  width: 50%;
  height: 6px;
  border-radius: 5px;
  bottom: -3px;
  right: 0;
  transition: 0.5s right;
}


.box1 {
  flex-direction: column;
  margin-top: 30px;
  background-color: white;
  width: 100%;
}
</style>
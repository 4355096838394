<template>
  <div class="navbar row no-gutters">
    <div class="menu">
      <div @click="$router.push('/home')" class="logo no-gutters"/>

      <div class="row m-0 menuimg">
        <img @click="nav=true" class="icon col-auto" src="../../assets/menu.svg" alt="menuicon">
      </div>

      <div class="sub-menu2 row">
        <a :class="[(currentroute==='Home') ? 'selected' : '']" @click="$router.push('/home')"
           class="col-auto">خانه</a>
        <a :class="[(currentroute==='Trades') ? 'selected' : '']" @click="$router.push('/trade')"
           class="col-auto">بازار ها</a>
        <a :class="[(['Simple','Trades'].includes(currentroute)) ? 'selected' : '']"
           class="dropdown col-auto" id="trading">معامله
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 494.148 494.148" width="8px" style="margin: 0 3px" xml:space="preserve">
            <path d="M405.284,201.188L130.804,13.28C118.128,4.596,105.356,0,94.74,0C74.216,0,61.52,16.472,61.52,44.044v406.124
                        c0,27.54,12.68,43.98,33.156,43.98c10.632,0,23.2-4.6,35.904-13.308l274.608-187.904c17.66-12.104,27.44-28.392,
                        27.44-45.884 C432.632,229.572,422.964,213.288,405.284,201.188z"/>
          </svg>
          <drop-content-hover class="drop" :contents="trading"/>
        </a>
        <a :class="[(currentroute==='Wage') ? 'selected' : '']" @click="$router.push('/wage')"
           class="col-auto">کارمزد</a>
        <a :class="[(['HowToUse','FAQ','AboutUs','Policy'].indexOf(currentroute)>-1) ? 'selected' : '']"
           class="dropdown col-auto" id="article">راهنما
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
               viewBox="0 0 494.148 494.148" width="8px" style="margin: 0 3px" xml:space="preserve">
                        <path d="M405.284,201.188L130.804,13.28C118.128,4.596,105.356,0,94.74,0C74.216,0,61.52,16.472,61.52,44.044v406.124
                        c0,27.54,12.68,43.98,33.156,43.98c10.632,0,23.2-4.6,35.904-13.308l274.608-187.904c17.66-12.104,27.44-28.392,
                        27.44-45.884 C432.632,229.572,422.964,213.288,405.284,201.188z"/>
                    </svg>
          <drop-content-hover class="drop" :contents="contents"/>
        </a>
        <a href="/blog" class="col-auto">بلاگ</a>
      </div>

      <touch-menu :style="{marginLeft: [nav? 0 : '']}" @close="nav=false" :account="true"
                  @help="help=true" @trading="showTrading=true" @account="accountmenu=true"/>

      <touch-menu-content :style="{marginLeft: [help? 0 : '']}" @close="help=false" :header="'راهنما'"
                          :contents="contents" :currentroute="currentroute"/>
      <touch-menu-content :style="{marginLeft: [showTrading? 0 : '']}" @close="showTrading=false"
                          :header="'معامله'"
                          :contents="trading" :currentroute="currentroute"/>

      <touch-menu-account :style="{marginLeft: [accountmenu? 0 : '']}" @close="accountmenu=false"
                          :currentroute="currentroute"/>

      <Account class="account" :route="currentroute"/>
    </div>
  </div>
</template>

<script>
import Account from "@/components/Navbar/account";
import TouchMenu from "@/components/Navbar/TouchMenu/touch-menu";
import TouchMenuContent from "@/components/Navbar/TouchMenu/touchMenuContent";
import TouchMenuAccount from "@/components/Navbar/TouchMenu/touchMenuAccount";
import DropContentHover from "@/components/Tools/DropContentHover";

export default {
  name: "NavBar",
  components: {
    DropContentHover, TouchMenuAccount, TouchMenuContent, TouchMenu, Account
  },
  data() {
    return {
      nav: false,
      nav1: false,
      help: false,
      showTrading: false,
      accountmenu: false,
      logo: require("assets/logo.png"),
      trade: false,
      tshow: false,
      tsshow: false,
      account: true,
      mobile: false,
      loginpage: false,
      currentroute: '',
      currentposition: '',
      dropname: '',
      subheader: '',
      contents_sub: [],
      contents: [
        {
          name: 'راهنمای استفاده',
          link: '/howtouse',
          route: 'HowToUse'
        },
        {
          name: 'سوالات متداول',
          link: '/faq',
          route: 'FAQ'
        },
        {
          name: 'درباره ما',
          link: '/about-us',
          route: 'AboutUs'
        },
        {
          name: 'قوانین و مقررات',
          link: '/policy',
          route: 'Policy'
        },
      ],
      trading: [
        {
          name: 'معامله ساده',
          link: '/simple',
          route: 'Simple'
        },
        {
          name: 'معامله حرفه ای',
          link: '/trade/TOMAN/BITCOIN',
          route: 'Trade'
        },
      ],
      tradeRoutes: ['Trade', 'Wallet', 'History'],
      accountRoutes: ['Profile', 'Setting', 'Messages', 'Wallet', 'History', 'Invite'],
      deg: [90, 90, 90, 90],
      show: [false, false, false, false],
      tradetoid: '',
      tradefromid: '',
      subcontent: [],
      subcontentstoman: [],
      subcontentsteter: []

    }
  },
  watch: {
    $route() {
      this.nav = false
      this.help = false
      this.showTrading = false
      this.accountmenu = false
    }
  },
  mounted() {
    this.currentroute = this.$route.name;
    this.tradetoid = this.$route.params.tradetoid
    this.tradefromid = this.$route.params.tradefromid
  },
  methods: {
    Route(e) {
      this.$router.push(e)
    },
    touchdropshow(e) {
      this.show[e] = !this.show[e]
      this.$forceUpdate()
    },
    opensub(e) {
      if (e === 'تومان') {
        this.contents_sub = this.subcontentstoman
      } else {
        this.contents_sub = this.subcontentsteter
      }
      this.subheader = e
      this.tsshow = true

    },
    scrollposition() {
      this.currentposition = window.scrollY
    }
  }

  // },
  // created: function () {
  //     // var vm = this;
  //     document.addEventListener('mouseover', function (e) {
  //         console.log(e.target.id)
  //     })
  // },
}
</script>

<style lang="scss" scoped>

.navbar {
  width: 100%;
  box-sizing: inherit;
  background-color: var(--light-background);
  min-height: 54px;
  position: fixed;
  position: -ms-device-fixed;
  top: 0;
  z-index: 50;
  transition: background-color 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transparency {
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgba(24, 43, 78, 0);
}

.logo {
  height: 40px;
  width: 135px;
  max-width: 135px;
  cursor: pointer;
  background-image: url("../../assets/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.menu {
  position: revert;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  max-width: 1070px;
  font-size: 12px;
}

.menuimg {
  display: none;
  flex-direction: column;
  align-items: center;
}

.menu .icon {
  height: 30px;
  width: 50px;
}


.sub-menu2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: max-content;
}


.sub-menu2 a {
  height: 22px;
  /*width: 73px;*/
  padding: 0 20px;
  cursor: pointer;
  /*border-left: solid var(--light-border) 2px;*/
  color: white
}

/*.sub-menu2 a:last-of-type {*/
/*    border-left: none*/
/*}*/

.sub-menu2 a:hover {
  color: var(--main-orange);
}

.selected {
  color: var(--main-orange) !important;
  /*height: 30px;*/
  /*border-bottom: #f8b200 solid 2px;*/
  cursor: default;
}

.selected a {
  color: var(--main-orange);
}

@media (max-width: 767px) {
  .menuimg {
    display: flex;
  }

  .sub-menu2, .account {
    display: none;
  }
}

.touch-menu .account {
  display: flex;
}

ul {
  list-style-type: none;
  font-size: 14px;
  width: max-content;
}

li {
  padding: 10px 12px;
  transition: 0.3s;
  position: relative;
  text-align: center;
}

.dropdown {
  position: relative;

  &.selected svg {
    fill: var(--main-orange);
  }
}

.dropdown svg {
  fill: white;
  transform: rotate(90deg);
  transition: 0.3s;
}

.drop {
  display: none;
}

.dropdown:hover .drop {
  display: block !important;
}

.dropdown:hover svg {
  fill: var(--main-orange);
  transform: rotate(270deg);
}

/*.dark a {*/
/*    color: white;*/
/*    border-color: rgba(255,255,255,0.2);*/
/*}*/

/*.dark svg {*/
/*    fill: white !important;*/
/*}*/

</style>
<template>
  <div id="modal" class="modal-backdrop" @click.self="close">
    <div class="modal"
         role="dialog"
         aria-labelledby="WithdrawModal"
         aria-describedby="WithdrawModal">
      <img src="../../assets/icon/close.svg"
           class="btn-close"
           @click="close"
           aria-label="Close modal" alt="">
      <header class="modal-header">
        برداشت {{ $coins[relatedCoin].persianName }}
      </header>
      <section class="modal-body" style="direction: rtl">
        <form @submit.prevent="withdraw">
          <div class="row no-gutters input">
            <label class="col-md-4" for="amount">مقدار {{ $coins[relatedCoin].persianName }}</label>
            <input class="col-md-8" type="text" @input="amount = $toLocal(amount)" v-model="amount"
                   name="amount" id="amount" v-tooltip.v-info="danger">
            <div class="row col-12 offset-0 col-md-8 offset-md-4 no-gutters">
              <a class="col-7">موجودی قابل برداشت:</a>
              <div class="col-5 align-items-center lefttext" style="display: flex;gap:0 5px">
                <a @click.prevent="amount=wallet.credit"> {{ $toLocal(wallet.credit) }} </a>
                <small>({{ $coins[relatedCoin].code }})</small>
              </div>
            </div>
          </div>
          <div class="row no-gutters input">
            <label class="col-12 col-md-4 d-flex-ac-jfs" for="network">شبکه</label>
            <div class="select col-md-8">
              <select class="col-12" v-model="token" id="network"
                      :style="{color: [token? '':'#00544f90']}">
                <option value="" disabled selected>انتخاب شبکه</option>
                <option v-for="(rol,index) in role.tokenTypeDetails" :key="index" :value="rol">
                  {{ rol.tokenType }}
                </option>
              </select>
              <a>&nbsp;</a>
            </div>
          </div>
          <div class="row no-gutters input">
            <label class="col-12 col-md-4" for="walletaddress">آدرس کیف ‌پول مقصد</label>
            <input class="col-12 col-md-8" type="text" v-model="walletAddress" id="walletaddress"
                   @input="checkAddress" :valid="valid">
            <div class="w-100"/>
            <a class="col-md-8 offset-md-4">توجه: وارد کردن آدرس نادرست منجر به از دست رفتن منابع مالی
              شما میشود.</a>
          </div>

          <div class="row justify-content-center" style="font-size: 16px">
            تراکنش دارای کارمزد
            {{ token.withdrawalFee }}
            {{ $coins[relatedCoin].persianName }}
            مربوط به شبکه
            {{ token.tokenType }}
            می باشد.
          </div>
          <button
              :disabled="disabled"
              hidden
              type="submit"
          />
        </form>
      </section>
      <footer class="modal-btn">
        <button
            :disabled="disabled"
            type="button"
            class="normal-btn"
            @click="withdraw"
            aria-label="Close modal">
          برداشت
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
var WAValidator = require('multicoin-address-validator');
export default {
  name: "WithdrawModal",
  props: {
    relatedCoin: {
      default: ''
    },
    wallet: {
      default: ''
    },
    walletType: {
      default: 'P2P'
    },
    role: {}
  },
  data() {
    return {
      amount: '',
      walletAddress: '',
      token:false,
      valid: '',
    }
  },
  computed: {
    disabled() {
      return !this.$S2N(this.amount)
          || !this.walletAddress
          || !this.token
          || (this.$S2N(this.amount) > this.$S2N(this.wallet.credit))
          || (this.$S2N(this.amount) > this.$S2N(this.max))
          || (this.$S2N(this.amount) < this.$S2N(this.min))
    },
    min() {
      return this.token?.minWithdraw || 0
    },
    max() {
      return this.token?.maxWithdraw || 0
    },
    danger() {
      return 'Min : ' + this.$toLocal(this.min) + ' ' + this.$coins[this.relatedCoin].code
          + '\n' + 'Max : ' + this.$toLocal(this.max) + ' ' + this.$coins[this.relatedCoin].code
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async withdraw() {
      if (!this.disable && !this.state.loading) {
        this.state.loading = true
        let transaction = {
          walletType : this.walletType,
          amount: this.$S2N(this.amount),
          destinationWalletAddress: this.walletAddress,
          relatedCoin: this.relatedCoin,
          tokenType: this.token.tokenType,
        }
        const res = await this.$axios.post("/users/send-code")
        this.$emit(
            "two-fa",
            [transaction, res.baseDTO.towStepAuthenticationType]
        );
      }
    },
    async checkAddress() {
      const temp = this.walletAddress
      await this.$sleep(500)
      if (temp === this.walletAddress)
        this.valid = WAValidator.validate(this.walletAddress, this.$coins[this.relatedCoin].code) ? 'yes' : 'no'
      else
        this.valid = ''
    }
  },
}
</script>

<style lang="scss" scoped>

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(19, 22, 27, 0.1);
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
  align-items: center;
  z-index: 55;
  color: var(--font-color);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  flex-direction: column;
}

.modal {
  position: relative;
  background: var(--dark-main);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 5px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  padding: 10px 10px;
}

.modal-header {
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.btn-close {
  position: absolute;
  left: 15px;
  top: 15px;
  height: 22px;
  width: 22px;
  transition: 0.5s transform ease-in;
  cursor: pointer;
  z-index: 5;
}

.btn-close:hover {
  transform: rotate(360deg);
}

.modal-body {
  margin-top: 20px;
  position: relative;
  background-color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 0 30px 20px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .25s ease;
}

form {
  margin: 20px 0;
  border-radius: 5px;
  width: 100%;
  font-size: 14px;
}

.input {
  margin-bottom: 20px;
}

label {
  font-size: 16px;
  margin-top: auto;
  margin-bottom: auto;
}

input, select {
  border: solid rgba(54, 52, 53, 0.15) 1px;
  height: 39px;
  border-radius: 5px;
  padding: 2px 5px !important;
}

input:hover, select:hover {
  border: solid rgba(54, 52, 53, 0.3) 1px;
}

input:focus, select:focus {
  border: solid rgba(54, 52, 53, 0.5) 1px;
}

input[valid="yes"] {
  border-color: green
}

input[valid="no"] {
  border-color: red
}

select {
  border: solid rgba(54, 52, 53, 0.15) 0.5px;
  height: 39px;
  border-radius: 5px;
  padding: 2px 5px !important;
  font-size: 16px;
}

.select {
  overflow: hidden;
  position: relative;
}

.select a {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 11px;
  height: 11px;
  border-left: black solid 4px;
  border-radius: 1px;
  border-bottom: black solid 4px;
  transform: translate(0, -65%) rotate(-45deg);
  transition: 0.25s;
}


.modal-btn {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.normal-btn {
  font-size: 16px;
  color: white;
  max-width: 200px;
  margin: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0;
}

.lefttext {
  text-align: left;
  direction: ltr;
}

.lefttext a {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<template>
  <div class="simple">
    <div class="header">
      <h1>معامله آسان</h1>
      <p>ساده ترین راه برای خرید و فروش رمز ارزها</p>
    </div>
    <div class="pannel">
      <over-loading v-if="loading"/>
      <div class="block">
        <div class="tab">
          <div style="border-top-right-radius: 5px;border-bottom-right-radius: 5px"
               :class="buy ? 'tab-content-active' : 'tab-content'"
               @click.prevent="buy=true;calculate();">
            <a>خرید</a>
          </div>
          <div style="border-top-left-radius: 5px;border-bottom-left-radius: 5px" class="sell"
               :class="!buy ? 'tab-content-active' : 'tab-content'"
               @click.prevent="buy=false;calculate();">
            <a>فروش</a>
          </div>
          <!--            <div class="selector" :style="{right :[limit? '' : '50%']}"></div>-->
        </div>
        <div class="cash">
          <p>موجودی</p>
          <p @click="setPrice(credit)" v-if="buy">
            {{ $toLocal(credit, $prcesion[From]) }}
            {{ $coins[To].persianName }}
          </p>
          <p @click="amount=credit;calculate();" v-else>
            {{ $toLocal(credit, $decimal[From]) }}
            {{ $coins[From].persianName }}
          </p>
        </div>
        <form @input="calculate" @submit.prevent="accept">
          <div class="amount">
            <input type="text" @input="amount = $toLocal(amount,$decimal[From])" v-model="amount"
                   v-tooltip.v-info="range">
            <select :value="From" @change="setFrom">
              <option v-for="{destCoinSymbol} of items"
                      :key="destCoinSymbol" :value="destCoinSymbol">
                {{ $coins[destCoinSymbol].code }}
              </option>
            </select>
            <over-loading v-if="loading2"/>
          </div>
          <div class="price">
            <input type="text" v-model="price" disabled>
            <select :value="To" @change="setTo">
              <option
                  v-for="{destCoinSymbol} of (From==='TETHER'? [{destCoinSymbol:'TOMAN'}] :  [{destCoinSymbol:'TETHER'},{destCoinSymbol:'TOMAN'}])"
                  :key="destCoinSymbol" :value="destCoinSymbol">
                {{ $coins[destCoinSymbol].code }}
              </option>
            </select>
            <over-loading v-if="calculating"/>
          </div>
          <button :style="{backgroundColor: buy ? 'var(--main-green)' : 'var(--main-red)'}" type="submit"
                  :disabled="disabled">{{ buy ? 'Buy' : 'Sell' }}
          </button>
        </form>

      </div>
    </div>
  </div>
</template>

<script>
import {Loop} from "@/lib/reuseableFunction";
import OverLoading from "components/over-loading";

export default {
  name: "Simple",
  components: {OverLoading},
  data() {
    return {
      buy: true,
      label: '',
      fromunit: 'BITCOIN',
      temp: 0,
      calculating: false,
      tounit: {
        name: 'تومان',
        value: 'TOMAN',
      },
      modal: false,
      amount: '',
      price: '',
      loading: false,
      loading2: false,
      alive: true,
      buyPrice: 0,
      sellPrice: 0,
      items: [],
      role: '',
      loop: undefined,
      coins: {},
      wallet: [
        {}
      ],
    }
  },
  computed: {
    From() {
      return this.$route.query.from || 'BITCOIN'
    },
    To() {
      return this.$route.query.to || 'TOMAN'
    },
    credit() {
      if (this.buy) {
        return this.wallet[this.To]?.credit || 0
      } else {
        return this.wallet[this.From]?.credit || 0
      }
    },
    disabled() {
      return (this.$S2N(this.buy ? this.price : this.amount) > this.$S2N(this.credit))
          || !this.$S2N(this.price)
          || !this.$S2N(this.credit)
    },
    selected() {
      return this.role?.tokenTypeDetails?.find(e => e.tokenType === this.data.tokenType)
    },
    fee() {
      return this.selected?.withdrawalFee
    },
    range() {
      return 'حداقل : ' + this.$toLocal(this.min) + ' ' + this.$coins[this.From].code
          + '\n' + 'حداکثر : ' + this.$toLocal(this.max) + ' ' + this.$coins[this.From].code
    },
    min() {
      return this.role.minAmountExchange
    },
    max() {
      return this.role.maxAmountExchange
    }
  },
  methods: {
    setPrice(e) {
      this.amount = this.$toLocal(e / this[this.buy ? 'buyPrice' : 'sellPrice'], this.$decimal[this.From])
      this.calculate()
    },
    calculate() {
      this.price = this.$toLocal(this.$S2N(this.amount) * this[this.buy ? 'buyPrice' : 'sellPrice'], this.$prcesion[this.From])
    },
    setFrom(e) {
      this.$router.push({query: {...this.$route.query, from: e.target.value}})
      this.setData()
      this.getNets()
      this.calculate()
    },
    setTo(e) {
      this.$router.push({query: {...this.$route.query, to: e.target.value}})
      this.beforeLoop()
    },
    async getWalletCredit() {
      if (this.state.userInfo.authorized) {
        this.loading = true
        this.wallet = await this.$axios.get('/wallets/customer-addresses?walletType=EXCHANGE')
        this.loading = false
      }
    },
    async accept() {
      // if (!this.useWallet && (!this.data.tokenType || !this.data.destinationWallet)) return
      let text = []

      text[0] = 'سفارش'
      text[1] = this.buy ? 'خرید:' : 'فروش:'
      text[2] = this.amount
      text[3] = this.$coins[this.From].code
      text[4] = '- به ارزش:'
      text[5] = this.price
      text[6] = this.$coins[this.To].code
      /*if (!this.useWallet) {
        text[7] = 'و انتقال به آدرس'
        text[8] = this.data.destinationWallet
        text[9] = 'تحت شبکه'
        text[10] = this.data.tokenType
      }*/

      const res = await this.$error('آیا از سفارش خود مطمعن هستید؟', text.join(' '), 'warning', 'بله', 'لغو')
      if (res.isConfirmed && !this.state.loading) {
        this.state.loading = true;
        this.sendData()
      }
    },
    async sendData() {
      let data = {
        marketType: this.From + '_' + this.To,
        destinationCoin: this.From,
        orderType: 'EXCHANGE_' + (this.buy ? 'BUY' : 'SELL'),
        amount: this.$S2N(this.amount),
        totalPrice: this.$S2N(this.price),
        unitPrice: this.$S2N(this[this.buy ? 'buyPrice' : 'sellPrice'])
      }
      data.amount = this.$S2N(this.amount)
      data.totalPrice = this.$S2N(this.price)
      data.unitPrice = this.$S2N(this[this.buy ? 'buyPrice' : 'sellPrice'])
      /*if (this.useWallet) {
        delete temptData.destinationWallet
        delete temptData.tokenType
      }*/
      await this.$axios.post(`/exchange/${(this.buy ? 'buy' : 'sell')}`, data)
      await this.$error('', 'سفارش با موفقیت انجام شد', 'success')
      this.getWalletCredit()
    },
    async getCoins() {
      this.state.loading = true
      const res = await this.$axios.get('/coins/details')
      this.coins = res.content
      this.getNets()
    },
    getNets() {
      const tradeFrom = this.From
      this.role = this.coins.find((a) => a.coin === tradeFrom)
    },
    setData() {
      const from = this.From
      this.buyPrice = this.items.find(a => a?.destCoinSymbol === from).buyPrice
      this.sellPrice = this.items.find(a => a?.destCoinSymbol === from).sellPrice
      this.calculate()
    },
    async getPrice() {
      [this.items,] = await this.$http.get('coins/exchange-price/' + (this.To === 'TETHER' ? 'usdt' : 'usd') + '?includeWeeklyChange=false')
      this.loading = false
      // this.items = this.items.filter(e => ['TOMAN','TETHER'].includes(e.destCoinSymbol))
      this.setData()
    },
    beforeLoop() {
      this.loop?.stop()
      this.loading = true
      this.loop = new Loop(this.getPrice, 5000, 'realPrice' + this.To)
      if (this.state.loop) {
        this.loop.start()
      } else {
        this.getPrice()
      }
    }
  },
  mounted() {
    this.getCoins()
    if (this.state.userInfo.authorized)
      this.getWalletCredit()
    this.beforeLoop()
  },
  beforeDestroy() {
    this.loop?.stop()
  }
};
</script>

<style lang="scss" scoped>
.simple {
  width: 100%;
  margin-top: -7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .header {
    min-height: calc(35vh);
    width: 100%;
    background-color: #202020;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 13%, rgba(0, 0, 0, 0.7)),
    url("../../assets/header.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-blend-mode: normal;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pannel {
    width: 100%;
    min-height: calc(65vh - 57px);
    background-color: var(--main-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .block {
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      width: calc(100% - 20px);
      max-width: 430px;
      border-radius: 5px;

      .tab {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 30px;
        /*border-bottom: #00000010 1px solid;*/
        font-size: 12px;
        align-items: center;
        background-color: white;
        text-align: right;
        width: 100%;

        .tab-content {
          font-weight: 800;
          width: 50%;
          height: 30px;
          text-align: center;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #00000010;
        }

        .tab-content-active {
          font-weight: 800;
          width: 50%;
          height: 30px;
          opacity: 1;
          text-align: center;
          background-color: var(--main-green);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: default;
          color: white;

          a {
            color: white;
          }
        }

        .tab-content-active.sell {
          background-color: var(--main-red);
        }

        a {
          color: var(--font-color);
        }
      }

      .cash {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        p {
          margin-bottom: 0;

          &:last-of-type {
            text-decoration: underline;
          }
        }
      }

      .price, .amount {
        display: flex;
        width: 100%;
        font-size: 14px;
        border-radius: 5px;
        height: 35px;
        margin: 20px 0;
        position: relative;

        input {
          width: calc(100% - 120px);
          border: none;
          border-left: 2px solid var(--light-border);
          font-size: 14px;
          height: 100%;
          padding: 0 5px;
        }

        select, span {
          width: 120px;
          font-size: 14px;
          height: 100%;
          padding: 0 5px;
        }

        select {
          background-image: url("../../assets/icon/down-arrow.svg");
          background-size: 7px;
          background-repeat: no-repeat;
          background-position: 10px center;
        }
      }

      .amount {
        border: 2px solid var(--light-border);
      }

      .price {
        border: 2px solid transparent;
        background-color: #00000010;
      }

      button {
        width: 100%;
        height: 35px;
        border-radius: 5px;
        color: white;
        font-weight: 800;
        cursor: pointer;
        margin-top: 10px;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }
    }
  }
}


</style>
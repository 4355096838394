<template>
    <div class="main-box">
        <h1 style="text-align: right">اعلانات</h1>
        <p v-if="messages.length === 0" style="width:100%;text-align:center;margin-top:50px">پیامی جهت نمایش وجود ندارد.</p>
        <div class="message-block" v-for="(message,index) in messages" :key="index">
            <div @click.prevent="toggle(index)" class="message-header">
                <div class="header-header">
                    <svg height="12" width="12">
                        <circle cx="6" cy="6" r="5px" :fill="[message.read ? '#00000030' : 'var(--main-green)']"/>
                    </svg>
                    <img src="../../assets/icon/mail.svg" alt="">
                    <h3>{{message.title}}</h3>
                </div>
                <h3 class="date">{{message.date}}</h3>
                <svg height="14px" :style="{transform: [showmessage[index] ?  'rotate(90deg)':'' ],
                fill: [showmessage[index] ?  'var(--main-orange)':'' ]}"
                     version="1.1" id="Capa_1"
                     xmlns="http://www.w3.org/2000/svg"
                     x="0px" y="0px" viewBox="0 0 443.52 443.52" xml:space="preserve">
                <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                L143.492,221.863z"/>
                </svg>
            </div>
            <div class="message-description" :style="{maxHeight: [showmessage[index]? '150px' : '']}">
                <p>{{message.content}}</p>
            </div>
        </div>
        <pagination v-if="totalElements > 10" style="margin-top: 30px" :n="6" :max="3" :page="id"/>
    </div>
</template>

<script>

    import Pagination from "../Tools/Pagination";

    export default {
        name: "Messages",
        components: {Pagination},
        props: ['id'],
        // components:
        data() {
            return {
                showmessage: [],
                messages: [],
                loading: false,
                page: 1,
                perPage: 10,
                totalElements: 1,
            }
        },
        watch: {
            "$route.params.id"() {
                this.getMessages()
            }
        },
        mounted() {
            this.getMessages()
        },
        methods: {
            async getMessages() {
                if (!this.$route.params.id) {
                    this.page = 1
                } else {
                    this.page = this.$route.params.id
                }
                const res = await this.$axios.get(`/users/notifications?read=&page=${this.page}&size=10`)
                this.totalElements = res.totalElements
                if (res.message === 'No Notifications') {
                    this.messages = []
                } else {
                    this.messages = res.content
                }
            },
            toggle(e) {
                this.showmessage[e] = !this.showmessage[e];
                this.$forceUpdate()
                this.readMessage(e)
            },
            async readMessage(e) {
                if (!this.messages[e].read) {
                    await this.$axios.put(`/users/notifications/read/${this.messages[e].id}`)
                    this.messages[e].read = true
                }
                this.$forceUpdate()
            },
        },

    }
</script>

<style lang="scss" scoped>
    .date {
        margin-right: -135px;
    }

    @media (max-width: 576px) {
        .date {
            margin-right: -70px;
        }
    }

    .main-box {
        display: inline-block;
        background-color: transparent;
        text-align: right;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1170px;
        color: var(--font-color);
    }

    h1 {
        font-family: IRANSans-Bold, sans-serif;
        font-size: 30px;
    }

    .message-block {
        display: block;
        flex-direction: column;
        align-items: center;
        background-color: white;
        text-align: right;
        border-bottom: #00000015 solid 1px;
        box-sizing: border-box;
        width: 100%;
        font-size: 20px;
        transition: 0.3s;
    }

    .message-header {
        position: relative;
        display: flex;
        height: 61px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        svg {
            transform: rotate(-90deg);
            margin-left: 10px;
            transition: 0.3s;
        }

        &:hover {
            svg {
                fill: var(--main-orange);
            }
        }
    }

    .message-header h3 {
        font-family: VistaDev, sans-serif;
        font-weight: 300;
        font-size: 18px;
        min-width: 100px;
    }

    .message-description {
        font-size: 16px;
        overflow: hidden;
        transition: 0.4s ease-out;
        -webkit-transition: 0.4s ease-out;
        -moz-transition: 0.4s ease-out;
        max-height: 0;

        p {
            margin-top: 0;
        }
    }

    .header-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-width: 100px;

        svg, img {
            margin-left: 10px
        }
    }

    @media (max-width: 400px) {
        .message-block, .message-header h3, .message-description {
            font-size: 16px !important;
        }
        .date {
            margin-right: -50px;
        }
    }

</style>
<template>
  <div class="box2">
    <over-loading v-if="loading"/>
    <table>
      <tr class="table-header">
        <th>بازار</th>
        <th>قیمت</th>
        <th>تغییرات</th>
      </tr>
      <tr v-if="!loading && !tableContents.length">
        <td colspan="3">
          <p style="width:100%;text-align:center;color:red">در حال حاضر بازار فعالی وجود ندارد.</p>
        </td>
      </tr>
      <tr v-for="tableContent in tableContents" :key="tableContent.price" class="table-row"
          @click="goto(tableContent.destCoinSymbol)"
          :class="{active : tradeFrom === tableContent.destCoinSymbol && tradeTo === (toman ? 'TOMAN' : 'TETHER')}">
        <td>
          <span class="relatedCoin">
            <img width="18" :src="'/coins/' + tableContent.destCoinSymbol + '.png'"/>
            {{ $coins[tableContent.destCoinSymbol].persianName }}
          </span>
        </td>
        <td :style="{color:[Number(tableContent.price24hrChangePercent)>0?'var(--main-green)':'var(--main-red)']}">
          {{ $toLocal(tableContent.price, toman ? 0 : ($prcesion[tableContent.sourceCoinSymbol])) }}
        </td>
        <td :style="{color:[Number(tableContent.price24hrChangePercent)>0?'var(--main-green)':'var(--main-red)']}">
          {{ (Number(tableContent.price24hrChangePercent).toFixed(1)) }}٪
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import OverLoading from "@/components/over-loading";
import {tradeFrom, tradeTo} from "lib/reuseableFunction";

export default {
  name: "RealPriceTableToman",
  components: {OverLoading},
  props: {
    toman: {
      default: true
    }
  },
  computed: {
    tradeTo, tradeFrom
  },
  data() {
    return {
      alive: false,
      tableContents: [],
      loading: false
    }
  },
  watch: {
    'toman'() {
      this.tab()
    }
  },
  methods: {
    async getPrice() {

      const url = this.toman ? '/coins/price/usd' : '/coins/price/usdt'

      const [res,] = await this.$http.get(url, {
        params: {
          include24hrChange: true,
          includeWeeklyChange: false
        }
      })

      if (res) {
        this.tableContents = res
        this.sort()
      }
    },
    goto(e) {
      this.$router.push('/trade/' + (this.toman ? 'TOMAN' : 'TETHER') + `/${e}`)
    },
    sort() {
      this.tableContents.sort((a, b) => this.$sortCoins.indexOf(a.sourceCoinSymbol) - this.$sortCoins.indexOf(b.sourceCoinSymbol))
    },
    async loop() {
      this.loading = true
      const tab = this.toman
      while (this.alive && tab === this.toman) {
        await this.getPrice().catch(() => {
        })
        this.loading = false
        await this.$sleep(5000)
      }
    },
    async tab() {
      this.tableContents = []
      this.loading = true
      await this.beforeLoop()
      this.loading = false
    },
    async beforeLoop() {
      if (this.state.loop)
        this.loop()
      else {
        this.loading = true
        await this.getPrice().catch(() => {
          this.loading = false
        })
        this.loading = false
      }
    }
  },
  mounted() {
    this.alive = true
    this.beforeLoop()
  },
  beforeDestroy() {
    this.alive = false
  }

}
</script>

<style lang="scss" scoped>
.relatedCoin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.box2 {
  font-size: 12px;
  max-height: 392px;
  display: block;
  direction: rtl;
  /*background-color: white;*/
  width: 100%;
  z-index: 100;
  height: 100%;

  .ps {
    height: 100%;
  }
}

table {
  position: relative;
  /*background-color: white;*/
  direction: rtl;
  width: 100%;
  border-collapse: collapse;
  min-height: 70px;
}

table th {
  height: 30px;
  opacity: 0.8;
}

tr th:first-child, td:first-child {
  text-align: right;
}

tr th:last-child, td:last-child {
  direction: ltr;
  text-align: left;
}

.table-header {
  position: relative;
  top: 0;
  /*background-color: white;*/
  text-align: center;
}

table td {
  height: 25px;
  opacity: 0.8;
}

.table-row {
  position: relative;
  text-align: center;
  cursor: default;

  &.active {
    font-weight: bold;
    text-decoration: underline;
  }
}

.table-row:hover {
  background-color: var(--hover);
  cursor: pointer;
  font-weight: bold;
  //transform: scale(1.05);
}

</style>
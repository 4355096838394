<template>
    <div class="main-pagination row no-gutters justify-content-center">
        <svg class="arrow" v-show="nums[nums.length-1] < n"
             @click="()=>this.$router.push({ name: link , params: { id : n} })"
        height="11px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 192.689 192.689" style="enable-background:new 0 0 192.689 192.689;" xml:space="preserve">
		<path d="M188.527,87.755l-83.009-84.2c-4.692-4.74-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l74.54,75.61
			l-74.54,75.61c-4.704,4.74-4.704,12.439,0,17.179c4.704,4.74,12.319,4.74,17.011,0l82.997-84.2
			C193.05,100.375,193.062,92.327,188.527,87.755z"/>
            <path d="M104.315,87.755l-82.997-84.2c-4.704-4.74-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l74.528,75.61
			l-74.54,75.61c-4.704,4.74-4.704,12.439,0,17.179s12.319,4.74,17.011,0l82.997-84.2C108.838,100.375,108.85,92.327,104.315,87.755
			z"/>
</svg>
        <svg class="arrow" v-show="page != n"
             @click="()=>this.$router.push({ name: link , params: { id : (Number(page)+1) }})" height="11px"
             version="1.1" id="Capa_1"
             xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" viewBox="0 0 443.52 443.52"
             style="transform: rotate(180deg);margin-left: 0" xml:space="preserve">
                <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                L143.492,221.863z"/>
            </svg>
        <a class="etc" v-show="nums[nums.length-1] < n">...</a>
        <div class="main">
            <div class="number" v-for="(num,index) in nums" :key="index">
                <a :style="{color:[(num==page)?'var(--main-orange)':'']}"
                   @click="()=>$router.push({ name: link , params: { id : num} })">
                    {{num}}
                </a>
            </div>
        </div>
        <a class="etc" v-show="nums[0] > 1">...</a>
        <svg style="margin-right: 0" class="arrow" v-show="page != 1"
             @click="()=>this.$router.push({ name: link , params: { id : page-1} })" height="11px"
             version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" viewBox="0 0 443.52 443.52"
             xml:space="preserve">
                <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8
                c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712
                L143.492,221.863z"/>
        </svg>
        <svg class="arrow" v-show="nums[0] > 1"
             @click="()=>this.$router.push({ name: link , params: { id : 1} })"
             height="11px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 192.689 192.689" style="enable-background:new 0 0 192.689 192.689;transform: rotate(180deg)" xml:space="preserve">
		<path d="M188.527,87.755l-83.009-84.2c-4.692-4.74-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l74.54,75.61
			l-74.54,75.61c-4.704,4.74-4.704,12.439,0,17.179c4.704,4.74,12.319,4.74,17.011,0l82.997-84.2
			C193.05,100.375,193.062,92.327,188.527,87.755z"/>
            <path d="M104.315,87.755l-82.997-84.2c-4.704-4.74-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l74.528,75.61
			l-74.54,75.61c-4.704,4.74-4.704,12.439,0,17.179s12.319,4.74,17.011,0l82.997-84.2C108.838,100.375,108.85,92.327,104.315,87.755
			z"/>
</svg>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props: {
            n: {
                type: Number,
                default: 1
            },
            page: {
                default: 1
            },
            max: {
                default: 1
            },
            link: {
                default: '/'
            }
        },
        data() {
            return {
                nums: '',
            }
        },
        watch: {
            $route(to) {
                this.pages(to.params.id)
            }
        },
        created() {
            this.pages(this.page)
        },
        methods: {
            pages(e) {
                // var e = this.page;
                if (e < (this.max - 1)) {
                    this.number(0)
                } else if (e > (this.n - (this.max - 1))) {
                    if (this.n <= this.max) {
                        this.number(0)
                    } else {
                        this.number(this.n - this.max)
                    }
                } else {
                    this.number(e - (this.max - 1))
                }
                // if (e < 4) {
                //     for (let i = 0; i < 5; i++) {
                //         a[i] = i + 1;
                //         if (i + 1 >= this.n) {
                //             break;
                //         }
                //     }
                // } else if(e > (this.n-3)){
                //     for (let i = 0; i < 5; i++) {
                //         console.log(i)
                //         a[i] = this.n - (4-i+1)
                //     }
                //     this.num = a
                // }else{
                //     for (let i = 0; i < 5; i++) {
                //         a[i] = e-2 + i;
                //     }
                // }

            },
            number(e) {
                var a = [0];
                for (let i = 0; i < this.max; i++) {
                    a[i] = e + i + 1;
                    if (e + i + 1 >= this.n) {
                        break;
                    }
                }
                this.nums = a
            }
        }
    }
</script>

<style scoped>
    .main-pagination {
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .main {
        display: inline-flex;
        direction: ltr;
    }

    .number {
        font-weight: 600;
        margin: 0 15px;
        cursor: pointer;
    }

    .number a:hover {
        color: var(--main-orange);
    }

    .etc {
        margin: -3px 10px 0 10px;
        cursor: pointer;
    }

    .etc-n {
        cursor: pointer;
    }

    .etc-n:hover {
        color: var(--main-orange);
    }

    .arrow {
        padding: 0 10px;
        margin: 0 10px
    }

    .arrow:first-of-type {
        margin: 0;
    }

    .arrow:last-of-type {
        margin: 0;
    }

    .arrow:hover {
        fill: var(--main-orange);
        cursor: pointer;
    }

</style>
<template>
  <div id="modal" class="modal-backdrop" @click.self="close">
    <div class="modal"
         role="dialog"
         aria-labelledby="WithdrawModal"
         aria-describedby="WithdrawModal">
      <img
          src="../../assets/icon/close.svg"
          class="btn-close"
          @click="close"
          aria-label="Close modal" alt="">
      <header class="modal-header">
        واریز {{ $coins[relatedCoin].persianName }}
      </header>
      <section class="modal-body" style="direction: rtl">
        <div class="row no-gutters input">
          <label class="col-12 col-md-4 d-flex-ac-jfs" for="walletaddress">شبکه</label>
          <div class="select col-12 col-md-8">
            <select class="col-12" v-model="token" id="walletaddress"
                    :style="{color: [token? '':'#00544f90']}" @change="getQR">
              <option value="" disabled selected>انتخاب شبکه</option>
              <option v-for="(rol,index) in role.tokenTypeDetails" :key="index" :value="rol">
                {{ rol.tokenType }}
              </option>
            </select>
            <a>&nbsp;</a>
          </div>
        </div>
        <div class="row justify-content-center" style="font-size: 16px">
          آدرس کیف پول شما در زیر قابل مشاهده است. برای واریز ارزهای دیجیتال به این کیف، می‌توانید از این
          آدرس استفاده کنید.
        </div>
        <div class="address" @click="Copy(address)" v-tooltip.hover="message" @mouseleave="hide">{{ address }}</div>
        <img width="250px" :src="'data:image/png;base64,'+qrCode" alt="">
        <p v-if="['STELLAR','RIPPLE'].includes(relatedCoin)"> تگ یا ممو : {{ tag }}</p>
        <div class="row no-gutters input" v-if="token.txIdIsNeeded">
          <label class="col-md-4" for="amount">شناسه تراکنش</label>
          <input class="col-md-8" type="text" v-model="txid"
                 name="amount" id="amount">
        </div>
      </section>
      <footer class="modal-btn">
        <button
            type="button"
            class="normal-btn"
            v-tooltip.hover="message"
            @mouseleave="hide"
            @click="Copy(address)">
          <img src="../../assets/icon/copy.svg" alt="">
          کپی آدرس
        </button>
        <button
            type="button"
            class="normal-btn"
            @click.prevent="checkWallet"
            :disabled="token.txIdIsNeeded && !txid"
        >
          بررسی واریز
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "DepositModal",
  props: {
    relatedCoin: {
      default: ''
    },
    wallet: {
      default: ''
    },
    walletType: {
      default: 'P2P'
    },
    role: {}
  },
  data() {
    return {
      amount: '',
      address: '',
      qrCode: '',
      tag: '',
      txid: '',
      token: false,
      message: 'کپی'
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    changeNetwork(e) {
      this.token = e
      this.getQR()
    },
    async Copy(e) {
      await navigator.clipboard.writeText(e)
      this.message = 'کپی شد'
    },
    hide() {
      setTimeout(() => {
        this.message = 'کپی'
      }, 500)
    },
    async checkWallet() {
      this.state.loading = true
      let query = {
        relatedCoin: this.relatedCoin,
        tokenType : this.token.tokenType
      }
      if (this.token.txIdIsNeeded) {
        query.txid = this.txid
      }
      const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
        params: query
      })
      let text
      if (res?.baseDTO?.amount) {
        text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coins[this.relatedCoin].persianName} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
      } else {
        text = 'مقدار واریزی شناسایی نشد'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
    },
    async getQR() {
      this.state.loading = true
      let params = {
        walletType: this.walletType,
        relatedCoin: this.relatedCoin,
        tokenType: this.token.tokenType
      }
      const res = await this.$axios(`/wallets/customer/wallet-address`, {params})
      if (res) {
        this.address = res.baseDTO.address
        this.qrCode = res.baseDTO.qrCode
        this.tag = res.baseDTO.tag
      }
    }
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  }
}
</script>

<style scoped>

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(19, 22, 27, 0.1);
  display: flex;
  flex-direction: column;
  overflow: auto;
  justify-content: center;
  align-items: center;
  z-index: 55;
  color: var(--font-color);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  flex-direction: column;
}

.modal {
  position: relative;
  background: var(--dark-main);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(1px);
  border-radius: 5px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  padding: 10px 10px;
}

.modal-header {
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
}

.btn-close {
  position: absolute;
  left: 15px;
  top: 15px;
  height: 22px;
  width: 22px;
  transition: 0.5s transform ease-in;
  cursor: pointer;
  z-index: 5;
}

.btn-close:hover {
  /*transform: rotate(180deg);*/
  transform: rotate(360deg);
}

.modal-body {
  margin-top: 20px;
  position: relative;
  background-color: white;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 20px 5% 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-body img {
  margin-top: 10px;
}

.modal-body div:nth-last-of-type(2) {
  margin: 30px 0 10px;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .25s ease;
}

.input {
  margin-bottom: 20px;
  width: 100%
}

label {
  font-size: 16px;
  vertical-align: center;
}

input, select {
  border: solid rgba(54, 52, 53, 0.15) 1px;
  height: 39px;
  border-radius: 5px;
  padding: 2px 5px !important;
}

input:hover, select:hover {
  border: solid rgba(54, 52, 53, 0.3) 1px;
}

input:focus, select:focus {
  border: solid rgba(54, 52, 53, 0.5) 1px;
}

.modal-btn {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  gap: 0 10px;
}

select {
  border: solid rgba(54, 52, 53, 0.15) 0.5px;
  height: 39px;
  border-radius: 5px;
  padding: 2px 5px !important;
  font-size: 16px;
}

.select {
  overflow: hidden;
  position: relative;
}

.select a {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 11px;
  height: 11px;
  border-left: black solid 4px;
  border-radius: 1px;
  border-bottom: black solid 4px;
  transform: translate(0, -65%) rotate(-45deg);
  transition: 0.25s;
}


.normal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: white;
  max-width: 200px;
  margin: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0;
}

.normal-btn img {
  margin-left: 10px;
}

@media (max-width: 400px) {
  .address {
    font-size: 12px;
  }

}

</style>

import { render, staticRenderFns } from "./setGoogleAuthModal.vue?vue&type=template&id=450a7af1&scoped=true&"
import script from "./setGoogleAuthModal.vue?vue&type=script&lang=js&"
export * from "./setGoogleAuthModal.vue?vue&type=script&lang=js&"
import style0 from "./setGoogleAuthModal.vue?vue&type=style&index=0&id=450a7af1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "450a7af1",
  null
  
)

export default component.exports
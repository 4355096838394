<template>
  <div class="main-box">
    <my-wallet @deposit="depositModal" @withdraw="withdrawModal" :type.sync="type"/>
    <wallet-history :tab="tab" :id="id"/>
    <transition name="modal-fade">
      <withdraw-modal
          v-if="showWithModal"
          @close="closemodal"
          @two-fa="openTwoStepModal"
          id="withmodal"
          :wallet="destination.wallet"
          :wallet-type="type"
          :related-coin="destination.relatedCoin"
          :role="coins.find((a) => a.coin === destination.relatedCoin)"
      />
      <withdraw-modal-rial
          v-if="showRWithModal"
          @close="closemodal"
          @two-fa="openTwoStepModal"
          id="withmodalR"
          :wallet="destination.wallet"
          :wallet-type="type"
          :role="coins.find((a) => a.coin === destination.relatedCoin)"
      />

      <deposit-modal
          v-if="showDepModal"
          @close="closemodal"
          id="depmodal"
          :wallet="destination.wallet"
          :related-coin="destination.relatedCoin"
          :wallet-type="type"
          :role="coins.find((a) => a.coin === destination.relatedCoin)"
      />
      <deposit-modal-rial
          v-if="showRDepModal"
          @close="closemodal"
          id="depmodalR"
          :wallet-type="type"
      />
    </transition>
    <transition name="modal-fade">
      <set-email-auth-modal v-if="twoStep === 'EMAIL'" @close="twoStep = ''" @submit="submitWithdrawRequest"/>
      <set-sms-auth-modal v-else-if="twoStep === 'SMS'" @close="twoStep = ''" @submit="submitWithdrawRequest"/>
      <set-google-auth-modal v-else-if="twoStep === 'GOOGLE_AUTHENTICATOR'" @close="twoStep = ''"
                             @submit="submitWithdrawRequest"/>
    </transition>
  </div>
</template>

<script>

import WalletHistory from "./WalletHistory";
import WithdrawModal from "./WithdrawModal";
import WithdrawModalRial from "./WithdrawModalRial";
import DepositModal from "./DepositModal";
import DepositModalRial from "./DepositModalRial";
import MyWallet from "./MyWallet";
import SetGoogleAuthModal from "@/components/UserAccount/setGoogleAuthModal";
import SetSmsAuthModal from "@/components/UserAccount/setSmsAuthModal";
import SetEmailAuthModal from "@/components/UserAccount/setEmailAuthModal";

export default {
  name: "Wallet",
  components: {
    SetEmailAuthModal,
    SetSmsAuthModal,
    SetGoogleAuthModal, MyWallet, DepositModalRial, DepositModal, WithdrawModalRial,
    WithdrawModal, WalletHistory
  },
  props: {
    tab:
        {
          default: 'withdraw'
        },
    id: {
      default: 1
    }
  },
  data() {
    return {
      showWithModal: false,
      showDepModal: false,
      showRWithModal: false,
      showRDepModal: false,
      destination: {
        wallet: '',
        relatedCoin: ''
      },
      type: 'P2P',
      wallets: [],
      relatedCoin: '',
      cash: 0,
      twoStep: '',
      transaction: {},
      wage: '',
      coins: [],
      role: '',
    }
  },
  methods: {
    routing(e) {
      this.$router.replace({params: {tab: e, id: 1}})
    },
    depositModal(e) {
      this.destination.wallet = e[1]
      this.destination.relatedCoin = e[0]
      if (e[0] !== 'TOMAN') {
        this.showDepModal = true
      } else {
        this.showRDepModal = true
      }
    },
    withdrawModal(e) {
      this.destination.wallet = e[1]
      this.destination.relatedCoin = e[0]
      if (e[0] !== 'TOMAN') {
        this.showWithModal = true
      } else {
        this.showRWithModal = true
      }
    },
    async submitWithdrawRequest(code) {
      this.state.loading = true
      this.transaction.code = code
      const [res,] = await this.$http.post(`/wallets/withdrawal-request`, this.transaction)
      if (res) {
        await this.$error('Request successfully created', '', 'success')
        ++this.state.requestChanged
        this.closemodal()
      }
    },
    closemodal() {
      this.showWithModal = false
      this.showDepModal = false
      this.showRWithModal = false
      this.showRDepModal = false
      this.twoStep = ''
    },
    openTwoStepModal(e) {
      this.transaction = e[0]
      this.twoStep = e[1]
    },
    async getData() {
      this.loading = true
      const res = await this.$axios.get('/coins/details')
      this.coins = res.content
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>

.main-box {
  background-color: transparent;
  text-align: right;
  padding: 0 5px;
  box-sizing: border-box;
  width: 100%;
  max-width: 1170px;
}


</style>
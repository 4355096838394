<template>
    <div class="box1">
        <over-loading v-if="loading"/>
        <perfect-scrollbar :options="settings">
            <table>
                <tr class="table-header">
                    <th>تاریخ</th>
                    <th>بازار</th>
                    <th class="mobilehidden">نوع</th>
                    <th class="mobilehidden">قیمت واحد ({{$coins[$route.params.tradeTo].persianName}})</th>
                    <th class="mobilehidden">مقدار</th>
                    <th>قیمت کل ({{$coins[$route.params.tradeTo].persianName}})</th>
                    <th>وضعیت</th>
                </tr>
                <tr v-if="!tableContents.length">
                    <td colspan="7">
                        <p style="color:rgb(185 179 179);text-align:center;width:100%"> سفارشی ثبت نشده است.</p>
                    </td>
                </tr>
                <tr v-for="(tableContent,index) in tableContents" :key="index"
                    class="table-row">
                    <td class="mobilehidden">{{$G2J(tableContent.createdAtDateTime,'faDateTime')}}</td>
                    <td class="mobileshow">{{$G2J(tableContent.createdAtDateTime,'faDate')}}</td>
                    <td>{{$coins[$route.params.tradeFrom].persianName}} - {{$coins[$route.params.tradeTo].persianName}}</td>
                    <td class="mobilehidden"
                        :style="{color:[tableContent.orderType.includes('BUY') ? 'var(--main-green)':'var(--main-red)']}">
                        {{tableContent.orderType.includes('LIMITED')?'حد': 'بازار'}} -
                        {{tableContent.orderType.includes("SELL")?'فروش':'خرید'}}
                    </td>
                    <td class="mobilehidden">{{$toLocal(tableContent.unitPrice)}}</td>
                    <td class="mobilehidden">{{$toLocal(tableContent.amount)}}</td>
                    <td class="mobilehidden">{{$toLocal(tableContent.wholePrice)}}</td>
                    <td class="mobileshow"
                        :style="{color:[tableContent.orderType.includes('BUY') ? 'var(--main-green)':'var(--main-red)']}">
                        {{$toLocal(tableContent.wholePrice)}}
                    </td>
                    <td v-if="tableContent.orderStatus === 'FINISHED'" style="color:var(--main-green)">انجام شده</td>
                    <td v-else style="color:var(--main-red)">انجام نشده</td>
                </tr>
            </table>
        </perfect-scrollbar>
    </div>
</template>


<script>
    import OverLoading from "@/components/over-loading";

    export default {
        name: "LastOrder",
        components: {OverLoading},
        data() {
            return {
                modalshow: false,
                removeid: 0,
                tableContents: [
                    /*{
                        orderType: '',
                        amount: 0,
                        unitPrice: 0,
                        wholePrice: 0,
                        executedPercent: 0,
                        id: 0,
                        createdAtDateTime: ''
                    }*/
                ],
                settings: {
                    suppressScrollX: true
                },
                loading: false,
                alive: false,
                loopIsAlive: false,
            }
        },
        methods: {
            async getLastOrders() {
                let res = await this.$axios.get('/orders', {
                    params: {
                        market: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo,
                        page: 1,
                        size: 20
                    }
                }).catch(()=>{})
                if (res && res.content){
                    res.content = res.content.filter(i => i.orderStatus !== 'IS_OPEN');
                    this.tableContents = res.content || [];
                }
            },
            async loop() {
                // this.state.loading = true
                this.loading = true

                while (this.alive) {
                    this.loopIsAlive = true
                    await this.getLastOrders()
                    this.loading = false
                    await this.$sleep(5000)
                }
                this.loopIsAlive = false
            }
        },
        async mounted() {
            this.alive = true
            this.loading = true
            if (this.state.loop) {
                this.loop()
            } else {
                await this.getLastOrders()
                this.loading = false
            }
        },
        beforeDestroy() {
            this.alive = false
        }

    }
</script>

<!--<style src="../../../node_modules/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>-->
<style scoped>
    .ps {
        height: 100%;
        width: 100%;
    }

    .box1 {
        flex-direction: column;
        width: 100%;
        height: calc(100% - 30px);
        font-size: 12px;
        /*height: 100%;*/
        display: block;
        align-items: center;
        padding: 10px 0;
        position: relative;
        direction: ltr;
    }

    /*@media (max-width: 992px) {*/
    /*    .ps {*/
    /*        height: calc(260px + (455px - 290px));*/
    /*    }*/

    /*    .box1 {*/
    /*        height: 455px;*/
    /*    }*/
    /*}*/

    table {
        direction: rtl;
        align-items: center;
        width: 100%;
        border-collapse: collapse;
        /*min-width: 600px;*/
        transform: scale(1);
    }

    .table-header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: white;
        padding: 10px 0;
        margin: 0 20px;
        text-align: center;
        height: 40px;
        z-index: 1;
    }

    .dark .table-header {
        background-color: var(--dark-background);
    }

    .dark a {
        color: white;
    }

    .table-header th,
    .table-row td {
        opacity: 0.8;
    }

    /*.table-header div:first-of-type {*/
    /*    min-width: 72px;*/
    /*}*/

    /*.table-header div:last-of-type {*/
    /*    min-width: 72px;*/
    /*    padding-right: 20px;*/
    /*    text-align: right;*/
    /*}*/


    .table-row {
        position: relative;
        align-items: center;
        padding: 7px 0;
        width: 100%;
        text-align: center;
        cursor: default;
        transition: background-color 0.2s;
        height: 30px;
    }

    .table-row:hover {
        background-color: var(--hover);
    }

    .table-row td:first-of-type,
    .table-header th:first-of-type {
        text-align: right;
        padding-right: 10px;
        /*min-width: 72px;*/
    }

    .table-row td:last-of-type,
    .table-header th:last-of-type {
        text-align: left;
        padding-left: 10px;
        /*min-width: 72px;*/
    }

    .table-header, .table-row {
        min-width: 450px;
    }

    .cancel a {
        color: var(--font-hover);
        text-decoration: underline;
        cursor: pointer;
    }

    .deactive a {
        color: var(--font-hover);
        text-decoration: underline;
        cursor: default;
        opacity: 0.5;
    }

    /*.trash {*/
    /*    cursor: pointer;*/
    /*    position: absolute;*/
    /*    top: 50%;*/
    /*    left: 0;*/
    /*    transform: translate(0, -50%);*/
    /*}*/
    .mobilehidden {
        display: table-cell;
    }

    .mobileshow {
        display: none
    }

    @media (max-width: 580px) {
        .mobilehidden {
            display: none;
        }

        .mobileshow {
            display: table-cell;
        }
    }


</style>
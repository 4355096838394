<template>
    <div class="main-box">
        <h1>تاریخچه</h1>
        <div class="tab">
            <div :class="[tab !=='buy' ? 'tab-content' : 'tab-content-active']"
                 @click.prevent="routing('buy')">
                <a>معاملات خرید</a>
            </div>
            <div :class="[tab !=='sell' ? 'tab-content' : 'tab-content-active']"
                 @click.prevent="routing('sell')">
                <a>معادلات فروش</a>
            </div>
            <div :class="[tab !== 'login' ? 'tab-content' : 'tab-content-active']"
                 @click.prevent="routing('login')">
                <a>لاگین</a>
            </div>
            <div class="selector" :style="{right :[tab==='buy'? '' : [tab==='sell'? '33%' : '66%']]}"></div>
        </div>
        <div class="box1">
            <buy-table v-if="tab=== 'buy'" :id="id"></buy-table>
            <sell-table v-if="tab === 'sell'" :id="id"></sell-table>
            <login-table v-if="tab === 'login'" :id="id"></login-table>
        </div>
    </div>
</template>

<script>
    import LoginTable from "./LoginTable";
    import BuyTable from "./BuyTable";
    import SellTable from "./SellTable";

    export default {
        name: "History",
        components: {SellTable, BuyTable, LoginTable},
        props: {
            tab:
                {
                    default: 'buy'
                },
            id:{
                default : 1
            }
        },
        data() {
            return {
                login: false,
                buy: true,
                sell: false
            }
        },
        methods:{
            routing(e){
                this.$router.push({ name: 'History' , params: { tab: e , id : 1} })
            }
        }
    }
</script>

<style scoped>

    .main-box {
        background-color: transparent;
        text-align: right;
        margin-top: 30px;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1170px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        font-size: 14px;
    }

    h1 {
        font-size: 30px;
        margin-top: 0;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .tab {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 60px;
        /*border-bottom: #00000010 1px solid;*/
        font-size: 18px;
        align-items: center;
        background-color: transparent;
        text-align: right;
        width: 100%;
        margin-top: 10px;

    }

    .tab-content {
        width: 33%;
        opacity: 0.5;
        text-align: center;
        cursor: pointer;
    }

    .tab-content-active {
        font-weight: 800;
        width: 33%;
        opacity: 0.8;
        text-align: center;
    }

    .selector {
        position: absolute;
        background-color: var(--main-orange);
        width: 34%;
        height: 6px;
        border-radius: 5px;
        bottom: -3px;
        right: 0;
        transition: 0.5s;
    }


    .box1 {
        flex-direction: column;
        margin-top: 30px;
        background-color: white;
        width: 100%;
    }
</style>
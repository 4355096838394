import Vue from 'vue';
Vue.use(require('vue-cookies'))

const that = Vue.prototype

const state = Vue.observable({
    token: localStorage.token || Vue.$cookies.get('--abcd14589') || false,
    theme: localStorage.theme || 'dark',
    loading: false,
    gotten: false,
    loop: process.env.NODE_ENV !== 'development',
    time: 5000,
    orderChanged:0,
    requestChanged:0,
    progress:0,
    messages:0,
    coins:false,

    userInfo: {
        firstName: '',
        lastName: '',
        email: '',
        remember: false,
        towStepAuthenticationType: '',
        authenticationType: '',
        authorized: false,
        pending: false,
        supervisor: false,
        userAccountLevel: '',
        totalTradesVolume: 0,
        fee: 0.4,
    },

    shopInfo: {
        minSell: 0,
        maxBuy: 0,
        lastPrice: 0,
    },

    buyShop:0,
    sellShop:0,
    buyAmount:0,
    buyPrice:0,
    sellAmount:0,
    sellPrice:0
})

that.state = state
export default state
<template>
    <div class="main">
        <div class="contents"
             @click="$router.push(contents[0].link)">
            <svg :style="{fill:[route === contents[0].route ? 'var(--main-orange)' : '']}"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="25px">
                <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/>
                <circle v-if="messages && messages.content && messages.content.length" fill="var(--main-orange)" cx="16" cy="6" r="4px"/>
            </svg>
        </div>
        <div class="drop_btn">
            <a :style="{color : ['Profile','Setting','Wallet','History','Invite'].includes(route)? 'var(--main-orange)' : ''}">
                {{state.userInfo.authenticationType === 'UNAUTHORIZED' ? state.userInfo.email : state.userInfo.firstName}}
            </a>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 :class="[['Profile','Setting','Wallet','History','Invite'].indexOf(route)>-1? 'selectedsvg' : '']"
                 viewBox="0 0 494.148 494.148" width="8px" style="margin: 9px 5px 0" xml:space="preserve">
                        <path d="M405.284,201.188L130.804,13.28C118.128,4.596,105.356,0,94.74,0C74.216,0,61.52,16.472,61.52,44.044v406.124
                        c0,27.54,12.68,43.98,33.156,43.98c10.632,0,23.2-4.6,35.904-13.308l274.608-187.904c17.66-12.104,27.44-28.392,
                        27.44-45.884 C432.632,229.572,422.964,213.288,405.284,201.188z"/>
            </svg>
            <div class="dropbox">
                <div class="dropbox1">
                    <div v-for="(content,index) in contents"
                         :key="index" v-show="index>0"
                         class="dropbox-contents"
                         @click="Route(content.link)">
                        <div :class="route === content.route? 'selected' : ''" class="contents">
                            <img class="icon" :src="content.img" :alt="'image'+index">
                            {{content.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <svg v-show="theme === 'dark' && route==='Trade'" @click.prevent="changetheme('')" version="1.1" id="Layer_1"-->
        <!--             xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"-->
        <!--             viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" width="30px" height="30px"-->
        <!--             xml:space="preserve">-->
        <!--            <path d="M26,8.1V16l2,1.2c2.5,1.4,4,4.1,4,6.9c0,4.4-3.6,8-8,8s-8-3.6-8-8c0-2.8,1.5-5.5,4-6.9l2-1.2V8.1H26 M30,4.1H18v9.6-->
        <!--            c-3.6,2.1-6,5.9-6,10.4c0,6.6,5.4,12,12,12s12-5.4,12-12c0-4.4-2.4-8.3-6-10.4C30,13.7,30,4.1,30,4.1z"/>-->
        <!--        </svg>-->
        <!--        <svg v-show="theme !== 'dark' && route==='Trade'" @click.prevent="changetheme('dark')"-->
        <!--             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="30px" height="30px">-->
        <!--            <path d="M3.55 19.09l1.41 1.41 1.79-1.8-1.41-1.41zM11 20h2v3h-2zM1 11h3v2H1zm12-6.95v3.96l1 .58c1.24.72 2 2.04 2 3.46 0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.42.77-2.74 2-3.46l1-.58V4.05h2m2-2H9v4.81C7.21 7.9 6 9.83 6 12.05c0 3.31 2.69 6 6 6s6-2.69 6-6c0-2.22-1.21-4.15-3-5.19V2.05zM20 11h3v2h-3zm-2.76 7.71l1.79 1.8 1.41-1.41-1.8-1.79z"/>-->
        <!--        </svg>-->
    </div>
</template>

<script>
    export default {
        name: "account-active",
        props: ['route'],
        data() {
            return {
                open: false,
                theme: '',
                contents: [
                    {
                        name: 'اعلانات',
                        link: '/messages',
                        img: require('../../assets/icon/bell.svg'),
                        route: 'Messages'
                    },
                    {
                        name: 'حساب کاربری',
                        link: '/profile',
                        img: require('../../assets/icon/name.svg'),
                        route: 'Profile'
                    },
                    {
                        name: 'امنیت',
                        link: '/setting',
                        img: require('../../assets/icon/shield.svg'),
                        route: 'Setting'
                    },
                    {
                        name: 'کیف پول',
                        link: '/wallet',
                        img: require('../../assets/icon/wallet.svg'),
                        route: 'Wallet'
                    },
                    {
                        name: 'تاریخچه',
                        link: '/history',
                        img: require('../../assets/icon/history.svg'),
                        route: 'History'
                    },
                    {
                        name: 'دعوت دوستان',
                        link: '/invite',
                        img: require('../../assets/icon/invite.svg'),
                        route: 'Invite'
                    },
                    {
                        name: 'خروج',
                        link: '/sign-in',
                        img: require('../../assets/icon/power.svg'),
                    },
                ],
                messages: {
                    content: []
                }

            }
        },
        watch: {
            $route() {
                this.getMessages()
            }
        },
        methods: {
            Route(e) {
                if(e === '/sign-in'){
                    this.$logout()
                }
                this.$router.push(e)
            },
            changetheme(e) {
                localStorage.theme = e;
                this.state.theme = localStorage.theme
            },
            async getMessages() {
                this.messages = await this.$axios.get('/users/notifications', {
                    params: {
                        read: false,
                        size: 100
                    }
                })
            }
        },
        created() {
            this.getMessages()
        }
    }
</script>

<style scoped>
    .dark .dropbox1 svg {
        fill: white;
    }

    .dark .dropbox1 img {
        filter: invert(98%) sepia(100%) saturate(7%) hue-rotate(155deg) brightness(102%) contrast(106%);
    }

    .main {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        /*width: 200px;*/
    }

    .drop_btn {
        position: relative;
        cursor: pointer;
        width: max-content;
        height: 30px;
        margin: 0 10px;
    }

    .drop_btn a {
        color: white;
    }

    .drop_btn:hover a {
        color: var(--main-orange);
    }

    .drop_btn svg {
        fill: white;
        transform: rotate(90deg);
        transition: 0.3s;
    }

    .drop_btn:hover svg {
        fill: var(--main-orange);
        transform: rotate(270deg);
    }

    .dropbox {
        margin-top: -10px;
        padding-top: 28px;
        transform: translateX(25%);
        font-size: 14px;
        position: absolute;
        display: none;
        transition: 0.5s;
        z-index: 6;
    }

    .dropbox1 {
        color: var(--font-color);
        background-color: white;
        text-align: right;
        width: 130px;
        border-radius: 5px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        transition: 0.5s;
        z-index: 6;
    }

    .dropbox-contents {
        padding: 8px 8px;
        padding-left: 10px;
        transition: 0.25s;
        cursor: pointer;
        position: relative;
    }

    .contents {
        width: max-content;
        display: flex;
        align-items: center;
        color: var(--font-color);
    }

    .contents img {
        margin-left: 10px;
        /*filter: invert(100%)*/
    }

    .contents svg {
        margin-left: 10px;
        fill: white;
    }

    .selected {
        color: var(--main-orange) !important;
        /*height: 30px;*/
        /*border-bottom: var(--main-orange) solid 2px;*/
        cursor: default;
    }

    .selected img {
        filter: invert(90%) sepia(22%) saturate(5925%) hue-rotate(354deg) brightness(95%) contrast(96%);
    }

    .selected svg {
        fill: var(--main-orange)
    }

    .icon {
        filter: invert(2%) sepia(7%) saturate(7498%) hue-rotate(186deg) brightness(95%) contrast(87%);
    }

    .selected .icon {
        filter: invert(90%) sepia(22%) saturate(5925%) hue-rotate(354deg) brightness(95%) contrast(96%);
    }

    .dropbox-contents:hover {
        background-color: var(--main-hover);
    }

    .dropbox-contents:hover .contents {
        color: var(--main-orange);
    }

    .dropbox-contents:hover .contents img {
        filter: invert(90%) sepia(22%) saturate(5925%) hue-rotate(354deg) brightness(95%) contrast(96%);
    }

    .dropbox-contents:last-of-type {
        border-top: solid 2px rgba(0, 0, 0, 0.1);
    }

    .dark .dropbox-contents {
        border-color: rgba(255, 255, 255, 0.1);
    }

    .drop_btn:hover .dropbox {
        display: block;
    }

    .icon {
        margin-left: 7px;
    }

    svg {
        margin: 0 25px;
        opacity: 0.8;
    }

    svg:first-of-type {
        margin-right: 10px;
    }

    svg:last-of-type {
        margin-left: 10px
    }

    svg:hover {
        fill: var(--main-orange);
        cursor: pointer;
    }

    .selected {
        color: var(--main-orange);
    }

    .selected img {
        filter: invert(90%) sepia(22%) saturate(5925%) hue-rotate(354deg) brightness(95%) contrast(96%);
    }

    .dark .dropbox1 {
        background-color: var(--dark-background);
        color: white !important;
    }

    .dark .contents {
        color: white;
    }

    .dark .dropbox1 {
        border-radius: 0;
    }

    .dark .dropbox-contents:hover .contents {
        color: var(--main-orange);
    }

    .dark .dropbox-contents:hover .contents img {
        filter: invert(90%) sepia(22%) saturate(5925%) hue-rotate(354deg) brightness(95%) contrast(96%);
    }

    .selectedsvg {
        fill: var(--main-orange) !important;
    }


</style>
<template>
  <div class="box1 row no-gutters">
    <over-loading v-if="loading"/>
    <div class="col-6 col-sm-4 col-md-3 pr-2 order-first header">
      <h1 style="font-size: 14px">بازار {{ $coins[tradeFrom].persianName }} -
        {{ $coins[tradeTo].persianName }}
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
              viewBox="0 0 494.148 494.148" width="8px" style="margin: 0 3px" xml:space="preserve">
                        <g>
                            <path d="M405.284,201.188L130.804,13.28C118.128,4.596,105.356,0,94.74,0C74.216,0,61.52,16.472,61.52,44.044v406.124
                        c0,27.54,12.68,43.98,33.156,43.98c10.632,0,23.2-4.6,35.904-13.308l274.608-187.904c17.66-12.104,27.44-28.392,
                        27.44-45.884 C432.632,229.572,422.964,213.288,405.284,201.188z"/>
                        </g>
        </svg>
      </h1>
      <real-price class="box"/>
    </div>
    <div class="col-6 col-sm-4 col-md-2 pr-2 pr-md-0 touch-full">
      <a>بیشترین</a>
      <a style="color: var(--main-green)">{{ $toLocal(data.maxPrice) }}
        <a style="color: var(--main-green)">{{ $coins[tradeTo].persianName }}</a>
      </a>
    </div>
    <div class="col-6 col-sm-4 col-md-2 pr-2 pr-md-0 touch-full">
      <a>کمترین</a>
      <a style="color: var(--main-red)">{{ $toLocal(data.minPrice) }}
        <a style="color: var(--main-red)">{{ $coins[tradeTo].persianName }}</a>
      </a>
    </div>
    <div class="col-6 col-sm-4 col-md-2 pr-2 pr-md-0 touch-full">
      <a>آخرین</a>
      <a>{{ $toLocal(data.lastPrice) }}
        <a>{{ $coins[tradeTo].persianName }}</a>
      </a>
    </div>
    <div class="col-6 col-sm-4 col-md-2 pr-2 pr-md-0 touch-full">
      <a>حجم</a>
      <a>{{ $toLocal(data.last24HourVolume) }}
        <a>{{ $coins[tradeTo].persianName }}</a>
      </a>
    </div>
    <div class="col-6 col-sm-4 col-md-1 pr-2 pr-md-0 order-first order-sm-last touch-full">
      <a>تغییرات</a>
      <a style="direction: ltr;justify-content: right"
         :style="{color: data.last24HourChange>0 ?'var(--main-green)':'var(--main-red)'}">
        ٪ {{ data.last24HourChange.toFixed(2) }}
      </a>
    </div>
  </div>
</template>

<script>

import RealPrice from "@/components/Trade/RealPrice/RealPrice";
import OverLoading from "@/components/over-loading";
import {tradeTo,tradeFrom} from "lib/reuseableFunction";

export default {
  name: "TradeHeader",
  components: {RealPrice, OverLoading},
  data() {
    return {
      loading: false,
      alive: false,
      data: {
        maxPrice: 0,
        minPrice: 0,
        lastPrice: 0,
        last24HourVolume: 0,
        last24HourChange: 0,
      },
    }
  },
  computed:{
    tradeTo,tradeFrom
  },
  methods: {
    async getTradeHeader() {
      const res = await this.$axios.get('/trades/market-info', {
        params: {
          weeklyDataIncluded: false,
          market_type: this.tradeFrom + '_' + this.tradeTo
        }
      }).catch(() => {
      })
      if (res) {
        this.data = res.content[0] || this.data
        this.state.shopInfo.lastPrice = this.data.lastPrice
      }
    },
    async loop() {
      this.state.loading = true
      this.loading = true

      while (this.alive) {
        await this.getTradeHeader()
        this.loading = false
        await this.$sleep(5000)
      }
    }
  },
  mounted() {
    this.alive = true
    if (this.state.loop) {
      this.loop()
    } else {
      this.getTradeHeader()
    }
  },
  beforeDestroy() {
    this.alive = false
  },

}
</script>

<style scoped>

.box1 {
  background-color: white;
  direction: rtl;
  text-align: right;
  min-height: 50px;
  width: 100%;
  color: var(--font-color);
  max-width: 820px;
}

.dark .box1 {
  background-color: var(--dark-background);
  color: white;
}

.box1 div {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  /*align-items: center;*/
  /*align-content: center;*/
  justify-content: space-around;
  /*vertical-align: center;*/
}

a {
  display: flex;
  align-items: center;
  color: var(--font-color);
  font-size: 12px;
}

.dark a {
  color: white;
}

.box1 div a a {
  font-size: 12px;
  margin-right: 4%;
}

.header {
  position: relative;
  flex-direction: row;
}

.header svg {
  fill: var(--font-color);
  transform: rotate(90deg);
  transition: 0.3s;
  /*transform-origin: center;*/
}

.dark .header svg {
  fill: white;
}

.box {
  position: absolute;
  top: 100%;
  background-color: white;
  display: none !important;
  z-index: 2;
  width: 250px;
  right: 0;
}

.dark .box {
  background-color: var(--dark-background);
}

.header:hover h1 {
  color: var(--main-orange);
  cursor: default;
}

.header:hover .box {
  display: flex !important;
}

.header:hover svg {
  fill: var(--main-orange);
  transform: rotate(270deg);
}

.touch .header {
  display: none;
}

.touch .touch-full {
  flex: 0 0 95%;
  max-width: none;
  width: 95%;
  font-size: 14px;
  height: calc(100vh / 7);
  max-height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.touch .touch-full a {
  font-size: 14px;
}


</style>
<template>
    <div class="box-sub">
        <a>اطلاعات بانکی</a>
        <button @click.prevent="addaccount" class="normal-btn">افزودن حساب جدید</button>
        <form>
            <div class="accountlist row no-gutters" v-for="(bankAccount,index) in data.bankAccounts" :key="index">
                <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                    <label class="col-4 pl-1" :for="'shaba'+index">شماره شبا</label>
                    <input class="col-12"
                           @input="bankAccount.shebaCode = bankAccount.shebaCode.replace(/[^\d.]|\.\./ig,'');error='';"
                           type="text" style="padding-left: 30px;text-align: left"
                           v-model="bankAccount.shebaCode"
                           :name="'shaba'+index" maxlength="24"
                           :id="'shaba'+index">
                    <p class="shaba">-IR</p>
                    <p class="error" v-if="error.includes(bankAccount.shebaCode) && bankAccount.shebaCode !== ''">شبا
                        تکراریست</p>
                </div>
                <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                    <label class="col-4 pl-1" :for="'cardnumber'+index">شماره کارت</label>
                    <input @input="dash(index)"
                           class="col-12" type="text" style="text-align: left"
                           v-model="bankAccount.cardNumber"
                           :name="'cardnumber'+index"
                           :id="'cardnumber'+index" maxlength="19" minlength="19">
                    <p class="error" v-if="error.includes(bankAccount.cardNumber) && bankAccount.cardNumber !== ''">
                        شماره کارت تکراریست</p>
                </div>
                <div class="row no-gutters col-6 col-sm-4 p-1 p-sm-3">
                    <label class="col-4 pl-1" :for="'bankname'+index">بانک</label>
                    <input class="col-12"
                           type="text"
                           v-model="bankAccount.bankName"
                           :name="'bankname'+index"
                           :id="'bankname'+index"
                           readonly>
                </div>
                <img @click.prevent="removeaccount(index)"
                     src="../../assets/icon/trash1.svg"
                     class="trash" alt=""
                     v-show="index>=1"
                     :id="'trash'+index">
            </div>
        </form>
    </div>
</template>

<script>
    const banks = {
        '603799': 'بانک ملی ایران',
        '589210': 'بانک سپه',
        '627648': 'بانک توسعه صادرات',
        '207177': 'بانک توسعه صادرات',
        '627353': 'بانک تجارت',
        '585983': 'بانک تجارت',
        '627961': 'بانک صنعت و معدن',
        '603770': 'بانک کشاورزی',
        '639217': 'بانک کشاورزی',
        '628023': 'بانک مسکن',
        '627760': 'پست بانک ایران',
        '502908': 'بانک توسعه تعاون',
        '627412': 'بانک اقتصاد نوین',
        '622106': 'بانک پارسیان',
        '639194': 'بانک پارسیان',
        '627884': 'بانک پارسیان',
        '502229': 'بانک پاسارگاد',
        '639347': 'بانک پاسارگاد',
        '639599': 'بانک قوامین',
        '504706': 'بانک شهر',
        '502806': 'بانک شهر',
        '603769': 'بانک صادرات',
        '610433': 'بانک ملت',
        '991975': 'بانک ملت',
        '589463': 'بانک رفاه',
        '502938': 'بانک دی',
        '639607': 'بانک سرمایه',
        '627381': 'بانک انصار',
        '505785': 'بانک ایران زمین',
        '636214': 'بانک آینده',
        '636949': 'بانک حکمت ایرانیان',
        '621986': 'بانک سامان',
        '639346': 'بانک سینا',
        '606373': 'بانک قرض الحسنه مهر',
        '627488': 'بانک کارآفرین',
        '502910': 'بانک کارآفرین',
        '505416': 'بانک گردشگری',
        '639370': 'بانک مهر اقتصاد',
        '628157': 'موسسه اعتباری توسعه',
        '505801': 'موسسه اعتباری کوثر',
    }
    export default {
        name: "BankInformation",
        props: ['data', 'error', 'change'],
        data() {
            return {}
        },
        methods: {
            addaccount() {
                this.data.bankAccounts = [
                    ...this.data.bankAccounts,
                    {
                        shebaCode: '',
                        cardNumber: '',
                        bankName: ''
                    }
                ]
            },
            removeaccount(e) {
                this.data.bankAccounts.splice(e, 1)
            },
            dash(e) {
                this.data.bankAccounts[e].bankName = banks[this.data.bankAccounts[e].cardNumber ? this.data.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
                this.data.bankAccounts[e].cardNumber = this.$separator(this.data.bankAccounts[e].cardNumber)
                this.error=''
            }
        },

        mounted() {
            // eslint-disable-next-line no-unused-vars
            for (let [index, item] of this.data.bankAccounts.entries()) {
                this.dash(index)
            }
        }
    }
</script>

<style scoped>
    .trash {
        cursor: pointer;
        position: absolute;
        bottom: 30.5px;
        left: -0.5%;
        transform: translate(0, 50%);
    }

    @media (max-width: 575px) {
        .trash {
            bottom: 19px;
            left: 10px;
        }
    }

    .normal-btn {
        font-size: 12px;
        position: absolute;
        left: 20px;
        width: 130px;
        margin: 0;
        margin-left: 1.3%;
    }

    .box-sub {
        font-family: IRANSans-Bold, sans-serif;
        position: relative;
        display: block;
        background-color: white;
        text-align: right;
        border-bottom: #00000015 solid 1px;
        padding: 10px 15px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
        color: var(--font-color);
    }

    form {
        font-family: VistaDev, sans-serif;
        position: relative;
        display: block;
        background-color: transparent;
        text-align: right;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    form div {
        width: 100%;
    }

    form div div {
        margin: 0 !important;
    }

    .box-sub label {
        display: flex;
        align-items: center;
        min-width: 84px;
    }

    .box-sub input {
        position: relative;
        /*border: solid rgba(54, 52, 53, 0.15) 0.5px;*/
        display: inline-block;
        height: 29px;
        border-radius: 5px;
        padding: 2px 5px;
        width: 100%;
        max-width: 66.666667%;
    }

    .error {
        color: red;
        font-size: 12px;
        position: absolute;
        top: auto;
        bottom: 0;
        left: auto;
        right: calc(33% + 1rem);
        transform: translateY(5px);
        margin: 0;
    }

    @media (max-width: 911px) {
        .box-sub input {
            max-width: 100%;
        }

        .error {
            bottom: auto;
            top: 0;
            left: 1rem;
            right: auto;
            transform: translateY(1rem);
            max-width: 55%;
            text-align: left;
        }
    }


    .accountlist {
        width: 100%;
        margin: 0;
        padding: 0;
        justify-content: flex-start;
        position: relative;
    }

    .accountlist div:last-of-type {
        display: flex;
        position: relative;
    }

    .shaba {
        color: var(--font-color);
        position: absolute;
        left: 1rem;
        bottom: calc(1rem + 4px);
        height: 20px;
        width: 20px;
        margin: 0;
    }

    @media (max-width: 575px) {
        .shaba {
            bottom: 8px;
            left: 8px;
        }

        .error {
            left: 5px;
            transform: translateY(5px);
            max-width: 55%;
        }
    }
</style>
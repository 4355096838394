<template>
    <div class="box-sub">
        <a style="align-self: start">بارگذاری مدارک</a>
        <div class="form">
            <p>
                جهت احراز هویت در سامانه ی ما لازم است تصویر سلفی خود را با کارت ملی تان و یک برگه با متن زیر ارسال نمایید .
                <br>
                اینجانب …. به کد ملی….ضمن مطالعه کامل قوانین سایت صرافی ویستا، جهت احراز هویت مدارک خودم را برای سایت صرافی ویستا ارسال کرده و متعهد میشوم حساب کاربری و بانکی خود را در اختیار اشخاص دیگری قرار نمیدهم و ارز دیجیتال خریداری شده را برای مصارف غیر قانونی استفاده نخواهم کرد و همه عواقب خلاف این اظهارات را در کلیه مراجع قضایی و اداری بر عهده میگیرم.
                <br>
                <br>
                تاریخ و امضا
            </p>
        </div>
        <div class="upload">
            <img class="img" src="../../assets/uploadimg.png" alt="">
            <form @submit.prevent="upload"
                  @dragover.prevent
                  @dragenter="hovered=!hovered"
                  @dragleave="hovered=!hovered"
                  @drop.stop.prevent="hovered=false;drop($event);"
                  :style="{borderColor : hovered? 'blue' : ''}">
                <img class="img" :src="path" alt="">
                <input type="file"
                       name="picture"
                       id="picture"
                       style="display: none"
                       @change="upload($event.target)"
                       :disabled="readonly"
                       v-if="!readonly"
                       required>
                <label for="picture" class="normal-btn" v-if="!readonly">{{loadedpicture}}% بارگذاری تصویر</label>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "UploadDocuments",
        props: ['data'],
        data() {
            return {
                path: '',
                loadedpicture:'',
                hovered:'',
            }
        },
        watch: {
            'data.image'() {
                if (this.data.image)
                    if (typeof this.data.image === 'string')
                        this.path = this.data.image
            }
        },
        computed:{
            readonly(){
                return ['AUTHORIZED',"INFORMATION_CHANGED","INFORMATION_CHANGED_PENDING"].includes(this.state.userInfo.authenticationType)
            }
        },
        methods: {
            drop(e){
                if(!this.readonly){
                    let dt = e.dataTransfer
                    this.upload(dt)
                }
            },
            upload(evt) {
                let file = evt.files[0]
                this.data.image = file
                let fileReader = new FileReader();
                fileReader.addEventListener("loadstart", (event)=>{
                    this.loadedpicture=((event.loaded/event.total)*100).toFixed(0);
                })
                fileReader.addEventListener("progress", (event)=>{
                    this.loadedpicture=((event.loaded/event.total)*100).toFixed(0);
                });
                fileReader.addEventListener('load', (event) => {
                    this.loadedpicture=((event.loaded/event.total)*100).toFixed(0);
                    this.path = event.target.result
                });
                fileReader.readAsDataURL(file)
            },
            hover(){
                this.hovered=true
            }
        }
    }
</script>

<style scoped>

    .box-sub {
        font-family: IRANSans-Bold, sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        text-align: right;
        /*border-top: #00000015 solid 1px;*/
        padding: 10px 15px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
        color: var(--font-color);
    }

    .form {
        font-family: VistaDev, sans-serif;
        text-align: right;
        margin: 20px 0;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    .form p {
        margin: 0 1.916%;
        text-align: justify;
    }

    .form div {
        display: inline-block;
        position: relative;
        box-sizing: inherit;
        width: 29.5%;
        margin: 20px 1.91%;
    }

    .upload {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        max-width: 820px;
        justify-content: space-between;
        /*align-items: center;*/
        margin: 20px 0;
        direction: ltr;
    }
    @media (max-width: 663px) {
        .upload {
            /*flex-wrap: nowrap;*/
            /*flex-direction: column;*/
            justify-content: center;
            /*align-items: center;*/
        }
    }

    .upload img {
        /*order: 0;*/
        max-height: 307px;
    }

    .upload form {
        width: 100%;
        max-width: 307px;
        height: 100%;
        min-height: 307px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        /*flex-wrap: wrap;*/
        border: dashed 3px #979797;
        position: relative;
        /*order: 1;*/
    }

    .upload form img {
        /*height: 100%;*/
        width: 100%;
        max-height: none !important;
    }

    .normal-btn {
        position: absolute;
        bottom: 0;
        font-size: 14px;
        text-align: center;
        width: 100%;
        min-width: 102px;
        max-width: 250px;
        margin-bottom: 20px;
    }

</style>
<template>
    <div class="box-sub">
        <a>تغییر رمز عبور</a>
        <form class="row no-gutters" @submit.prevent="submit" @input="checkPass">
            <div class="row no-gutters col-12 col-sm-4 p-2" :class="{danger:passError.length}">
                <label class="col-4 p-" for="oldpassword">رمز عبور فعلی</label>
                <input class="col"
                       type="password"
                       v-model="oldpassword"
                       name="oldpassword"
                       id="oldpassword">
                <a>{{passError}}</a>
            </div>

            <div class="row no-gutters col-12 col-sm-4 p-2" :class="{danger:pass1Error.length}">
                <label class="col-4" for="newpassword1">رمز عبور جدید</label>
                <input class="col"
                       type="password"
                       v-model="newpassword1"
                       name="newpassword1"
                       id="newpassword1">
                <a>{{pass1Error}}</a>

            </div>
            <div class="row no-gutters col-12 col-sm-4 p-2" :class="{danger:pass2Error.length}">
                <label class="col-4" for="newpassword2">تکرار رمز عبور</label>
                <input class="col"
                       type="password"
                       v-model="newpassword2"
                       name="newpassword2"
                       id="newpassword2">
                <a>{{pass2Error}}</a>
            </div>

            <button type="submit" class="normal-btn">تغییر رمز</button>
        </form>

    </div>
</template>

<script>
    export default {
        name: "ChangePassword",
        data() {
            return {
                oldpassword: '',
                newpassword1: '',
                newpassword2: '',
                passError: '',
                pass1Error: '',
                pass2Error: '',
            }
        },

        methods: {
            async submit() {
                let passSent = {
                    oldPassword: this.oldpassword,
                    password: this.newpassword1
                }

                await this.check2Pass()

                if (this.checkPass())
                    this.state.loading = true
                try {
                    const res = await this.$axios.post(`/users/change-password`, passSent)
                    if (res.message === "password changed successfully") {
                        this.oldpassword = ''
                        this.newpassword1 = ''
                        this.newpassword2 = ''
                        this.passError = ''
                        this.pass1Error = ''
                        this.pass2Error = ''
                        this.$error('رمز شما تغییر یافت', '', 'success')
                    }
                } catch (error) {
                    if (error.response.data.message === 'Old Password Is Incorrect') {
                        this.passError = 'رمز قبلی اشتباه است.'
                    }
                }
            },
            checkPass() {
                if (!this.oldpassword) {
                    this.passError = 'رمز فعلی اجباری';
                } else {
                    this.passError = ''
                }
                if (this.newpassword1) {
                    if (this.newpassword1.length < 8) {
                        this.pass1Error = 'حداقل 8 کاراکتر';
                    } else if (!this.validPass(this.newpassword1)) {
                        this.pass1Error = 'ترکیبی از حروف کوچک و بزرگ و عدد';
                    } else if (!this.newpassword2) {
                        this.pass1Error = ''
                        this.pass2Error = 'تکرار رمز اجباری';
                    } else if (this.newpassword2 !== this.newpassword1) {
                        this.pass1Error = ''
                        this.pass2Error = 'تکرار رمز مطابقت ندارد';
                    } else {
                        this.pass2Error = ''
                    }
                }
                if (!this.passError.length && !this.pass1Error.length && !this.pass2Error.length) {
                    return true;
                }
            },
            async check2Pass() {
                if (!this.$validPass(this.newpassword1) || this.newpassword1.length < 8) {
                    this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                    return Promise.reject('wrong pass : ' + this.oldpassword)
                }
                if (this.newpassword1 !== this.newpassword2) {
                    this.$error('اطلاعات وارد شده اشتباه است', 'تکرار رمز اشتباه است')
                    return Promise.reject('repeat failed : ' + this.newpassword1 + '!==' + this.newpassword2)
                }
            },
            validPass(password) {
                const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
                return re.test(password);
            }
        },
    }
</script>

<style scoped>
    .box-sub {
        font-family: IRANSans-Bold, sans-serif;
        display: block;
        background-color: white;
        text-align: right;
        /*border: #00000015 solid 0.5px;*/
        padding: 10px 15px;
        margin: 20px 0;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
        color: var(--font-color);
    }

    form {
        font-family: VistaDev, sans-serif;
        text-align: left;
        margin: 20px 0;
        border-radius: 5px;
        width: 100%;
        font-size: 14px;
    }

    .error {
        color: var(--main-red);
        transition: 0.5s;
    }

    form label {
        text-align: right;
        width: 100%;
        min-width: 80px;
    }

    form div {
        position: relative;
        box-sizing: inherit;
        width: 29.5%;
        margin: 10px 0;
    }

    .box-sub input {
        position: relative;
        /*border: solid rgba(54, 52, 53, 0.15) 0.5px;*/
        display: inline-block;
        width: 70%;
        min-width: 165px;
        height: 29px;
        border-radius: 5px;
        padding: 2px 5px;
        text-align: left;
        direction: ltr;
    }

    /*.box-sub input:hover {*/
    /*    border: solid rgba(54, 52, 53, 0.3) 0.5px;*/
    /*}*/

    /*.box-sub input:focus {*/
    /*    border: solid rgba(54, 52, 53, 0.5) 0.5px;*/
    /*}*/

    .danger input {
        border: solid var(--main-red) 1px;
    }

    .danger input:hover {
        border: solid var(--main-red) 1px;
    }

    .danger input:focus {
        border: solid var(--main-red) 1px;
    }

    .danger a {
        color: var(--main-red);
        position: absolute;
        bottom: -10px;
        left: 10px;
        font-size: 12px;
    }

    .danger label {
        color: var(--main-red);
    }

    .normal-btn {
        color: white;
        font-size: 14px;
        width: 30%;
        min-width: 137px;
        max-width: 300px;
        margin: 45px auto;
    }
</style>
<template>
    <div class="mini-loading">
        <img :width="width + 'px'" src="../assets/mini-loading.svg" alt="">
    </div>
</template>

<script>
    export default {
        name: "over-loading",
        props:{
            width:{
                default:40
            }
        }
    }
</script>

<style scoped>
    .mini-loading{
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--hover);
        backdrop-filter: blur(1px);
        z-index: 10;
        border-radius: var(--border-radius);
    }
</style>
<template>
    <div class="footer">
        <div class="footer-social">
            <div class="row mx-2 my-1 no-gutters">
                <div class="col-md-5 col-12 justify-content-center justify-content-md-start"
                     style="cursor: default;display: flex;align-items: center">
                    کلیه حقوق این سایت متعلق به شرکت ویستا می‌باشد.
                </div>
                <div class="col-md-7 col-12 social justify-content-md-end justify-content-center row no-gutters m-0 order-first order-sm-last">
                    <a class="test col-12 col-md-4">ویستا در شبکه های اجتماعی:</a>
                    <div class="social col-6 col-md-4 justify-content-around justify-content-sm-between">
                        <a v-if="info.twitter"
                           :href="info.twitter.includes('http')? info.twitter: `https://twitter.com/${info.twitter}`"
                           target="_blank">
                            <img src="../../assets/Twitter.png" alt="">
                        </a>
                        <a v-if="info.telegram"
                           :href="info.telegram.includes('http')? info.telegram: `https://telegram.org/${info.telegram}`"
                           target="_blank">
                            <img src="../../assets/Telegram.png" alt="">
                        </a>
                        <a v-if="info.linkedin"
                           :href="info.linkedin.includes('http')? info.linkedin: `https://linkedin.com/${info.linkedin}`"
                           target="_blank">
                            <img src="../../assets/Linkedin.png" alt="">
                        </a>
                        <a v-if="info.instagram"
                           :href="info.instagram.includes('http')? info.instagram: `https://instagram.com/${info.instagram}`"
                           target="_blank">
                            <img src="../../assets/Instagram.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "miniFooter",
        data() {
            return {
                home: true,
                logedin: false,
                info:{}
            }
        },
        methods: {
            twitter() {
                // window.location.href = 'https://twitter.com';
                window.open(
                    'https://twitter.com',
                    '_blank' // <- This is what makes it open in a new window.
                );
            },
            instagram() {
                window.open(
                    'http://instagram.com',
                    '_blank' // <- This is what makes it open in a new window.
                );
            },
            linkedin() {
                window.open(
                    'https://linkedin.com',
                    '_blank' // <- This is what makes it open in a new window.
                );
            },
            telegram() {
                window.open(
                    'https://telegram.org',
                    '_blank' // <- This is what makes it open in a new window.
                );
            }
        },
        async created() {
            this.state.loading = true
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO
        }
    }
</script>

<style scoped>
    .footer {
        display: flex;
        flex-direction: column;
        background-color: var(--light-background);
        align-items: center;
        z-index: 1;
        position: relative;
    }

    .footer-social {
        width: 100%;
        max-width: 1070px;
        opacity: 0.8;
        font-family: IRANSans,sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        justify-content: center;
    }

    .social {
        display: flex;
    }

    .social a {
        color: white;
        cursor: default;
        vertical-align: super;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .social .social a{
        background-color: transparent;
        border-radius: 50%;
        height: 35px;
        transition: 0.5s;
        cursor: pointer;
    }
    .social .social a img{
        width: 35px;
    }

    .social .social a:hover {
        background-color: var(--main-orange);
    }
    @media (max-width: 574px){
        .footer-list:last-of-type{
            text-align: center;
        }
        .test{
            display: none;
        }

    }
</style>
<template>
    <div class="home">
        <div class="back"></div>
        <div class="site-header">
            <div class="header-detail">
                <h1>صرافی صرافی ویستا</h1>
                <h2>امن‌ترین مکان برای خرید، فروش و مدیریت رمزارز های شما</h2>
            </div>
            <div class="input">
                <input v-if="!state.token" type="email" name="email" v-model="email" placeholder="ایمیل">
                <button class="normal-btn" @click="savetolocal">همین حالا شروع کنید</button>
            </div>
        </div>
        <div class="chart1">
            <h2>محاسبه گر قیمت</h2>
            <calculator :toman.sync="toman" :calcoin="calcoin"/>
        </div>
        <div class="chart1">
            <div class="table">
                <crypto-rial-chart :max="6" @get-data="calcoin = $event" :active="toman"/>
                <a class="more" @click="$router.push('/trade')">مشاهده تمام بازار ها
                    <svg height="8px" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         viewBox="0 0 443.52 443.52" xml:space="preserve">
                                <path d="M143.492,221.863L336.226,29.129c6.663-6.664,6.663-17.468,0-24.132c-6.665-6.662-17.468-6.662-24.132,0l-204.8,204.8 c-6.662,6.664-6.662,17.468,0,24.132l204.8,204.8c6.78,6.548,17.584,6.36,24.132-0.42c6.387-6.614,6.387-17.099,0-23.712 L143.492,221.863z"/>
                    </svg>
                </a>
            </div>
        </div>
        <div class="chart2">
            <div class="gallery-main">
                <h2>آخرین اخبار و بلاگ</h2>
                <home-gallery style="margin: 50px 0;"/>
                <a class="more" href="/blog">مشاهده بلاگ</a>
            </div>
        </div>
        <div class="benefit">
            <div class="box">
                <div class="benefit-header">
                    <h2>امکانات و ویژگی ها</h2>
                </div>
                <div class="row no-gutters benefit-detail">
                    <div class="col-md-auto col-sm-6 col-12 p-3">
                        <img src="../../assets/Tradex/Home/fullsupport.png" alt="">
                        <h3>پشتیبانی ۲۴ ساعته</h3>
                    </div>
                    <div class="col-md-auto col-sm-6 col-12 p-3">
                        <img src="../../assets/Tradex/Home/invite.png" alt="">
                        <h3> کسب درآمد از طریق دعوت دوستان</h3>
                    </div>
                    <div class="col-md-auto col-sm-6 col-12 p-3">
                        <img src="../../assets/Tradex/Home/lowfee.png" alt="">
                        <h3> کارمزد بسیار پایین معاملات</h3>
                    </div>
                    <div class="col-md-auto col-sm-6 col-12 p-3">
                        <img src="../../assets/Tradex/Home/fasttrans.png" alt="">
                        <h3>واریز سریع وجه</h3>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="site-footer">-->
        <!--            <div class="footer-box">-->
        <!--                <div class="main">-->
        <!--                    <h2>اپلیکیشن صرافی ویستا</h2>-->
        <!--                    <p>خرید و فروش بیت کوین و سایر ارزهای دیجیتال را به راحتی در گوشی خود داشته باشید. همیشه و همه جا-->
        <!--                        معامله کنید.</p>-->
        <!--                    <div class="app-btn">-->
        <!--                        <div class="g-app-btn">-->
        <!--                            <img src="../../assets/IT Arz/Home/google-play.png" alt="">-->
        <!--                            <a>دریافت نسخه اندروید از</a>-->
        <!--                            <h3>گوگل پلی</h3>-->
        <!--                        </div>-->
        <!--                        <div class="a-app-btn">-->
        <!--                            <img src="../../assets/IT Arz/Home/۲ copy.png" alt="">-->
        <!--                            <a>دریافت نسخه اندروید از</a>-->
        <!--                            <img src="../../assets/IT Arz/Home/۱ copy.png">-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <img src="../../assets/IT Arz/Home/mobile-1256.png" alt="">-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>

    import HomeGallery from "@/components/Home/HomeGallery";
    import CryptoRialChart from "@/components/Tools/CryptoRialChart";
    // import DropDown from "@/components/Home/dropDown";
    import Calculator from "components/Home/calculator";

    export default {
        name: "Home",
        components: {
            Calculator,
            // DropDown,
            CryptoRialChart,
            HomeGallery
        },
        data() {
            return {
                userscount: '0',
                tradprice: '0',
                totaltrad: '0',
                email: '',
                currentposition: '',
                benefitposition: '',
                calcoin: [],
                calcname: 'بیتکوین',
                calcunit: 0,
                calcTunit: 10,
                toman: true,
                price: '',
                amount: ''
            }
        },
        methods: {
            savetolocal() {
                localStorage.email = this.email
                if(this.state.token){
                    this.$router.push('/trade')
                }else{
                    this.$router.push('/sign-up')
                }
            },
            setcoin(e) {
                this.calcname = e ? (this.$coins[e.relatedCoin].persianName) : 'رمزارز'
                this.calcunit = e ? (e.lastPrice || 0) : 0
                this.calprice()
            },
            exchange() {
                this.toman = !this.toman;
                this.calprice()
            }
        }

    }
</script>

<style scoped>

    .home {
        margin-top: -20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        z-index: 1;
        color: var(--font-color);
    }

    .box {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 1070px;
        color: white;
    }

    .header-detail {
        margin-top: 100px;
    }

    h1 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .box h2 {
        font-size: 18px;
    }

    .site-header {
        font-family: VistaDev, sans-serif;
        background-color: #202020;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 13%, rgba(0, 0, 0, 0.7)),
        url("../../assets/10861.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-blend-mode: normal;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        /*height: 695px;*/
        /*-webkit-backdrop-filter: blur(1px);*/
        /*backdrop-filter: blur(1px);*/
        color: white;
        min-height: 430px;
        padding: 0 10px;
    }

    .input {
        background-color: transparent;
        width: 100%;
        max-width: 480px;
        height: 45px;
        /*border-radius: 5px;*/
        margin-top: 50px;
        /*border: solid 2px var(--main-orange);*/
        /*overflow: hidden;*/
        /*box-sizing: border-box;*/
    }

    .input input {
        width: calc(100% - 180px);
        height: 100%;
        padding: 10px;
        margin: 10px;
        border: solid 2px var(--main-orange);
        background-color: white;
        border-radius: 5px;
    }

    .input button {
        width: 160px;
        height: 100%;
        font-size: 14px;
        color: var(--font-color);
        border-radius: 5px;
        /*border: solid 2px var(--main-orange);*/
        margin: 0;
    }

    .normal-btn {
        margin: 0 0 50px 0;
    }

    .chart1, .chart2 {
        background-color: white;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column
    }

    .chart1 {
        background-color: var(--main-gray)
    }

    .chart1 .table {
        width: 100%;
        max-width: 1070px;
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        overflow: hidden;
        margin: 50px 0;
    }


    .more {
        display: inline-block;
        //width: 115px;
        font-size: 12px;
        font-weight: 800;
        align-items: center;
        cursor: pointer;
        margin: 20px auto;
    }

    .more:hover {
        text-decoration: underline;
        color: var(--main-orange);
    }

    .more:hover svg {
        fill: var(--main-orange);
    }

    /*.back {*/
    /*    width: 100%;*/
    /*    height: 100%;*/
    /*    position: fixed;*/
    /*    position: -ms-device-fixed;*/
    /*    z-index: -1;*/
    /*    top: 0;*/
    /*    left: 0;*/
    /*    filter: grayscale(90%);*/
    /*    -webkit-filter: grayscale(90%);*/
    /*    background-image: url("../../assets/Tradex/Home/home-background.png");*/
    /*    background-position: center;*/
    /*    background-size: cover;*/
    /*    !*background-repeat: no-repeat;*!*/
    /*}*/

    .benefit {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        min-height: 324px;
    }

    .benefit h2 {
        font-family: IRANSans-Bold, sans-serif;
        margin: 50px 0 35px;
        color: var(--font-color);
    }

    .benefit-detail {
        display: flex;
        justify-content: space-between;
    }

    .benefit-detail img {
        height: 100px;
        margin-bottom: 20px;
    }

    .benefit-detail h3 {
        font-size: 18px;
        margin-bottom: 20px;
        margin-top: 0;
        color: var(--font-color);
    }

    .benefit-detail p {
        font-size: 14px;
        text-align: justify;
    }

    .gallery-main {
        margin: 50px 0 30px;
        width: 100%;
        max-width: 1070px;
        padding: 0 10px;
    }

    .container {
        position: relative;
    }

    /*.site-footer {*/
    /*    background-color: #202020;*/
    /*    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) -21%, rgba(0, 0, 0, 0.7) 100%),*/
    /*    url("../../assets/Tradex/Home/home-background.png");*/
    /*    background-repeat: no-repeat;*/
    /*    background-position: center;*/
    /*    background-size: cover;*/
    /*    background-blend-mode: normal;*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*    align-items: center;*/
    /*    justify-content: center;*/
    /*    width: 100%;*/
    /*    !*height: 695px;*!*/
    /*    !*-webkit-backdrop-filter: blur(1px);*!*/
    /*    !*backdrop-filter: blur(1px);*!*/
    /*    color: white;*/
    /*    min-height: 600px;*/
    /*}*/

    /*.footer-box {*/
    /*    width: 100%;*/
    /*    max-width: 1070px;*/
    /*    padding: 10px;*/
    /*    display: flex;*/
    /*    flex-direction: row;*/
    /*    justify-content: space-between;*/
    /*    align-items: center;*/
    /*}*/

    /*.footer-box img {*/
    /*    width: 100%;*/
    /*    max-width: 400px;*/
    /*}*/

    /*.footer-box .main {*/
    /*    max-width: 500px;*/
    /*    text-align: right;*/
    /*}*/

    /*.footer-box .main h2{*/
    /*    font-size: 24px;*/
    /*}*/

    /*.footer-box .main p{*/
    /*    font-size: 18px;*/
    /*}*/

    /*.app-btn{*/
    /*    width: 100%;*/
    /*    display: flex;*/
    /*    justify-content: flex-start;*/
    /*    margin-top: 60px;*/
    /*    margin-bottom: 30px;*/
    /*}*/

    /*@media (max-width: 575px) {*/
    /*    .footer-box {*/
    /*        flex-direction: column;*/
    /*    }*/

    /*    .footer-box .main {*/
    /*        text-align: center;*/
    /*    }*/
    /*    .app-btn{*/
    /*        justify-content: center;*/
    /*    }*/
    /*}*/

    /*.g-app-btn {*/
    /*    border: solid 2px var(--main-orange);*/
    /*    border-radius: 5px;*/
    /*    width: 100%;*/
    /*    margin: 5px;*/
    /*    max-width: 200px;*/
    /*    height: 55px;*/
    /*    text-align: center;*/
    /*    position: relative;*/
    /*    cursor: pointer;*/
    /*}*/
    /*.g-app-btn:hover{*/
    /*    background-color: var(--main-orange);*/
    /*}*/

    /*.g-app-btn h3 {*/
    /*    font-family: IRANSans-Bold, sans-serif;*/
    /*    margin: 0;*/
    /*    font-size: 17px;*/
    /*    !*font-weight: 900;*!*/
    /*    position: absolute;*/
    /*    right: 50%;*/
    /*    bottom: 5px;*/
    /*    transform: translateX(50%) translateX(-20px);*/
    /*}*/

    /*.g-app-btn a {*/
    /*    color: white;*/
    /*    font-size: 12px;*/
    /*    position: absolute;*/
    /*    right: 50%;*/
    /*    transform: translateX(50%) translateX(-20px);*/
    /*    width: max-content;*/
    /*}*/

    /*.g-app-btn img {*/
    /*    position: absolute;*/
    /*    right: 10px;*/
    /*    top: 50%;*/
    /*    transform: translateY(-50%);*/
    /*    height: 23px;*/
    /*    width: auto;*/
    /*}*/

    /*.a-app-btn {*/
    /*    border: solid 2px var(--main-orange);*/
    /*    border-radius: 5px;*/
    /*    width: 100%;*/
    /*    margin: 5px;*/
    /*    max-width: 200px;*/
    /*    height: 55px;*/
    /*    text-align: center;*/
    /*    position: relative;*/
    /*    cursor: pointer;*/
    /*}*/
    /*.a-app-btn:hover{*/
    /*    background-color: var(--main-orange);*/
    /*}*/

    /*.a-app-btn a {*/
    /*    color: white;*/
    /*    font-size: 12px;*/
    /*    position: absolute;*/
    /*    right: 50%;*/
    /*    transform: translateX(50%) translateX(-20px);*/
    /*    width: max-content;*/
    /*}*/

    /*.a-app-btn img:first-of-type {*/
    /*    position: absolute;*/
    /*    right: 10px;*/
    /*    top: 50%;*/
    /*    transform: translateY(-50%);*/
    /*    height: 23px;*/
    /*    width: auto;*/
    /*}*/

    /*.a-app-btn img:last-of-type {*/
    /*    position: absolute;*/
    /*    top: 50%;*/
    /*    height: 20px;*/
    /*    width: auto;*/
    /*    !*font-weight: 900;*!*/
    /*    right: 50%;*/
    /*    bottom: 5px;*/
    /*    transform: translateX(50%) translateX(-20px);*/
    /*}*/
</style>
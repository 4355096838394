<template>
  <div class="main-box">
    <div class="tab">
      <div :class="[!toman ? 'tab-content' : 'tab-content-active']"
           @click.prevent="toman=true">
        <a>تومان</a>
      </div>
      <div :class="[toman ? 'tab-content' : 'tab-content-active']"
           @click.prevent="toman=false">
        <a>تتر</a>
      </div>
      <div class="selector" :style="{right : toman? '' : '50%'}"></div>
    </div>
    <perfect-scrollbar :options="{suppressScrollX: true,}" class="box1">
        <real-price-table-toman :toman="toman" :key="toman"/>
    </perfect-scrollbar>
  </div>
</template>

<script>

import RealPriceTableToman from "./RealPriceTableToman";
import {tradeTo} from "lib/reuseableFunction";
// import RealPriceTableTeter from "./RealPriceTableTeter";

export default {
  name: "RealPrice",
  components: {RealPriceTableToman},
  data() {
    return {
      toman: this.tradeTo === 'TOMAN',
    }
  },
  computed: {
    tradeTo
  }
}
</script>

<style lang="scss" scoped>

.main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: right;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 1400px;
  font-size: 12px;
  background-color: transparent;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  direction: ltr;
}


.tab {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 30px;
  border-bottom: #00000010 1px solid;
  font-size: 12px;
  align-items: center;
  background-color: transparent;
  text-align: right;
  width: 90%;
  direction: rtl;
}

.dark .tab a {
  color: var(--font-color);
}

.dark .tab a {
  color: white;
}

.tab-content {
  width: 50%;
  opacity: 0.5;
  text-align: center;
  cursor: pointer;
}

.tab-content-active {
  font-weight: 800;
  width: 50%;
  opacity: 0.7;
  text-align: center;
}

.box1 {
  flex-direction: column;
  /*background-color: transparent;*/
  padding: 0 10px;
  width: 100%;
  height: calc(100% - 30px);
}

.selector {
  position: absolute;
  background-color: var(--main-orange);
  width: 50%;
  height: 2px;
  border-radius: 5px;
  bottom: -1px;
  right: 0;
  transition: 0.5s;
}
</style>
<template>
    <div class="Auth-box">
        <form @submit.prevent="send" class="Auth">
            <ali-input @update:value="emailError=''" :value.sync="data.email" type="email" name="email" :errortext="emailError" faname="ایمیل"/>
            <ali-input @update:value="passError=''" :value.sync="data.password" type="password" name="password" :errortext="passError"
                       faname="رمز عبور"/>
            <ali-input @update="check2Pass" :value.sync="data.referralCode" type="text" name="password" :errortext="''"
                       faname="کد دعوت" :readonly="readOnly"/>
            <button hidden type="submit" :disabled="disable"/>
        </form>
        <button type="submit" class="normal-btn" @click="send"
                :disabled="disable">ثبت نام
        </button>
        <a style="margin: 5px 0">یا</a>
        <button class="google-btn" @click="goToGoogle">
            ثبت نام با گوگل
        </button>
        <div style="font-size: 14px;text-align: center;margin-top: 10px">
            <a>با ورود و یا ثبت نام در صرافی ویستا ارز شما <a class="link" @click="$router.push('/policy')">شرایط
                و قوانین</a> استفاده از سرویس های
                سایت و قوانین حریم خصوصی آن را می‌پذیرید.</a>
        </div>
        <!--        <div style="text-align: center;margin-top: 20px;font-size: 14px">-->
        <!--            قبلا در ویستا ثبت‌نام کرده‌اید؟ <a class="link" @click="()=>this.$router.push('login')"> وارد شوید</a>-->
        <!--        </div>-->
    </div>
</template>

<script>
    import AliInput from "@/components/Tools/AliInput";

    export default {
        name: "Register",
        components: {AliInput},
        data() {
            return {
                data: {
                    email: '',
                    password: '',
                    osType: '',
                    deviceType: '',
                    browser: '',
                    referralCode: ''
                },
                password: '',
                type1: 'password',
                type2: 'password',
                emailError: '',
                passError: '',
            }
        },
        computed: {
            disable() {
                return !this.data.email || !this.data.password || this.emailError.length > 0 || this.passError.length > 0
            },
            readOnly(){
                return localStorage.referralCode || this.$route.query.ref
            }
        },
        methods: {
            goToGoogle(){
                window.location = this.$google_sso
            },
            async send() {
                await this.checkEmail()
                await this.check2Pass()
                this.state.loading = true
                const res = await this.$axios.post('/users/register', this.data)
                if (res.message === 'user created successfully , verification email sent') {
                    if (res.baseDTO.towStepAuthenticationType === 'EMAIL') {
                        this.$cookies.set('username', this.data.email)
                        this.state.userInfo.email = this.data.email
                        await this.$router.push('/two-step-email')
                    }
                } else if (res.token) {
                    this.$setToken(res.token, this.state.userInfo.remember)
                    await this.$router.push('/profile')
                }
            },
            async check2Pass() {
                if (!this.$validPass(this.data.password) || this.data.password.length < 8) {
                    this.passError = 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد'
                    this.$error('اطلاعات وارد شده اشتباه است', 'رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد')
                    return Promise.reject('wrong pass : ' + this.data.password)
                }
            },
            async checkEmail() {
                if (!this.$validEmail(this.data.email) || !this.data.email) {
                    this.emailError = 'ایمیل صحیح وارد نمایید'
                    this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                    return Promise.reject('repeat failed : ' + this.data.email)
                }
            },
            getData() {
                this.data.osType = this.$detectOs()
                this.data.browser = this.$detectBrowser() || 'CHROME'
                this.data.deviceType = this.$detectDevice()
            },
        },
        mounted() {
            this.getData()
            if(this.$route.query.ref || localStorage.referralCode){
                localStorage.referralCode = this.$route.query.ref || localStorage.referralCode
                this.data.referralCode = this.$route.query.ref || localStorage.referralCode
            }
        }
    }
</script>

<style lang="scss" scoped>

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 470px;
        height: 100%;
        margin: 0;
        padding: 10px 0;
        border: none;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .link {
        /*margin-right: 10px;*/
        text-decoration: underline;
        font-size: 14px !important;
        cursor: pointer;

        &:hover {
            color: var(--font-hover);
        }
    }
</style>

<template>
  <div class="box1">
    <OverLoading v-if="loading"/>
    <div style="position: relative;width: 100%;height: 100%">
      <perfect-scrollbar :options="settings">
        <table>
          <tr class="table-header">
            <th>مقدار</th>
            <th>قیمت</th>
            <th>مجموع</th>
            <th>زمان</th>
          </tr>
          <tr :style="{color:tableContent.up ? 'var(--main-green)':'var(--main-red)'}"
              v-for="(tableContent,index) in tableContents" :key="index"
              class="table-row">
            <td>{{ tableContent.amount }}</td>
            <td>{{ tableContent.unitPrice.toLocaleString() }}</td>
            <td>{{ tableContent.wholePrice.toLocaleString() }}</td>
            <td>{{ $G2J(tableContent.createdAtDateTime, 'faDate') }}</td>
          </tr>
          <tr v-if="!tableContents.length">
            <td colspan="4">
              <p style="width:100%;text-align: center">در حال حاضر اطلاعاتی وجود ندارد.</p>
            </td>
          </tr>
        </table>
      </perfect-scrollbar>
    </div>
  </div>
</template>


<script>
import OverLoading from "@/components/over-loading";
import {tradeFrom, tradeTo, Loop} from "lib/reuseableFunction";

export default {
  name: "LastTrade",
  components: {OverLoading},
  data() {
    return {
      tableContents: [
        /*{
            amount: 0,
            calculatedPriceInTomanMarket: 0,
            createdAtDateTime: '',
            destinationCoin: "BINANCE_COIN",
            executedPercent: 0,
            id: 0,
            marketType: "BINANCE_COIN_TETHER",
            orderType: '',
            receivedAmount: 0,
            sourceCoin: "BINANCE_COIN",
            unitPrice: 0,
            wagePercent: 0,
            wholePrice: 0
        }*/
      ],
      loading: false,
      settings: {
        suppressScrollX: true
      },
      loop: undefined
    }
  },
  computed: {
    tradeTo, tradeFrom
  },
  methods: {
    async getLastTrades() {
      const [res,] = await this.$http.get('/trades/last-trades', {
        params: {
          type: 'P2P',
          marketType: this.tradeFrom + '_' + this.tradeTo,
          size: 40
        }
      })
      if (res) {
        let up = true
        this.tableContents = res.content
            .filter(e => e.orderType.includes('SELL'))
            .reverse()
            .map((e, i, arr) => {
              up = (arr[i - 1]?.unitPrice || 0) < e.unitPrice ? true : (arr[i - 1]?.unitPrice || 0) > e.unitPrice ? false : up
              return {
                ...e,
                up: up
              }
            })
            .reverse()
      }
      this.loading = false
    },
    async beforeLoop() {
      this.loading = true
      this.loop = new Loop(this.getLastTrades, 1000, 'lastTrade')
      if (this.state.loop) {
        this.loop.start()
      } else {
        this.getLastTrades()
      }
    }
  },
  mounted() {
    this.beforeLoop()
  },
  beforeDestroy() {
    this.loop?.stop()
  }

}
</script>

<!--<style src="../../../node_modules/vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css"/>-->
<style scoped>
.ps {
  height: 100%;
  /*height: 100%;*/
  width: 100%;
}

.box1 {
  flex-direction: column;
  width: 100%;
  font-size: 12px;
  height: 100%;
  direction: ltr;
  align-items: center;
  display: block;
  position: relative;
}

table {
  direction: rtl;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  border-collapse: collapse;
  /*transform: scale(1);*/
  /*position: relative;*/
}

.table-header {
  position: -webkit-sticky;
  position: sticky;
  /*position: fixed;*/
  /*position: -ms-device-fixed;*/
  top: 0;
  background-color: white;
  padding: 10px 0;
  height: 40px;
  text-align: center;
  z-index: 1;
}

.dark .table-header {
  background-color: var(--dark-background);
}

.dark table .table-header {
  background-color: var(--dark-main) !important;
}

th {
  opacity: 0.5;
}

td {
  opacity: 0.8;
}

.table-header th:first-of-type,
.table-row td:first-of-type {
  text-align: right;
  padding-right: 10px;
}

.table-header th:last-of-type,
.table-row td:last-of-type {
  text-align: left;
  padding-left: 10px;
}


.table-row {
  position: relative;
  height: 20px;
  width: 100%;
  text-align: center;
  cursor: default;
}

</style>
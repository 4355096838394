<template>
    <div class="main-box">
        <h1>بازار ها</h1>
        <div class="tab">
            <div :class="[!active ? 'tab-content' : 'tab-content-active']"
                 @click.prevent="active=true">
                <a>تومان</a>
            </div>
            <div :class="[active ? 'tab-content' : 'tab-content-active']"
                 @click.prevent="active=false">
                <a>تتر</a>
            </div>
            <div class="selector" :style="{right :[active? '' : '50%']}"></div>
        </div>
        <div class="box1">
            <crypto-rial-chart v-if="active" :max="max" :active="active"/>
            <crypto-rial-chart v-else :max="max-1" :active="active"/>
        </div>
    </div>
</template>

<script>

    import CryptoRialChart from "../Tools/CryptoRialChart";
    // import CryptoTeterChart from "../Tools/CryptoTeterChart";

    export default {
        name: "CryptoChart",
        components: { CryptoRialChart,},
        props: {
            max: {
                default: 11
            }
        },
        data() {
            return {
                active:true,
            }
        },
    }
</script>

<style scoped>

    .main-box {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0 10px;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
        max-width: 1170px;
        font-size: 14px;
        background-color: transparent;
        margin-top: 30px;
        color: var(--font-color);
    }

    h1{
        font-size: 30px;
        margin-right: 10px;
    }


    .tab {
        display: flex;
        flex-direction: row;
        position: relative;
        height: 60px;
        border-bottom: #00000010 1px solid;
        font-size: 18px;
        align-items: center;
        background-color: transparent;
        text-align: right;
        width: 100%;
        margin-top: 10px;

    }

    .tab-content {
        width: 50%;
        opacity: 0.5;
        text-align: center;
        cursor: pointer;
    }

    .tab-content-active {
        font-weight: 800;
        width: 50%;
        text-align: center;
    }

    .box1 {
        flex-direction: column;
        margin: 50px 0;
        background-color: white;
        width: 100%;
    }

    .selector {
        position: absolute;
        background-color: var(--main-orange);
        width: 50%;
        height: 6px;
        border-radius: 5px;
        bottom: -3px;
        right: 0;
        transition: 0.5s;
    }
</style>
import Vue from "vue";
import VueRouter from "vue-router";

const that = Vue.prototype

import TwoStepEmail from "@/components/Auth/TwoStepEmail";
import RecoveryPass from "@/components/Auth/RecoveryPass";
import ResetPass from "@/components/Auth/ResetPass";
import Profile from "@/components/UserAccount/Profile";
import Messages from "@/components/UserAccount/Messages";
import HowToUse from "@/components/Help/HowToUse";
import FAQ from "@/components/Help/FAQ";
import History from "@/components/History/History";
import Wage from "@/components/Help/Wage";
import Policy from "@/components/Help/Policy";
import AboutUs from "@/components/Help/AboutUs";
import Wallet from "@/components/Wallet/Wallet";
import Home from "@/components/Home/Home";
import Trade from "@/components/Trade/Trade";
import Setting from "@/components/UserAccount/Setting";
import Trades from "@/components/Trade/Trades";
import LoginRegister from "@/components/Auth/LoginRegister";
import Invite from "@/components/UserAccount/Invite";
import Simple from "@/components/Trade/Simple";
import RedirectFromBank from "@/components/Wallet/RedirectFromBank";
import FinoVerifying from "@/components/Auth/FinoVerifying";
import FromGoogle from "components/Auth/FromGoogle";
// import RedirectFromBank from "@/components/Wallet/RedirectFromBank"


Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    base: '/',
    linkActiveClass: 'active-tab',
    routes: [
        {
            path: '/',
            redirect: '/home',
        },
        {
            path: '/sign-*',
            name: 'Auth',
            component: LoginRegister,
            props: true
        },
        {
            path: '/fromgoogle',
            name: 'FromGoogle',
            component: FromGoogle
        },
        {
            path: '/reset-pass',
            name: 'ResetPass',
            component: ResetPass,

        },
        {
            path: '/recovery-pass',
            name: 'RecoveryPass',
            component: RecoveryPass
        },
        {
            path: '/two-step-*',
            name: 'TwoStepEmail',
            component: TwoStepEmail,
            props: true
        },
        {
            path: '/verifying',
            name: 'VerifyPage',
            component: FinoVerifying
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/setting',
            name: 'Setting',
            component: Setting
        },
        {
            path: '/messages/:id',
            name: 'Messages',
            component: Messages,
            props: true
        },
        {
            path: '/messages',
            name: 'Messages',
            component: Messages
        },
        {
            path: '/howtouse',
            name: 'HowToUse',
            component: HowToUse
        },
        {
            path: '/redirect-from-bank',
            name: 'redirect',
            component: RedirectFromBank
        },
        {
            path: '/faq',
            name: 'FAQ',
            component: FAQ
        },
        {
            path: '/history/:tab/:id',
            name: 'History',
            component: History,
            props: true
        },
        {
            path: '/history',
            redirect: 'history/buy/1',
            name: 'History',
            component: History,
            props: true
        },
        {
            path: '/wallet/:tab/:id',
            name: 'Wallet',
            component: Wallet,
            props: true
        },
        {
            path: '/wallet',
            redirect: 'wallet/deposit/1',
            name: 'Wallet',
            component: Wallet,
            props: true
        },
        {
            path: '/wage',
            name: 'Wage',
            component: Wage
        },
        {
            path: '/policy',
            name: 'Policy',
            component: Policy
        },
        {
            path: '/about-us',
            name: 'AboutUs',
            component: AboutUs
        },
        {
            path: '/trade/:tradeTo/:tradeFrom',
            name: 'Trade',
            component: Trade,
            props: true
        },
        {
            path: '/trade',
            name: 'Trades',
            component: Trades,
        },
        {
            path: '/home',
            name: 'Home',
            component: Home
        },
        {
            path: '/invite',
            name: 'Invite',
            component: Invite
        },
        {
            path: '/simple',
            name: 'Simple',
            component: Simple,
            props: true
        },

        {
            path: '*',
            redirect: '/'
        }
    ],

    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (to.name !== from.name) {
            return new Promise(() => {
                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    })
                }, 500)
            })
        }
    }
})

const forLogin = [
    'History',
    'Dashboard',
    'Wallet',
    'Setting',
    'Messages',
    'Profile',
    'Orders',
    // 'Trade',
    // 'Simple',
]
const unAuthorUser = [
    'History',
    'Dashboard',
    'Wallet',
    'Orders',
    'Simple'
]
const otp = [
    'TwoStepEmail',
    'TwoStepGoogleAuth',
    'TwoStepSms',
    'ResetPass',
]
const auth = [
    'Auth',
    'ForgetPass',
]

router.beforeEach(async (to, from, next) => {

    document.title = to.name

    if (!that.state.coins) {
        await that.$getCoins()
    }

    that.state.loading = true
    if (that.state.token) {

        if (!that.state.gotten) {
            await that.$getuserInfo()
            that.state.loading = true
            await that.$checkMessages()
            that.state.loading = true
            await that.$getFees()
        }

        that.state.loading = true

        if (from.name === 'Blog') {
            await window.open(to.fullPath, '_self')
        } else if (otp.includes(to.name) || auth.includes(to.name)) {
            next({name: 'Home'})
        } else if (to.name === 'Trade' && otp.includes(from.name) && ['UNAUTHORIZED', 'NOT_COMPLETE'].includes(that.state.userInfo.authenticationType)) {
            next({name: 'Profile'})
        } else if (to.name === 'Trade') {
            try {
                that.state.loading = true
                await that.$axios(`/markets/${to.params.tradeFrom}_${to.params.tradeTo}`)
                next()
            } catch (e) {
                if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
                    if (otp.includes(from.name)) {
                        next({name: 'Home'})
                    } else {
                        await that.$error('بازار غیرفعال است')
                        next(from)
                    }
                }
            }
        } else {
            if (that.state.userInfo.authorized) {
                next()
            } else {
                if (otp.includes(from.name) && to.name !== 'Profile')
                    next({name: 'Profile'})
                else if (unAuthorUser.includes(to.name)) {
                    that.state.loading = false
                    const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
                    if (res.isConfirmed) {
                        next({name: 'Profile'})
                    } else {
                        next(from)
                    }
                } else next()
            }
        }
    } else {
        if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
            next({name: 'Auth', params: {pathMatch: 'in'}})
        else {
            next()
        }
    }
    that.state.loading = false
})
router.afterEach(() => {
    that.state.loading = false
})

export default router

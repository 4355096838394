import { render, staticRenderFns } from "./Trade.vue?vue&type=template&id=47acc15a&scoped=true&"
import script from "./Trade.vue?vue&type=script&lang=js&"
export * from "./Trade.vue?vue&type=script&lang=js&"
import style0 from "../../assets/bootstrap-grid-rtl-2/bootstrap-grid-rtl.scss?vue&type=style&index=0&id=47acc15a&lang=scss&rel=stylesheet&scoped=true&"
import style1 from "./Trade.vue?vue&type=style&index=1&id=47acc15a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47acc15a",
  null
  
)

export default component.exports
<template>
    <div class="main-box">
        <h2 style="text-align: right">حساب کاربری
            <a :style="{color : variant}">({{label}})</a>
        </h2>
        <form @submit.prevent="submit" class="box2">
            <personal-information :data.sync="userInfo"/>
            <bank-information :key="change" :data="userInfo" :error="duplicateBank"/>
            <upload-documents :data.sync="userInfo" v-if="userInfo.authenticationType !== 'AUTHORIZED'"/>
            <p v-if="disabled" style="color: red">{{disabled}}</p>
            <button
                    type="submit"
                    class="normal-btn"
                    :disabled="disabled"
            >
                ثبت تغییرات
                {{[ 'NOT_COMPLETE' , 'INFORMATION_CHANGED'].includes(state.userInfo.authenticationType) ?
                ' و احراز هویت ' : ''}}
            </button>
        </form>
    </div>
</template>

<script>
    import PersonalInformation from "@/components/UserAccount/PersonalInformation";
    import BankInformation from "@/components/UserAccount/BankInformation";
    import UploadDocuments from "@/components/UserAccount/UploadDocuments";

    const inputLabel = {
        'firstName': 'نام',
        'lastName': 'نام خانوادگی',
        'email': 'ایمیل',
        'birthDay': 'تاریخ تولد',
        'mobileNumber': 'شماره موبایل',
        'telephone': 'شماره ثابت',
        'address': 'آدرس',
        'image': 'عکس',
    }

    const statusLabel = {
        CONFIRMED_IDENTITY: 'اطلاعات هویتی تایید شده',
        AUTHORIZED: 'احراز هویت شده',
        UNAUTHORIZED: 'اطلاعات تکمیل نشده',
        PENDING: 'در انتظار تایید',
        NOT_COMPLETE: 'احراز هویت تکمیل نشده',
        UNIDENTIFIED: 'اطلاعات هویتی تایید نشده',
        INFORMATION_CHANGED_PENDING: 'در انتظار تایید',
        INFORMATION_CHANGED: 'احراز هویت تکمیل نشده'
    }
    const statusVariant = {
        CONFIRMED_IDENTITY: 'royalblue',
        AUTHORIZED: 'green',
        UNAUTHORIZED: 'red',
        PENDING: 'gold',
        NOT_COMPLETE: 'orange',
        UNIDENTIFIED: 'red',
        INFORMATION_CHANGED_PENDING: 'gold',
        INFORMATION_CHANGED: 'orange'
    }


    export default {
        name: "Profile",
        components: {UploadDocuments, BankInformation, PersonalInformation},
        data() {
            return {
                userInfo: {
                    // authorized: this.state.userInfo.authorized,
                    // pending: this.state.userInfo.pending,
                    authenticationType: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    nationalCode: '',
                    birthDay: '',
                    mobileNumber: '',
                    telephone: '',
                    address: '',
                    bankAccounts: [{shebaCode: "", cardNumber: "", bankName: ""}],
                    image: '',
                },
                duplicateBank: '',
                change: 0,
            }
        },
        computed: {
            disabled() {
                for (let key in this.userInfo) {
                    if (['firstName', 'lastName', 'email', 'nationalCode', 'birthDay', 'mobileNumber', 'telephone', 'address', 'bankAccounts', 'image'].includes(key)) {
                        if (key === 'bankAccounts') {
                            for (let data of this.userInfo.bankAccounts) {
                                if (!data.shebaCode || !data.cardNumber || data.cardNumber.replace(/-/g, '').length < 16) {
                                    return 'اطلاعات بانکی اشتباه است';
                                }
                            }
                        } else if (key === 'address') {
                            // let re = /\d{10}/;
                            if (this.userInfo.address && this.userInfo.address.replace(/ /ig, '').length < 21 /*|| !re.test(this.userInfo.address)*/) {
                                return 'آدرس الزامیست'
                            }
                        } else if (key === 'nationalCode') {
                            if (this.userInfo[key] < 10) {
                                return 'کدملی اشتباه است';
                            }
                        } else if (!this.userInfo[key]) {
                            return inputLabel[key] + ' اجباریست'
                        }
                    }
                }
                return false
            },
            label() {
                return statusLabel[this.state.userInfo.authenticationType]
            },
            variant() {
                return statusVariant[this.state.userInfo.authenticationType]
            }
        },
        methods: {
            async check() {
                this.state.loading = true;
                const res = await this.$axios("/users/account")
                this.userInfo = res.baseDTO
                this.userInfo.bankAccounts = res.baseDTO.bankAccounts.length ?
                    res.baseDTO.bankAccounts
                    : [{shebaCode: "", cardNumber: "", bankName: ""}]

                this.change++

            },
            removeDash(items) {
                for (let item of items) {
                    item.cardNumber = item.cardNumber.replace(/-/gi, '');
                }
            },
            bankCheck(items) {
                for (let item of items) {
                    if (!item.cardNumber || !item.shebaCode) {
                        this.$error('اطلاعات ناقص', "شماره شبا یا کارت بانکی را وارد نکرده اید");
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            dataURLtoFile(dataurl, filename) {

                let arr = dataurl.split(",");
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                let u8arr = new Uint8Array(n);

                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }

                return new File([u8arr], filename, {type: mime});
            },
            async submit() {
                this.state.loading = true;

                this.removeDash(this.userInfo.bankAccounts);

                let form_data = new FormData();

                for (let key in this.userInfo) {
                    if (key === 'image') {
                        if (typeof this.userInfo.image !== 'string') {
                            form_data.append("file", this.userInfo.image);
                        }
                    } else {
                        form_data.append(key, this.userInfo[key]);
                    }
                }

                form_data.append("bankAccountsString", JSON.stringify(this.userInfo.bankAccounts));

                if (this.bankCheck(this.userInfo.bankAccounts)) {
                    try {
                        const res = await this.$axios.post("/users/account", form_data)

                        this.state.userInfo.authenticationType = res.baseDTO.authenticationType
                        this.state.userInfo.email = res.baseDTO.email
                        this.state.userInfo.authorized = res.baseDTO.authenticationType === "AUTHORIZED"
                        this.state.userInfo.pending = res.baseDTO.authenticationType === "PENDING"

                        await this.$error('', 'اطلاعات با موفقیت ذخیره شد', 'success')

                        // console.log('info => ',this.userInfo)

                        if (res.message.includes("need to be verified")) {
                            this.state.loading = true
                            await this.$axios(`/finotech/send-sms-to-customer?email=${this.userInfo.email}`)
                            this.$router.push('/verifying')
                        }
                    } catch (e) {
                        if (e.response && e.response.data.message.includes("ShebaCode or CardNumber Is For Another User")) {
                            this.$error('', ' شماره شبا یا کارت قبلا وارد شده است.')
                            this.duplicateBank = e.response.data.message
                        }
                    }
                }
            },
        },
        created() {
            this.check()
        }

    }
</script>

<style scoped>
    .normal-btn {
        color: white;
        font-size: 14px;
        width: 30%;
        min-width: 137px;
        margin: 34px;
    }

    .main-box {
        display: inline-block;
        background-color: transparent;
        text-align: right;
        margin-top: 30px;
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        max-width: 1170px;
    }

    h2 {
        font-family: IRANSans-Bold, sans-serif;
        font-size: 22px;
        margin-bottom: 30px;
        margin-right: 10px;
    }


    .box2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        text-align: right;
        width: 100%;
    }

    .normal-btn {
        max-width: 300px;
    }
</style>
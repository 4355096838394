<template>
  <div class="gallery-box row">
    <div v-for="(post,index) in posts.slice(0,3)" :key="index"
         class="col-12 col-sm-4 pb-3">
      <div class="gallery-content">
        <img :src="post.better_featured_image.source_url" alt="">
        <a :href="post.link" class="gallery-content-body" :title="post.title.rendered" target="_blank">
          {{ post.title.rendered }}
        </a>
        <div class="gallery-content-footer">
          <div>
            {{ minute(post.excerpt.rendered) }} دقیقه
          </div>
          <div>{{ $G2J(post.date, 'faBlog') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const posts = require("@/lib/blogs.json")
export default {
  name: "HomeGallery",
  data() {
    return {
      gallerywidth: '',
      screanWidth: screen.width,
      trans: 0,
      enable: 1,
      maingallerywith: '',
      posts: posts,
    }
  },
  computed: {
    minute() {
      return e => e.match(/<span class="rt-time">(.*?)<\/span>/)[1] || 5
    }
  },
  mounted() {
    this.windowssize()
    this.getBlogInfo()
    window.addEventListener('resize', this.windowssize);
  },
  methods: {
    windowssize() {
      this.screanWidth = screen.width
      if (this.screanWidth > 1200) {
        this.gallerywidth = (1070 / 4) - 15;
        this.maingallerywith = 1070
      } else if (this.screanWidth > 990) {
        this.gallerywidth = (910 / 4) - 5;
        this.maingallerywith = 910
      } else if (this.screanWidth > 768) {
        this.gallerywidth = (680 / 3) - 3;
        this.maingallerywith = 680
      } else if (this.screanWidth > 575) {
        this.gallerywidth = (510 / 2);
        this.maingallerywith = 510
      } else {
        this.gallerywidth = 285
      }
    },
    left() {
      var n = 0
      if (this.trans < ((this.posts.length) * (this.gallerywidth + 20) - 20)) {
        if (this.screanWidth > 990) {
          n = ((this.posts.length - 4) * (this.gallerywidth + 20)) - this.trans
        } else if (this.screanWidth > 768) {
          n = ((this.posts.length - 3) * (this.gallerywidth + 20)) - this.trans
        } else {
          n = ((this.posts.length - 2) * (this.gallerywidth + 20)) - this.trans
        }
        console.log(n)
        if (n < this.gallerywidth) {
          this.trans += n
        } else {
          this.trans += this.gallerywidth + 20;
        }
      }
      if (n <= 0) {
        this.trans = 0
      }
      // if (this.screanWidth > 990) {
      //     n = ((this.posts.length - 4) * (this.gallerywidth + 10)) - this.trans
      // } else if (this.screanWidth > 768) {
      //     n = ((this.posts.length - 3) * (this.gallerywidth + 10)) - this.trans
      // } else {
      //     n = ((this.posts.length - 2) * (this.gallerywidth + 10)) - this.trans
      // }
    },
    right() {
      if (this.trans > 0) {
        if (this.trans < this.gallerywidth) {
          this.trans -= this.trans
        } else {
          this.trans -= this.gallerywidth + 20;
        }
      } else if (this.trans == 0) {
        this.trans = (this.posts.length * (this.gallerywidth + 20) - this.maingallerywith) - 20
        return
      }
    },
    async getBlogInfo() {
      this.posts = await this.$axios.get('https://VistaEx.ir/blog/wp-json/wp/v2/posts?per_page=3&context=embed&page=1')
      this.$emit('show', true)
    },
  }
}
</script>

<style scoped>

.gallery-box {
  transition: 0.5s;
  display: flex;
  justify-content: space-between;
}

.gallery-content {
  font-size: 18px;
  max-width: 300px;
  height: 100%;
  /*min-height: 270px;*/
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 5px;
  overflow: hidden;
  color: var(--font-color);
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  position: relative;
}

.gallery-content:hover {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}

.gallery-content-body {
  font-size: 16px;
  font-weight: 800;
  padding: 20px 10px 50px;
  margin: 0;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.gallery-content-footer {
  font-size: 14px;
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
}

.gallery-content-footer div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gallery-content img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.gallery-content div img {
  /*width: 20px;*/
  height: 20px;
  margin-left: 5px;
}

</style>
<template>
    <div class="Auth-box">
        <h3>شناسایی دو عاملی</h3>
        <p>کد ۵ رقمی ارسال شده به شماره خود را وارد نمایید.</p>
        <CodeInput :loading="false" class="auth-row"
                   @change="onChange" @complete="submit"
                   :fields="num" ref="code-input" :fieldWidth="55" :fieldHeight="55"/>
        <div class="footer">
            <a v-if="countDown===0" @click.prevent="send" style="cursor: pointer" class="countdown">ارسال مجدد</a>
            <a v-else style="opacity:0.5;cursor:default" class="countdown">ارسال مجدد</a>
            <a style="text-decoration: none" class="countdown">{{countDown}} ثانیه </a>
        </div>
        <button class="normal-btn" @click.prevent="submit" :disabled="code.toString().length < num || countDown === 0">تایید</button>
        <a style="opacity: 0.8">{{error}}</a>
    </div>
</template>

<script>
    import CodeInput from "vue-verification-code-input";

    export default {
        name: "FinoVerifying",
        components: {CodeInput},
        data() {
            return {
                email: '',
                password: '',
                password2: '',
                type1: 'password',
                type2: 'password',
                checked: '',
                countDown: 180,
                smscode: [],
                error: '',
                num: 5,
                code: '',
                timeOut: '',
            }
        },
        methods: {
            async countDownTimer() {
                if (this.countDown > 0) {
                    this.timeOut = setTimeout(() => {
                        this.countDown--
                        this.countDownTimer()
                    }, 1000)
                } else {
                    const a = await this.$error('زمان شما به اتمام رسید', '','warning','ارسال مجدد')
                    if(a.isConfirmed){
                        this.send()
                    }
                }
            },
            async send() {
                this.smscode = ''
                this.state.loading = true
                const res = await this.$axios.get(`/finotech/send-sms-to-customer?email=${this.state.userInfo.email}`)
                if (res.message === 'Sms sent to customer.') {
                    if (this.countDown === 0) {
                        this.countDown = 180
                        this.countDownTimer()
                    }
                }
            },
            /*setPlaceholder() {
                for (let i = 0; i < this.num; i++) {
                    this.$refs['code-input'].$refs['input_' + i][0].placeholder = '----'
                }
            },*/
            onChange(e) {
                this.code = e
            },
            async submit() {
                if (this.code) {
                    this.state.loading = true
                    await this.$axios.get('/finotech/otp', {
                        params: {
                            otp: this.code,
                            email: this.state.userInfo.email
                        }
                    })
                    await this.$error('', 'فرایند احراز هویت انجام شد. منتظر تایید اطلاعات توسط پشتیبانی باشید.', 'success')
                    this.$router.push('/profile')
                }
            }

        },
        created() {
            this.countDownTimer()
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },
        mounted() {
            this.$refs['code-input'].$refs['input_0'][0].focus();
        },
    }
</script>

<style lang="scss" scoped>
    p{
        margin-bottom: 30px;
    }
    input{
        padding: 5px 0;
    }
    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 450px;
        height: 100%;
    }
    .countdown {
        font-size: 14px;
        text-decoration: underline;
    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }

    .auth-row{
        display: flex;
        direction: ltr;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
    }

    .Auth-input {
        font-size: 20px;
        border-radius: 5px;
        border: solid 1px #00000010;
        width: 55px;
        height: 55px;
        text-align: center;
        &:focus{
            border-color: var(--main-orange);
        }
    }

    .normal-btn {
        margin-top: 60px;
        margin-bottom: 20px;
    }
</style>
<template>
    <div class="main">
        <div class="main-box">
            <div class="box-item">
                <p>درگاه پرداخت :</p>
                <p>{{gateway}}</p>
            </div>
            <div class="box-item">
                <p>مقدار :</p>
                <p>{{$toLocal(amount)}} تومان</p>
            </div>
            <div class="box-item">
                <p>کد رهگیری :</p>
                <p>{{code}}</p>
            </div>
            <div class="box-item">
                <p>وضعیت :</p>
                <span :style="{color:status === 'موفق'? 'var(--main-green)':'var(--main-red)' }">{{status}}</span>
            </div>
        </div>
        <button class="normal-btn" @click="$router.push('/wallet')">بازگشت به کیف پول ها</button>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                status: '',
                code: '',
                amount: '',
                gateway: ''
            }
        },
        async created() {
            // console.log(this.$route.query);
            const res = await this.$axios(`/wallets/deposit-status?id=${this.$route.query.id}`)
            this.status = res.baseDTO.isDone ? 'موفق' : 'ناموفق'
            this.amount = res.baseDTO.amount
            this.gateway = res.baseDTO.paymentGateway
            this.code = res.baseDTO.hashCode
        }
    }
</script>

<style lang="scss" scoped>
    .main {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .main-box{
            width: 100%;
            max-width: 500px;
            /*height: 200px;*/
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            .box-item{
                display: flex;
                justify-content: space-between;
                padding: 10px 0;
                border-bottom: 1px solid var(--main-gray);
                &:last-of-type{
                    /*border: none;*/
                }
            }
        }
        .normal-btn{
            font-size: 17px;
            max-width: 200px;
        }
    }
</style>
<template>
    <div class="account">
        <account-active v-if="state.token" :route="route"/>
        <a href="/sign-in" @click.prevent="$router.push('sign-in')" v-if="!state.token" class="btn" >ورود</a>
        <a href="/sign-up" @click.prevent="$router.push('sign-up')" v-if="!state.token" class="btn" >ثبت نام</a>
        <img v-if="$route.name === 'Trade'" @click="theme('dark')" src="../../assets/icon/sun.svg" alt="">
        <img v-if="$route.name === 'Trade'" @click="theme('light')" src="../../assets/icon/moon.svg" alt="">
<!--        <account-deactive v-show="!account" :route="route"></account-deactive>-->
    </div>
</template>

<script>
    import AccountActive from "@/components/Navbar/account-active";
    // import AccountDeactive from "@/components/Navbar/AccountDeactive";

    export default {
        name: "account",
        props: ['route'],
        components: {AccountActive},
        methods:{
           
            theme(e){
                localStorage.theme = e;
                this.state.theme = localStorage.theme
            }
        }
    }
</script>

<style scoped>

    .account {
        font-size: 14px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        /*width: 175px;*/
    }
    .account img{
        filter: invert(94%)
    }
    .account img:hover{
        filter: invert(76%) sepia(23%) saturate(4531%) hue-rotate(3deg) brightness(106%) contrast(91%);
    }
    .account img:last-of-type{
        display: none;
    }
    .dark .account img:first-of-type{
        display: none;
    }
    .dark .account img:last-of-type{
        display: block;
    }
    .btn{
        width: 80px;
        height: 29px;
        border-radius: 5px;
        background-color:var(--main-orange);
        border: 2px solid var(--main-orange);
        color: var(--font-color);
        font-size: 12px;
        cursor: pointer;
        transition: 0.2s;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn:first-of-type{
        background-color: transparent;
        border: none;
        color: var(--main-orange);
    }
    .btn:hover{
        filter: brightness(120%);
    }
</style>
<template>
    <div class="Auth-box">
        <h3>فراموشی رمز عبور</h3>
        <p>ایمیلی که با آن در صرافی ویستا ثبت نام کرده‌اید را وارد کنید.</p>
        <form @submit.prevent="send" class="Auth">
            <ali-input :value.sync="email" type="email" name="email" :errortext="emailError"
                       faname="ایمیل"/>
            <input type="submit" hidden>
        </form>
        <button class="normal-btn" @click.prevent="send" :disabled="!email">ارسال کد بازیابی رمز عبور</button>
    </div>
</template>

<script>
    import AliInput from "@/components/Tools/AliInput";
    export default {
        name: "RecoveryPass",
        components: {AliInput},
        data() {
            return {
                email: '',
                emailError: '',
            }
        },
        methods: {
            async send() {
                await this.checkEmail()
                this.state.loading = true

                const res = await this.$axios.post('/users/forget-password?email=' + this.email)

                this.$cookies.set('username', this.email)
                this.state.userInfo.email = this.email

                if(res.baseDTO){
                    this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
                    this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
                }else{
                    this.state.userInfo.towStepAuthenticationType = 'EMAIL'
                }

                await this.$router.push('/reset-pass')
            },
            async checkEmail() {
                if (!this.$validEmail(this.email) || !this.email) {
                    this.emailError = 'ایمیل صحیح وارد نمایید'
                    this.$error('اطلاعات وارد شده اشتباه است', 'ایمیل صحیح وارد نمایید')
                    return Promise.reject('repeat failed : ' + this.email)
                }
            },
        }
    }
</script>

<style scoped>

    .Auth-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 470px;
        height: 100%;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .normal-btn{
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>

<template>
    <div v-show="!logedin || home" class="footer">
        <div class="footer-content row ml-2 mr-2">
            <div style="display: flex;flex-direction: column;align-items: center" class="col-sm-3 col-12 px-5 px-sm-2">
                <img src="../../assets/logo.png" alt="logo">
                <p style="color: white;text-align: justify;font-size: 14px">صرافی ویستا تابع قوانین جمهوری اسلامی ایران
                    بوده و بستری برای تبادل دارایی‌های دیجیتال می‌باشد. هیچ
                    گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر ارزهای کاغذی، در این بستر صورت نمی گیرد.</p>
            </div>
            <div class="footer-list col-sm-3 col-6 px-3 align-items-md-end align-items-center">
                <div>
                    <a>درباره</a>
                    <ul>
                        <li><a @click="$router.push('/about-us')">درباره ما</a></li>
                        <li><a @click="$router.push('/wage')">کارمزدها</a></li>
                        <li><a @click="$router.push('/policy')">قوانین</a></li>
                    </ul>
                </div>
            </div>
            <div class="footer-list col-sm-3 col-6 align-items-md-center align-items-center">
                <div>
                    <a>راهنما</a>
                    <ul>
                        <li><a @click="$router.push('/howtouse')">راهنمای استفاده</a></li>
                        <li><a @click="$router.push('/faq')">سوالات متداول</a></li>
                        <li><a @click="$router.push('/blog')">بلاگ</a></li>
                    </ul>
                </div>
            </div>
            <div class="footer-list col-sm-3 col-12 px-5 px-sm-0">
                <div>
                    <a>تماس با ما</a>
                    <ul>
                        <li v-if="info.address"><a>آدرس : {{info.address}}</a></li>
                        <li v-if="info.number"><a>تلفن : {{info.number}}</a></li>
                        <li v-if="info.email"><a>ایمیل : {{info.email}}</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-social">
            <div class="row m-2 no-gutters">
                <div class="col-md-7 col-12 justify-content-center justify-content-md-start"
                     style="cursor: default;display: flex;align-items: center">
                    <p style="cursor: default;margin: 0">کلیه حقوق این سایت متعلق به صرافی ویستا می‌باشد.</p>
                    <p style="margin-right:10px;margin: 0">طراحی شده توسط شرکت
                        <a style="text-decoration: underline;color: white" href="https://vistaapp.ir">ویستا</a>
                    </p>
                </div>
                <div class="col-md-5 col-12 social justify-content-md-end justify-content-center row no-gutters m-0 order-first order-sm-last">
                    <a class="test col-12 col-md-6">صرافی ویستا در شبکه های اجتماعی:</a>
                    <div class="social col-6 col-md-4 justify-content-around justify-content-sm-between">
                        <a v-if="info.twitter"
                           :href="info.twitter.includes('http')? info.twitter: `https://twitter.com/${info.twitter}`"
                           target="_blank">
                            <img src="../../assets/Twitter.png" alt="">
                        </a>
                        <a v-if="info.telegram"
                           :href="info.telegram.includes('http')? info.telegram: `https://telegram.org/${info.telegram}`"
                           target="_blank">
                            <img src="../../assets/Telegram.png" alt="">
                        </a>
                        <a v-if="info.linkedin"
                           :href="info.linkedin.includes('http')? info.linkedin: `https://linkedin.com/${info.linkedin}`"
                           target="_blank">
                            <img src="../../assets/Linkedin.png" alt="">
                        </a>
                        <a v-if="info.instagram"
                           :href="info.instagram.includes('http')? info.instagram: `https://instagram.com/${info.instagram}`"
                           target="_blank">
                            <img src="../../assets/Instagram.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data() {
            return {
                home: true,
                logedin: false,
                info:{}
            }
        },
        methods: {},
        async created() {
            this.state.loading = true
            const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO
        }
    }
</script>

<style scoped>
    .footer {
        display: flex;
        flex-direction: column;
        background-color: var(--light-background);
        align-items: center;
        z-index: 1;
        position: relative;
    }

    .footer-content {
        position: relative;
        width: 100%;
        max-width: 1070px;
        margin-top: 50px;
        padding-bottom: 50px;
        opacity: 0.8;
    }

    .footer-content::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 0);
        background-color: white;
        opacity: 0.1;
        width: 98%;
        height: 1px;
    }

    .footer-content img {
        width: 75%;
        margin-right: 5px;
    }

    .footer-list {
        color: white;
        text-align: right;
        display: flex;
        flex-direction: column;
    }

    .footer-list a {
        font-size: 21px;
        color: white;
        cursor: default;
    }

    ul {
        margin-top: 20px;
    }

    li {
        list-style: none;
        line-height: 1.79;
        margin-bottom: 15px;
    }

    li a {
        font-size: 14px !important;
        font-weight: 500;
        opacity: 0.8;
        cursor: pointer !important;

    }

    li a:hover {
        color: var(--font-hover);
    }

    .footer-list:last-of-type li a:hover {
        color: #ffffff;
        cursor: text !important;
    }

    .footer-social {
        width: 100%;
        max-width: 1070px;
        opacity: 0.8;
        font-family: IRANSans,sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        justify-content: center;
    }

    .social {
        display: flex;
    }

    .social a {
        color: white;
        cursor: default;
        vertical-align: super;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .social .social a{
        background-color: transparent;
        border-radius: 50%;
        height: 35px;
        transition: 0.5s;
        cursor: pointer;
    }
    .social .social a img{
        width: 35px;
    }

    .social .social a:hover {
        background-color: var(--main-orange);
    }
    @media (max-width: 574px){
        .footer-list:last-of-type{
            text-align: center;
        }
        .test{
            display: none;
        }

    }
</style>